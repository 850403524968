import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, redirect } from "react-router-dom";
import { routes } from "./routes";
import Header from '../components/layout/header/header';
import Sidebar from '../components/layout/sidebar/sidebar';
import Footer from '../components/layout/footer/footer'
import AOS from "aos";
import "aos/dist/aos.css";


const getRoutes = (routes) => {
	return routes.map((route, i) => {
		const { routes, pathCheck } = route;
		if (pathCheck) {
			return null;
		}

		return (
			<Route key={i} {...route}>
				{routes}
			</Route>
		);
	});
};

export default function AppRoutes() {
	// const state = useSelector((store) => store.authReducer)
	// useEffect(() => {
	// 	AOS.init({
	//         once: false,
	//     });
	// 	AOS.refresh();
	// }, []);

	useEffect(() => {
		AOS.init({
			once: false,
		});
		AOS.refresh();
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const handleScroll = () => {
		AOS.refresh();
	};

	return (
		<Router>
			{
				<div className="container-fluid">
					{
						<Header />
					}
					<div className="row">
						<Routes> {getRoutes(routes)} </Routes>
					</div>
					{
						<Footer />
					}
				</div>
			}
		</Router>
	);
}
