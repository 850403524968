import React, { useState } from 'react';
import "./quantum.scss"
import { Accordion, AccordionTab } from 'primereact/accordion';

function ViewServices(props) {

    const [activeIndex1, setActiveIndex1] = useState(null);
    const [activeIndex2, setActiveIndex2] = useState(null);
    const [activeIndex3, setActiveIndex3] = useState(null);
    return (
        <main>
            <div className="container-fluid quantum_commitment2 quantum_page-banner-wrap">
                <div className="row">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="quantum_commitment">
                                <h2 data-aos="fade-in">
                                Anryton Quantum Consultation Services
                                </h2>
                                <div className="col-md-12">
                                    <div className="row d-flex">
                                        <div className="quantum_page-banner">
                                        Unlock Tomorrow’s Potential with Quantum
                                        Technologies
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="quantum_divider quantum_divider-mb">
                <img src="images/2px.png" />
            </div>
            <div className="container text-white quantum_container-mt">
                <div className="text-center quantum_service-intro" data-aos="fade-in">
                    <h2 className='fw-bolder mb-4'> Post-Quantum Cryptography Solutions
                    </h2>
                    <p>
                    Cybersecurity today—whether securing WhatsApp messages, bank transactions, or digital
 certificates—is primarily built on RSA, an asymmetric encryption algorithm. While it would take
 billions of years for classical computers to break RSA encryption, a sufficiently powerful
 quantum computer could crack it in just hours.<br />
 As we approach "Q-day"—the day when quantum computers will surpass classical
 systems—the quantum revolution is poised to transform industries with breakthroughs in data
 processing, security, and optimization.<br />
 Anryton, leveraging its privacy-first Layer 1 Blockchain, offers Quantum Consultation Services
 to help enterprises navigate this transformation and tackle tomorrow’s challenges today.
                    </p>
                </div>
                <div className="row d-flex align-items-center quantum_feats-row">
                    <div className="col-md-6 quantum_main-title" data-aos="fade-right">
                        <h3 className='fw-bolder'>
                        HNDL–Harvest Now, Decrypt Later
                        </h3>
                        <p>
                            The data you store today—whether on Blockchain or the cloud—will soon be vulnerable to quantum attacks. Sensitive information, including:
                        </p>
                        <ul className='_li-listing'>
                            <li>Private photos</li>
                            <li>Billion-dollar ideas</li>
                            <li>Trade secrets</li>
                            <li>Personal matters</li>
                        </ul>
                        <p>All of it will be at risk unless organizations take proactive steps now.</p>
                        <p>
                            For enterprises that have spent decades safeguarding <strong>intellectual property</strong> and <strong>trade secrets</strong>, becoming <strong>Quantum Ready</strong> with <strong>Quantum cryptography</strong> is the only viable solution. Somewhere, a competitor could already be preparing to exploit your data as soon as quantum computing becomes accessible.
                        </p>
                    </div>

                    <div className="col-md-6 quantum_service-img" data-aos="fade-left">
                        <img src="/images/service1.jpg" alt="" />
                    </div>
                    <div className="quantum_col-equal">
                        <div className="col quantum_feats _industry-section" data-aos="fade-down">
                        <div class="container">
                                <h2 class="_section-title">Industries We Serve</h2>
                                <p>Anyrton’s Quantum Consultation Services support a diverse range of industries, helping them harness the power of quantum technology.</p>

                                <div class="_industry-card">
                                    <h5><strong>Financial Services</strong></h5>
                                    <p>Enhancing risk management, portfolio optimization, and fraud detection.</p>
                                </div>

                                <div class="_industry-card">
                                    <h5><strong>Healthcare & Pharma</strong></h5>
                                    <p>Revolutionizing drug discovery, genomics, and secure medical data encryption.</p>
                                </div>

                                <div class="_industry-card">
                                    <h5><strong>Logistics & Supply Chain</strong></h5>
                                    <p>Streamlining operations through quantum-enhanced predictive analytics.</p>
                                </div>

                                <div class="_industry-card">
                                    <h5><strong>Manufacturing & Energy</strong></h5>
                                    <p>Optimizing resource allocation and driving sustainability.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col quantum_feats" data-aos="fade-up">
                        <h2 class=" _section-title">Our Quantum Services - A 5 Step Process</h2>

                        <div class="_service-card">
                            <p><span class="_step-number">1.</span> <strong>Quantum Strategy Development:</strong> We assess your organization’s quantum readiness, identify opportunities, and create actionable roadmaps for implementing quantum technologies tailored to your business.</p>
                        </div>

                        <div class="_service-card">
                            <p><span class="_step-number">2.</span> <strong>Quantum-Safe Blockchain Solutions:</strong> Leveraging our Blockchain expertise, we offer secure, future-proof solutions integrating quantum-safe cryptographic measures for long-term data protection.</p>
                        </div>

                        <div class="_service-card">
                            <p><span class="_step-number">3.</span> <strong>Quantum Simulation & Problem-Solving:</strong> From logistics to financial modeling, our experts develop quantum simulations to drive faster, more accurate outcomes.</p>
                        </div>

                        <div class="_service-card">
                            <p><span class="_step-number">4.</span> <strong>Quantum Skill Development:</strong> We offer training workshops to build a quantum-aware workforce, helping your teams understand, adapt, and leverage quantum principles in daily operations.</p>
                        </div>

                        <div class="_service-card">
                            <p><span class="step-number">5.</span> <strong>R&D Partnership for Quantum-Blockchain Convergence:</strong> As leaders in quantum and Blockchain integration, Anyrton partners with organizations to explore new applications of quantum technologies in Blockchain and beyond.</p>
                        </div>
                        </div>
                    </div>
                </div>

                <div className='container' data-aos="fade-in">
                    <h2 className='fw-bolder mb-4'>  WhyQuantum Cryptography?
                    </h2>
                    <p>Quantum computing poses a significant threat to traditional cryptography systems, including the
 encryption keys, digital certificates, and data protection mechanisms that secure sensitive
 information today. By exploiting quantum phenomena, these computers can solve complex
 mathematical problems—like those underpinning RSA and ECC encryption—making traditional
 security measures vulnerable.
                    </p>

                    <h4>Key Quantum Technology Areas:</h4>
                        <ul>
                            <li><strong>Quantum Sensors:</strong> Precision devices that measure data, such as magnetic fields or gravitational waves.</li>
                            <li><strong>Quantum Computers:</strong> Systems that leverage qubits to process information, exponentially increasing computational power.</li>
                            <li><strong>Quantum Communication & Cryptography:</strong> Focuses on secure data transmission, with technologies like Quantum Key Distribution (QKD) ensuring data integrity.</li>
                        </ul>

                        <p>
                            Among these three, <strong>Quantum Communication and Cryptography</strong> are the most mature and practical for near-term use. Technologies like QKD are already in use to secure communication channels, making quantum-safe security a critical priority for any organization preparing for a post-quantum world.
                        </p>

                        <h5>Post-Quantum Cryptography (PQC):</h5>
                        <p>
                            This field is dedicated to developing cryptographic algorithms resilient to quantum attacks while still operable on classical computers. Now is the time to adopt flexible security approaches to safeguard digital assets against future quantum threats.
                        </p>
                        <p>
                            Anyrton, a leader in Blockchain and advanced technologies, is uniquely positioned to guide organizations through the quantum revolution, enabling them to become <strong>quantum-ready</strong> while unlocking new efficiencies, security advancements, and optimization opportunities.
                        </p>
                </div>

                <div className="row d-flex align-items-center quantum_feats-row">
                    <div className="col-md-6 quantum_service-img" data-aos="fade-right">
                        <img src="/images/service2.jpg" alt="" />
                    </div>
                    <div className="col-md-6 quantum_main-title" data-aos="fade-left">
                        <h2>
                            WhyChoose Anryton?
                        </h2>
                        <p>
                        Anryton combines deep expertise in both Blockchain and quantum technologies with a steadfast
 commitment to privacy and security. Powered by our Cosmos SDK-based architecture and
 pioneering decentralized privacy solutions, our team is dedicated to helping you navigate the
 quantum era with confidence.
                        </p>
                    </div>
                    
                </div>

                <div className="row d-flex align-items-center quantum_feats-row">
                    <div className="col-md-6 quantum_main-title" data-aos="fade-right">
                        <h2>
                            Get Started with Quantum Today
                        </h2>
                        <p>
                        Are you ready to transform your organization with quantum capabilities? Anryton’s Quantum
 Consultation Services guide you through every stage of the process, from strategy to full
 implementation. Contact us today to learn how we can unlock the potential of quantum
 technologies for your business.
                        </p>
                    </div>
                    <div className="col-md-6 quantum_service-img" data-aos="fade-left">
                        <img src="/images/service3.jpg" alt="" />
                    </div>
                    
                </div>

                
            </div>
            <div className="container">
                <div className="mb-4 quantum_faq" data-aos="fade">
                    <h1>
                        FAQs
                    </h1>
                    <h3 className="quantum_faq-title-desc" data-aos="fade">
                        The leap from centralized to decentralized systems, while revolutionary, can be daunting. We make it seamless.
                    </h3>
                </div>
                <div className="quantum_faq" data-aos="fade">
                    <Accordion activeIndex={activeIndex1} onTabChange={(e) => setActiveIndex1(e.index)}>
                        <AccordionTab header="I already use Blockchain, which is considered highly secure. Why should I worry
 about quantum threats?">
                            <p className="m-0">
                            Quantum computing's problem-solving capabilities will soon render traditional cryptography
 obsolete, jeopardizing everything from communications to financial transactions. Preparing for
 the post-quantum world is crucial to staying secure.
                            </p>
                        </AccordionTab>
                    </Accordion>
                    <Accordion activeIndex={activeIndex2} onTabChange={(e) => setActiveIndex2(e.index)}>
                        <AccordionTab header="What is Post-Quantum Cryptography (PQC) and how does it protect against quantum
 threats?">
                            <p className="m-0">
                            Post-quantum cryptography (PQC) refers to cryptographic algorithms designed to resist
 quantum computer attacks while still functioning on classical computers. These algorithms are
 being developed to protect sensitive data from future quantum decryption capabilities, ensuring
 long-term security in a quantum-enabled world.
                            </p>
                        </AccordionTab>
                    </Accordion>
                    <Accordion activeIndex={activeIndex3} onTabChange={(e) => setActiveIndex3(e.index)}>
                        <AccordionTab header="Howsoon doweneedto prepare for quantum threats, and what are the first steps?">
                            <p className="m-0">
                            While large-scale quantum computers are still under development, the threat is fast
 approaching. Preparing now by adopting quantum-safe technologies like Post-Quantum
 Cryptography (PQC) and Quantum Key Distribution (QKD) ensures your organization stays
 secure. The first steps include assessing your current cryptographic systems and partnering
 with experts like Anryton to develop a tailored quantum-readiness strategy.
                            </p>
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>
        </main>
    )
}

export default ViewServices