import React, { useState } from 'react';
import "./disclaimer.scss"

const Disclaimer = () => {
 
  return (
    <>
        <div className="container-fluid">
            <div className="container-fluid disclaimer_commitment2 disclaimer_page-banner-wrap">
                <div className="row">
                    <div className="container">
                        <div className="row disclaimer_contactus-top-content">
                            <div className="container">
                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="disclaimer_commitment">
                                        <h2 data-aos="fade-in">
                                        Disclaimer
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="disclaimer_divider disclaimer_divider-mb">
                <img src="images/2px.png" />
            </div>

            <div className="container-fluid">
                <div className="container">
                    <div className="row disclaimer_form-container">
    
    <p className='text-white fs-4'>This website and all information contained herein are provided solely for informational and operational purposes regarding our platform’s utility token. Our token is a utility token intended exclusively for use within our platform ecosystem. It does not represent equity, ownership, or any share in profits or revenue of the issuing company.</p>

    <p className='text-white fs-4'>Please note that our utility token is not an investment vehicle and is not designed or intended to offer any profit, return, or speculative gains to purchasers or holders. The token’s sole purpose is to enable access to certain features or services on our platform, and it is not registered as a security under any jurisdiction.</p>

    <p className='text-white fs-4'>Purchasers and holders of the token are encouraged to use it solely as outlined in the platform's documentation, and should consult with financial, legal, or tax advisors for specific guidance. Our platform does not make any guarantees regarding the token’s value, future utility, or legal classification outside the intended use as specified.</p>

    <p className='text-white fs-4'>By engaging with our platform and/or purchasing the token, users acknowledge and agree to the terms outlined here and assume any risks associated with the utility token usage.</p>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default Disclaimer;
