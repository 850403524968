import React, { useState, useEffect, useRef } from 'react';
import "./header.scss";
import { Link, useLocation } from 'react-router-dom';

function Header(props) {
    const location = useLocation();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isHeaderFixed, setIsHeaderFixed] = useState(false);
    const navbarCollapseRef = useRef(null); // Reference to the collapsible navbar

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };

    const listenScrollEvent = () => {
        if (window.scrollY > 70) {
            setIsHeaderFixed(true);
        } else {
            setIsHeaderFixed(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
        return () => {
            window.removeEventListener('scroll', listenScrollEvent);
        };
    }, []);

    const isActive = (path) => {
        return location.pathname === path;
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    const handleNavLinkClick = () => {
        scrollToTop();
        closeDropdown();

        // Close the mobile menu if it's open
        if (navbarCollapseRef.current && navbarCollapseRef.current.classList.contains('show')) {
            navbarCollapseRef.current.classList.remove('show');
        }
    };

    return (
        <div className={`container nav-wrap header_navigation-bar ${isHeaderFixed ? 'header_fixed-header' : ''}`}>
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid header_header-logo-nav" data-aos="fade-in">
                    <Link to="/" className="navbar-brand header_header-logo" onClick={handleNavLinkClick}>
                        <img src="images/logo.svg" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon">
                            <img src="images/menu.svg" />
                        </span>
                    </button>
                    <div className="collapse navbar-collapse navbar-flex justify-content-end" id="navbarSupportedContent" ref={navbarCollapseRef}>
                        <ul className="navbar-nav header_header-nav ml-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to="/services" className={`nav-link ${isActive('/services') ? 'active' : ''}`} onClick={handleNavLinkClick}>Service </Link>
                            </li>
                            <li className="nav-item dropdown header_dropdown" onMouseEnter={toggleDropdown} onMouseLeave={closeDropdown}>
                                <a href="javascript:void(0)" className="nav-link header_chevron-dd">
                                    <span>
                                        Solutions
                                    </span>
                                    <span>
                                        <img src="images/chevron.svg" />
                                    </span>
                                </a>
                                <div className={` dropdown-menu ${isDropdownOpen ? 'show' : ''}`} aria-labelledby="navbarDropdown">
                                    {/* <Link to="/anry-Token" className="dropdown-item" onClick={handleNavLinkClick}>ANRY Token</Link>
                                    <Link to="/p2p" className="dropdown-item" onClick={handleNavLinkClick}>P2P</Link>
                                    <Link to="nft-marketplace" className="dropdown-item" onClick={handleNavLinkClick}>NFT Marketplace</Link> */}
                                    
                                    <Link to="nft" className="dropdown-item" onClick={handleNavLinkClick}>DNA NFT</Link>
                                    <Link to="DataStorage" className="dropdown-item" onClick={handleNavLinkClick}>Data Storage</Link>
                                    <Link to="quantumconsulation" className="dropdown-item" onClick={handleNavLinkClick}>Quantum Consultation</Link>
                                    <Link to="mol" className="dropdown-item" onClick={handleNavLinkClick}>MOL COIN</Link>
                                </div>
                                <div className={`header_custom-dropdown ${isDropdownOpen ? 'show' : ''}`} aria-labelledby="navbarDropdown">
                                    {/* <Link to="/anry-Token" className="dropdown-item" onClick={handleNavLinkClick}>ANRY Token</Link>
                                    <Link to="/p2p" className="dropdown-item" onClick={handleNavLinkClick}>P2P</Link> */}
                                    
                                    
                                    <Link to="nft" className="dropdown-item" onClick={handleNavLinkClick}>DNA NFT</Link>
                                    <Link to="nft-marketplace" className="dropdown-item" onClick={handleNavLinkClick}>NFT Marketplace</Link>
                                    <Link to="quantumconsulation" className="dropdown-item" onClick={handleNavLinkClick}>Quantum Consultation</Link>
                                    <Link to="mol" className="dropdown-item" onClick={handleNavLinkClick}>MOL COIN</Link>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link to="/cases" className={`nav-link ${isActive('/cases') ? 'active' : ''}`} onClick={handleNavLinkClick}>Use Cases</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/about" className={`nav-link ${isActive('/about') ? 'active' : ''}`} onClick={handleNavLinkClick}>About</Link>
                            </li>
                            
                            <li className="nav-item">
                                <Link to="/qday" className={`nav-link ${isActive('/qday') ? 'active' : ''}`} onClick={handleNavLinkClick}>Q-Day</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/blog" className={`nav-link ${isActive('/blog') ? 'active' : ''}`} onClick={handleNavLinkClick}>Blog</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/faq" className={`nav-link ${isActive('/faq') ? 'active' : ''}`} onClick={handleNavLinkClick}>FAQ</Link>
                            </li>
                            <li className="nav-item header_nav-whitepaper">
                                <Link to="/whitepaper" className={`nav-link ${isActive('/whitepaper') ? 'active' : ''}`} onClick={handleNavLinkClick}>Whitepaper</Link>
                            </li>
                            <li className="nav-item header_nav-highlight">
                                <Link to="/contact" className={`nav-link ${isActive('/contact') ? 'active' : ''}`} onClick={handleNavLinkClick}>Contact</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Header;
