import React, { useEffect, useState } from 'react';
import "./mol.scss";
import { Accordion, AccordionTab } from 'primereact/accordion';
import Slider from 'react-slick';

import { Chart } from 'primereact/chart';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


function ViewAnryToken(props) {

    const [activeIndex1, setActiveIndex1] = useState(null);
    const [activeIndex2, setActiveIndex2] = useState(null);
    const [activeIndex3, setActiveIndex3] = useState(null);

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    const slickSlider = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,

        responsive: [
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    className: "center",
                    centerMode: true,
                    mobileFirst: true

                },
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    className: "center",
                    centerMode: true,
                    mobileFirst: true
                }
            }
        ]
    };

    useEffect(() => {
        const data = {
            labels: ['Angel Sale', 'Private Sale', 'Public Sale', 'Team', 'Reserves', 'Storage mining allocation', 'Grants Program', 'Marketing', 'Advisors', 'Liquidity/ Exchange listing', 'Staking'],
            datasets: [
                {
                    data: [3, 6, 6, 10, 25, 10, 20, 10, 3, 5, 2],
                    backgroundColor: ['#3C91E6', '#9FD356', '#FA824C', '#58FCEC', '#6D213C', '#FFC15E', '#FF570A', '#D5FFF3', '#6E75A8', '#DEEFB7', '#8BBEB2'],
                    hoverBackgroundColor: ['#3C91E6', '#9FD356', '#FA824C', '#58FCEC', '#6D213C', '#FFC15E', '#FF570A', '#D5FFF3', '#6E75A8', '#DEEFB7', '#8BBEB2'],
                }
            ]
        };
        const options = {
            cutout: '60%',
            plugins: {
                legend: false 
            },
        };

        setChartData(data);
        setChartOptions(options);
    }, []);


    return (
     
           <>
            <div className="container-fluid mol_commitment2 mol_page-banner-wrap">
                <div className="row">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="mol_commitment">
                                <h2 data-aos="fade-in">
                                    MOL Coin
                                </h2>
                                <div className="col-md-12">
                                    <div className="row d-flex">
                                        <div className="mol_page-banner">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div className="container mx-auto home_new-updated">
                <div className='col-9 mx-auto home_secure-data-section py-5'>

                    <div className='mt-4'>
                        <h4 className='mb-4'>Anryton Announces Upcoming Listing of MOL on XT.COM – Pioneering Quantum Technology in Blockchain.</h4>
                        <img src="images/xt.png" alt='xt' width="150" className='mb-4' />
                        <p> We are excited to announce that MOL (Anryton) will soon be listed on XT.COM. The MOL/USDT trading pair will be introduced in the Innovation Zone, offering new opportunities for traders to engage with this cutting-edge blockchain project. Stay tuned for updates on the schedule for deposits, withdrawals, and trading.</p>

                        <p>As blockchain technology advances, Anryton is preparing for the next wave of innovation: quantum technology in blockchain. With the rise of quantum computing, Anryton's ecosystem is designed to ensure long-term security and a future-proof digital infrastructure.
                        </p>

                        <p>Stay tuned for more details on the upcoming listing of MOL on XT.COM, and get ready to embrace the future of blockchain technology with Anryton!</p>
                    </div>
                </div>
            </div></>
           
       
    )
}

export default ViewAnryToken