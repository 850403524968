import React, { useEffect , useState} from 'react';
import Slider from 'react-slick';

import "./home.scss";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import DisclaimerModal from '../discalaimer-modal/disclaimerModal';
import DisclaimerDialog from '../disclaimer/disclaimerDailog';


function ViewHome(props) {
    const [isModalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const navigate = useNavigate();

    const handleAcceptAll = () => {
        console.log('Accepted all');
        // Add logic for "Accept All"
    };

    const handleRejectAll = () => {
        console.log('Rejected all');
        // Add logic for "Reject All"
    };
    
    const openModal = (content) => {
        setModalContent(content);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleReadMoreClick = () => {
        navigate('/qday'); // Replace with your target route
    };

    

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        className: "center",
        autoplaySpeed: 2000,

        responsive: [
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    className: "regular",
                    centerMode: false,
                    // dots: true
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    className: "regular",
                    centerMode: false,
                    // dots: true,
                }
            }
        ]
    };

    const slickSlider = {
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,

        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    className: "regular",
                    centerMode: false,
                    dots: true
                }
            }
        ]
    };

    // useEffect(() => {
    //     AOS.init({
    //         once: false,
    //     });
    //     AOS.refresh();
    // }, []);



    return (
        <div>
            <div className="container-fluid">
               
                <div className="row">
                    <div>
                        <video autoPlay muted loop className="home_myVideo">
                            <source src="images/baner.mp4" type="video/mp4" />
                        </video>
                    </div>
                    <div className="home_banner-caption">
                        <h2 data-aos="fade-down">
                        Leading the Future of Quantum-Resistant, <br />Decentralized Data Storage
                        </h2>
                        <p data-aos="fade-right">
                        Quantum-Ready Layer 1 blockchain for privacy-first, scalable, and secure decentralized data storage.
                        {/* <div className="read-more-container">
                        <button onClick={() => openModal(
                            "Quantum-Ready Layer 1 blockchain for privacy-first, scalable, and secure decentralized data storage."
                        )} className="home_read-more-btn">
                            Read More
                        </button></div> */}
                        </p>               
                        {/* <p data-aos="fade-right">
                        A Future-Proof Layer 1 Blockchain for Privacy-First, Scalable, and Secure Solutions<br />                            
                        We are revolutionizing data security with our <br /><strong>Quantum-Ready Layer One network</strong>. Our <strong>decentralized storage</strong> solutions protect your data today and for the future in an increasingly digital world.
                        </p> */}

                        <div className="home_banner-cta home_floating">
                            <span data-aos="fade-in">
                                <a href="https://miicall.com/Anryton" target='_blank'  className="home_purple">Let's Talk Blockchain</a>
                            </span>
                             <span data-aos="fade-in">
                                <Link to="/whitepaper" className="home_white-outline">
                                    Whitepaper
                                </Link>
                            </span> 
                        </div>
                        <video autoPlay muted loop className="home_myVideo home_myVideo_opacity">
                            <source src="images/hero-video.webm" type="video/webm" />
                        </video>
                    </div>
                </div>
            </div>

             {/* Modal */}
             {isModalOpen && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <button className="modal-close-btn" onClick={closeModal}>X</button>
                        <div className="modal-con" dangerouslySetInnerHTML={{ __html: modalContent }} />
                    </div>
                </div>
            )}

         

            <div className="container home_the-day-section">
                    <div className='col-12 home_decentralized-section py-5'>
                        <h3 data-aos="fade-left" className='text-center fs-1'>Anryton's Architecture</h3>
                        <p className='fs-5' data-aos="fade-left"> Anryton’s architecture blends Cosmos SDK and EVM compatibility on the secure Tendermint Core, offering fast, scalable solutions with high throughput. This setup allows seamless Ethereum-based app deployment and enables cross-chain transactions via Cosmos IBC, making Anryton an ideal choice for businesses needing scalable dApps, secure transactions, and privacy in a decentralized environment.
                        {/* <div className="read-more-container">
                        <button onClick={() => openModal(
                        "By integrating Ethereum Virtual Machine (EVM) capabilities, Anryton supports seamless deployment of Ethereum-based applications. It also leverages Cosmos’s Inter-Blockchain Communication (IBC), enabling cross-chain transactions, making Anryton the perfect platform for businesses looking to build scalable dApps, manage secure transactions, and ensure privacy in a decentralized environment."
                    )} className="home_read-more-btn">
                        Read More
                    </button></div> */}
                    </p>
                    </div>
               
            </div>

            <div className='container text-white home_section-five'>
                <div className='row'>

                    <div className='col-xxl-12 col-xl-12 col-md-12 col-12 home_quantum-ready-section py-5'>
                        <h3>Why Evmos for Anryton
                        </h3>
                        <p className='fs-5' data-aos="fade-right"> Anryton’s use of Evmos offers Ethereum compatibility within the Cosmos ecosystem, combining familiar tools for developers with the scalability of Cosmos, ideal for enterprises and developers moving from Ethereum.</p>
                        
                        <ul className='fs-5' data-aos="fade-right">
                            <li class="lead fs-5"> <strong>Ethereum Compatibility:</strong> Enables effortless deployment of Ethereum-based dApps on Anryton.</li>
                            <li class="lead fs-5"> <strong>Solidity Support:</strong> Leverages Solidity, the leading language for smart contracts.</li>
                            <li class="lead fs-5"> <strong>Tool Integration:</strong> Seamlessly connects with popular tools like MetaMask for easy network interaction.</li>
                            <li class="lead fs-5"> <strong>Scalability & Interoperability:</strong> Combines Cosmos's scalability with Ethereum’s dApp infrastructure, ideal for enterprises and developers transitioning from Ethereum.</li>
                        </ul>
                        {/* <div className="read-more-container">
                <button
                    onClick={() => openModal(
                        "By integrating EVMOS, Anryton ensures developers have access to a robust framework for building and deploying decentralized applications. This integration not only enhances development speed but also simplifies the process of managing and scaling dApps. With EVM compatibility, Anryton stands out as a versatile platform that bridges the gap between the Cosmos ecosystem and Ethereum’s established infrastructure."
                    )}
                    className="home_read-more-btn"
                >
                    Read More
                </button>
            </div> */}

                        {/* <p class="lead">EVMOSallows Anryton to provide the scalability and interoperability of the Cosmos ecosystem
 while maintaining compatibility with Ethereum’s widespread dApp infrastructure, making it ideal
 for enterprises and developers migrating from Ethereum.</p> */}
                    </div>

                </div>
            </div>

            <div className='container text-white home_section-five'>
        <div className='row'>
            <div class="container">
                <h1 class="main-heading" style={{ color: '#8f31e9' }}>Privacy-First Data Storage Solution</h1>

                <div class="row">
                    <div class="col-lg-12">
                        <p class="lead">
                            Data privacy is at the heart of Anryton’s mission. In today’s world, securing sensitive information is more important than ever, and Anryton is designed to provide a privacy-first blockchain solution that protects data at every step.
                        </p>
                        <div className="read-more-container">
                <button
                    onClick={() => openModal(`
                        <h2 style="color: #8f31e9;">Importance in the Current World</h2>
                        <p>As enterprises across industries—from healthcare to finance—move towards digital transformation, they are faced with the challenge of securely managing vast amounts of sensitive data. Data breaches, unauthorized access, and privacy regulations have made it critical for organizations to adopt solutions that ensure data privacy without sacrificing efficiency.</p>
                        <p>Anryton’s privacy-first approach means that sensitive information is encrypted and stored securely, accessible only by authorized parties. Its architecture is designed to comply with global privacy standards, ensuring enterprises can safeguard their data and remain compliant.</p>
                    `)}
                    className="home_read-more-btn"
                >
                    Read More
                </button>
            </div>
                    </div>
                </div>

                <div class="border-divider"></div>

            <div class="border-divider"></div>

            <div class="row">
                <h3 class="section-heading mb-4 mt-5" style={{ color: '#8f31e9' }}>Potential Use Cases</h3>
                    <div class="col-lg-4" data-aos="fade-up">
                        <div class="border p-4 rounded-3">
                            <h5>1. Healthcare</h5>
                            <p class="lead">
                                Secure patient data management across hospitals and clinics, ensuring compliance with privacy laws like HIPAA.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-4" data-aos="fade-up">
                        <div class="border p-4 rounded-3">
                            <h5>2. Legal Services</h5>
                            <p class="lead">
                            Secure storage of legal documents, ensuring compliance and protection against unauthorized access.                            </p>
                        </div>
                    </div>
                    <div class="col-lg-4" data-aos="fade-up">
                        <div class="border p-4 rounded-3">
                            <h5>3. Finance</h5>
                            <p class="lead">
                                Storing financial transactions with enhanced privacy measures, ensuring data integrity and customer protection.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
            
    <div className="container home_new-updated">
        <div className='col-12 home_secure-data-section py-5'>
            <h3 data-aos="fade-in fw-bolder" > Powering DNA NFT Platform
            </h3>

            <div className='mt-4'>                        
                <p>Anryton’s integration with the DNA NFT platform presents a groundbreaking solution for genomic data management, offering a secure, privacy-focused way to store, authenticate, and control genetic information. By enabling users to mint DNA NFTs that represent ownership and rights to their genetic data, Anryton empowers individuals with control over their sensitive information. This approach enhances genomic data privacy, supporting secure, decentralized research and personalized healthcare while ensuring individuals' ownership and protection of their genetic data.

            </p>
            </div>
        </div>
    </div>
            <div className='container my-5 text-white'>
                <div className='row'>
                    <div className='col-xxl-6 col-xl-6 col-md-6 col-12 home_decentralized-section py-5'>
                        <h3 data-aos="fade-left" style={{ color: '#8f31e9' }}>Quantum-Proof Ecosystem with Lattice-Based Cryptography</h3>
                        <p className='fs-5' data-aos="fade-left">As blockchain technology evolves, so do the threats. Quantum computing is poised to challenge
 current cryptographic standards, and Anryton is preparing for this future with a quantum-proof
 ecosystem. The network’s lattice-based cryptography ensures that even in the face of advances
 in quantum computing...
 <div className="read-more-container">
                <button
                    onClick={() => openModal(`
                       <p> Anryton’s data security remains intact  Lattice-based cryptography is known for its resistance to quantum attacks, making it a
 forward-thinking solution for securing sensitive data in the long term. This future-proofing
 ensures that Anryton’s ecosystem remains secure and reliable, providing peace of mind for
 enterprises and developers who are building on its platform</p>

                 <p> Anryton is leading the charge in the next wave of blockchain innovation by focusing on privacy,
 scalability, interoperability, and security. With its EVM compatibility, privacy-first approach, and a
 commitment to quantum-proofing its network, Anryton is the blockchain for enterprises looking
 to thrive in the decentralized future. Whether you are managing sensitive data, developing
 dApps, or navigating the regulatory landscape, Anryton offers the tools and infrastructure you
 need to succeed</p>
                    `)}
                    className="home_read-more-btn"
                >
                    Read More
                </button>
            </div></p>

                
                    </div>

                                    <div className='col-xxl-6 col-xl-6 col-md-6 col-12 home_quantum-ready-section py-5'>
                                        <h3> Prioritizing Regulatory Compliance</h3>
                                        <p className='fs-5' data-aos="fade-right"> In a world where regulations around data protection are becoming increasingly stringent,
                Anryton is designed to help businesses comply with global regulations like GDPR, HIPAA, and
                other data privacy laws. The network’s architecture allows for...
                                <div className="read-more-container">
                                <button
                                    onClick={() => openModal(`
                                        <h5 style="color: #8f31e9;">Our platform offers:
                                        </h5>
                                        <ul className='fs-5' data-aos="fade-right">
                                            <li className='fs-5'> Encrypted data storage and access control, ensuring that sensitive data is only accessible
                                            by authorized parties.</li>
                                            <li className='fs-5'> Built-in tools to support auditing and regulatory reporting, enabling businesses to
                                            demonstrate compliance easily</li>
                                        </ul>
                                        <p  className='fs-5'>By prioritizing regulatory compliance, Anryton helps enterprises navigate the complexities of
                evolving global data protection laws while ensuring the integrity of their decentralized
                operations</p>
                                    `)}
                                    className="home_read-more-btn"
                                >
                                    Read More
                                </button>
                            </div>
                            </p>                  
                        </div>
                    </div>
                </div>

                <div className="container home_the-day-section mb-5">
                    <div className='col-12 home_decentralized-section py-5'>
                        <h3 data-aos="fade-left" className='text-center fs-1' >The Q-Day Threat: Are You Ready?</h3>
                        <p>As quantum computing advances, the potential to revolutionize industries is vast, but so are the risks it poses to data security. Q-Day refers to the day when quantum computers become capable of breaking the encryption methods that currently protect the world's sensitive information. This looming threat means that businesses relying on traditional encryption could face unprecedented vulnerabilities.
                        <div className="read-more-container">
                            <button
                            onClick={handleReadMoreClick}
                                className="home_read-more-btn"
                            >
                                Read More
                            </button>
                        </div>
                    </p>                     
                </div>
            </div>

            <br /><br /><br />

            <div className="container-fluid home_img-wrap mt-5">
                <div className="row">
                    <div className="col-md-12 home_about-img-pd">
                        <div className="row">
                            <div className="col-md-3 home_about-img-mb">
                                <div className="home_about-img home_img-valign-mt20" data-aos="fade-right">
                                    <img src="images/about-img2.jpg" />
                                </div>
                            </div>
                            <div className="col-md-3 home_about-img-mb">
                                <div className="home_about-img home_img-valign-mt-min20" data-aos="fade-down">
                                    <img src="images/about-img3.jpg" />
                                </div>
                            </div>
                            <div className="col-md-3 home_about-img-mb">
                                <div className="home_about-img home_img-valign-mt50" data-aos="fade-up">
                                    <img src="images/about-img4.jpg" />
                                </div>
                            </div>
                            <div className="col-md-3 home_about-img-mb">
                                <div className="home_about-img brr-0 home_img-valign-mt20" data-aos="fade-left">
                                    <img src="images/about-img6.jpg" className="brr-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="home_press-annoucements">
                <h2 data-aos="fade-in">
                    Press Announcements
                </h2>

            </div>
            <div className='home_logo-slider'>
                <Slider {...settings}>
                    <div className='home_slider-logo-img'>
                        <img src="images/press1.png" />
                    </div>
                    <div className='home_slider-logo-img'>
                        <img src="images/press2.png" />
                    </div>
                    <div className='home_slider-logo-img' >
                        <img src="images/press3.png" />
                    </div>
                    <div className='home_slider-logo-img'>
                        <img src="images/press4.png" />
                    </div>
                    <div className='home_slider-logo-img'>
                        <img src="images/press5.png" />
                    </div>
                    <div className='home_slider-logo-img'>
                        <img src="images/press6.png" />
                    </div>
                </Slider>
            </div>

            <div className="container-fluid home_commitment2">
                <div className="row">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="home_commitment">
                                <h2 data-aos="fade-in" style={{ color: '#8f31e9' }}>
                                    Our Commitment to<br />
                                    Data Sovereignty and Security
                                </h2>
                                <div className="col-md-12">
                                    <div className="row d-flex">
                                        <div className="col-md-3 text-center home_commit-row" data-aos="fade-right">
                                            <div className="home_commitment-content">
                                                <span>
                                                    <img src="images/private.svg" />
                                                </span>
                                                <h1>Private</h1>
                                                <p>
                                                    Reclaim your digital sovereignty with privacy-first data management
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3 text-center home_commit-row" data-aos="fade-down">
                                            <div className="home_commitment-content">
                                                <span>
                                                    <img src="images/personalised.svg" />
                                                </span>
                                                <h1>Personalized</h1>
                                                <p>
                                                    Setup your own node or use a dedicated node to store your data, while maintaining a decentralized encrypted backup
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3 text-center home_commit-row" data-aos="fade-up">
                                            <div className="home_commitment-content">
                                                <span>
                                                    <img src="images/secure.svg" />
                                                </span>
                                                <h1>Secure</h1>
                                                <p>
                                                    Proof of Stake consensus with the latest cryptographic standards to relieve you from your cybersecurity concerns
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-md-3 text-center home_commit-row" data-aos="fade-left">
                                            <div className="home_commitment-content">
                                                <span>
                                                    <img src="images/decentralized.svg" />
                                                </span>
                                                <h1>Decentralized</h1>
                                                <p>
                                                    Individually owned nodes to maintain the Blockchain distributed ledger, combined with personal storage solution
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home_divider home_divider-mb">
                <img src="images/2px.png" />
            </div>

            <div className="container-fluid home_other-industries">
                <div className="row">
                    <div className="container">
                        <div className="row">
                            <div className="d-flex align-items-center justify-content-between home_industries-wrap">
                                <div className="home_industries-text" data-aos="fade-in" style={{ color: '#8f31e9' }}>
                                    Other Industries
                                </div>
                                <div className="home_industries-wrap">
                                    <div className="home_industries-row">
                                        <div className="home_industries-data" data-aos="fade-down">
                                            <span>
                                                <img src="images/identity.svg" />
                                            </span>
                                            <span className="home_industry-sect">
                                                <h2>
                                                    Identity
                                                </h2>
                                                <p>Securing Digital Presence with Blockchain.</p>
                                            </span>
                                        </div>
                                        <div className="home_industries-data" data-aos="fade-left">
                                            <span>
                                                <img src="images/governance.svg" />
                                            </span>
                                            <span className="home_industry-sect">
                                                <h2>
                                                    Governance
                                                </h2>
                                                <p>Empowering decentralized decision-making and transparency</p>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="home_industries-row">
                                        <div className="home_industries-data" data-aos="fade-right">
                                            <span>
                                                <img src="images/insurance.svg" />
                                            </span>
                                            <span className="home_industry-sect">
                                                <h2>
                                                    Insurance
                                                </h2>
                                                <p>Decentralized Insurance Solutions for the Digital Age.</p>
                                            </span>
                                        </div>
                                        <div className="home_industries-data" data-aos="fade-up">
                                            <span>
                                                <img src="images/ecommerce.svg" />
                                            </span>
                                            <span className="home_industry-sect">
                                                <h2>
                                                    E-Commerce
                                                </h2>
                                                <p>Blockchain-Powered for Secure Transactions.</p>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container home_slick-slider-container'>
                <div className='row'>
                    <div className='col-lg-4 col-md-12 home_silder-left-content'>
                        <h2>
                            Secure your data with Anryton
                        </h2>
                        <p>
                            Privacy Is Our Paramount Principle
                        </p>
                    </div>
                    <div className='col-lg-8 col-md-12 home_slider-right-cards'>
                        <Slider {...slickSlider}>
                            <div className="home_slide-wrap">
                                <span>
                                    <img src="images/digital-narrative.svg" />
                                </span>
                                <h4>
                                    Own your digital narrative
                                </h4>
                                <p>
                                    Control data access with our blockchain-driven, user-centric privacy model.
                                </p>
                            </div>
                            <div className="home_slide-wrap">
                                <span>
                                    <img src="images/custom-settings.svg" />
                                </span>
                                <h4>
                                    Custom settings, superior control
                                </h4>
                                <p>
                                    Tailor your data security preferences with flexible, individualized features.
                                </p>
                            </div>
                            <div className="home_slide-wrap">
                                <span>
                                    <img src="images/personalised.svg" />
                                </span>
                                <h4>
                                    Personalized data storage solutions
                                </h4>
                                <p>
                                    Easily configure a bespoke node with our guided setup process.
                                </p>
                            </div>
                            <div className="home_slide-wrap">
                                <span>
                                    <img src="images/secure.svg" />
                                </span>
                                <h4>
                                    Advance protection, peace of mind
                                </h4>
                                <p>
                                    Stay ahead with our state-of-the-art cryptographic security measures
                                </p>
                            </div>
                            <div className="home_slide-wrap">
                                <span>
                                    <img src="images/decentralized.svg" />
                                </span>
                                <h4>
                                    Decentralization for enhanced data integrity.
                                </h4>
                                <p>
                                    Own a part of the blockchain, ensuring data remains incorruptible
                                </p>
                            </div>
                            <div className="home_slide-wrap">
                                <span>
                                    <img src="images/sustainability.svg" />
                                </span>
                                <h4>
                                    Unmatched support, seamless experience
                                </h4>
                                <p>
                                    Experience personalized guidance every step of your data management journey.
                                </p>
                            </div>
                        </Slider>
                    </div>
                </div>
            </div>

            <div className="container-fluid home_community-wrap">
                <div className="row">
                    <div className="container">
                        <div>
                            <div className="d-flex align-items-center justify-content-between home_industries-wrap">
                                <div className="home_community-text" data-aos="fade-in" style={{ color: '#8f31e9' }}>
                                    Join Anryton's<br /> Exclusive Community
                                </div>
                                <div className="container d-flex home_mb-30 home_community-block-wrapper">
                                    <div className="col-md-4 home_community-block-mb">
                                        <div className="home_community-block" data-aos="fade-right">
                                            <h3>
                                                Network with Privacy-Focused Professionals
                                            </h3>
                                            <p style={{ color: 'white'}}>
                                                Join an elite network committed to upholding data privacy
                                            </p>
                                            <div className="home_community-img">
                                                <img src="images/community-img1.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 home_community-block-mb">
                                        <div className="home_community-block" data-aos="fade-down">
                                            <h3>
                                                Concierge Service for Your Journey
                                            </h3>
                                            <p style={{ color: 'white'}}>
                                                Industry-leading customer support available 24*7
                                            </p>
                                            <div className="home_community-img">
                                                <img src="images/community-img2.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 home_community-block-mb">
                                        <div className="home_community-block" data-aos="fade-left">
                                            <h3>
                                                Customization at the Core of Service
                                            </h3>
                                            <p style={{ color: 'white'}}>
                                                Choose how to secure your data with customizable security options
                                            </p>
                                            <div className="home_community-img">
                                                <img src="images/community-img3.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="container d-flex home_community-block-wrapper">
                                    <div className="col-md-4 home_community-block-mb">
                                        <div className="home_community-block" data-aos="fade-right">
                                            <h3>
                                                Feedback Welcomed, Improvements Deployed
                                            </h3>
                                            <p style={{ color: 'white'}}>
                                                Regular user feedback integration ensures a comfortable platform experience
                                            </p>
                                            <div className="home_community-img">
                                                <img src="images/community-img4.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 home_community-block-mb">
                                        <div className="home_community-block" data-aos="fade-up">
                                            <h3>
                                                Node Autonomy with Decentralized Backups
                                            </h3>
                                            <p style={{ color: 'white'}}>
                                                Now Maintain the autonomy over the data with our decentralized node structure
                                            </p>
                                            <div className="home_community-img">
                                                <img src="images/community-img6.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 home_community-block-mb">
                                        <div className="home_community-block" data-aos="fade-left">
                                            <h3>
                                                Empowerment through Technology Education
                                            </h3>
                                            <p style={{ color: 'white'}}>
                                                Access resources to understand blockchain's role in secure data management
                                            </p>
                                            <div className="home_community-img">
                                                <img src="images/community-img5.jpg" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="home_press-annoucements">
                <h2 data-aos="fade-in">
                    Strategic Partners
                </h2>

            </div>
            <div className='home_logo-slider'>
                <Slider {...settings}>
                    <div className='home_slider-logo-img home_slider-wid'>
                        <img src="images/logo_nea.png" />
                    </div>
                    <div className='home_slider-logo-img home_slider-wid'>
                        <img src="images/logo_anra.png" />
                    </div>
                    <div className='home_slider-logo-img home_slider-wid' >
                        <img src="images/logo_miicall.png" />
                    </div>
                    <div className='home_slider-logo-img home_slider-wid'>
                        <img src="images/logo_BParo.png" />
                    </div>
                    <div className='home_slider-logo-img home_slider-wid'>
                        <img src="images/logo_advanced_cardiology.png" />
                    </div>
                    <div className='home_slider-logo-img home_slider-wid'>
                        <img src="images/logo_bioemr.png" />
                    </div>
                    <div className='home_slider-logo-img home_slider-wid'>
                        <img src="images/logo_bioaro.png" />
                    </div>
                    <div className='home_slider-logo-img home_slider-wid'>
                        <img src="images/logo_cardia.png" />
                    </div>
                </Slider>
            </div>
            <DisclaimerDialog onAcceptAll={handleAcceptAll} onRejectAll={handleRejectAll} />
        </div>

    )
}

export default ViewHome