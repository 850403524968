import ViewAbout from '../views/about/about';
import ViewContact from '../views/contact/contact';
import ViewHome from '../views/home/home';
import ViewPages from '../views/pages/pages';
import ViewServices from '../views/services/services'
import ViewBlog from '../views/blog/blog'
import ViewCases from '../views/cases/cases';
import ViewWhitepaper from '../views/whitepaper/whitepaper';
import ViewBlogDetails from '../views/blog-details/blog-details';
import ViewAnryToken from '../views/anry-Token/anry-token';
import Viewp2p from '../views/p2p/p2p';
import ViewNftMarketplace from '../views/nft-marketplace/nft-marketplace';
import ViewQday from '../views/q-day/q_day';
import DataStorage from '../views/datastoragesolution/datastoragesolution'
import Nft from '../views/nft/nft'
import Faq from '../views/faq/faq'
import ViewMolToken from '../views/mol/mol';
import QuantumConsulation from '../views/quantum/quantum'
import Disclaimer from '../views/disclaimer/disclaimer';

export const routes = [

    {
        path: '/pages',
        element: <ViewPages />
    },

    {
        path: '/',
        element: <ViewHome />
    },


    {
        path: '/contact',
        element: <ViewContact />
    },

    {
        path: '/about',
        element: <ViewAbout />
    },
    {
        path: '/qday',
        element: <ViewQday />
    },
    {
        path: '/services',
        element: <ViewServices />
    },

    {
        path: '/blog',
        element: <ViewBlog />
    },
    {
        path: '/blog-details',
        element: <ViewBlogDetails />
    },
    {
        path: '/cases',
        element: <ViewCases />
    },

    {
        path: '/whitepaper',
        element: <ViewWhitepaper />
    },
    {
        path: '/anry-token',
        element: <ViewAnryToken />
    },
    {
        path: '/p2p',
        element: <Viewp2p />
    },
    {
        path: '/nft-marketplace',
        element: <ViewNftMarketplace />
    },
    {
        path: '/DataStorage',
        element: <DataStorage />
    },
    {
        path: '/nft',
        element: <Nft />
    },
    {
        path: '/faq',
        element: <Faq />
    },
    {
        path: '/mol',
        element: <ViewMolToken />
    },
    {
        path: '/quantumconsulation',
        element: <QuantumConsulation />
    },
    {
        path: '/disclaimer',
        element: <Disclaimer />
    },
]