import React, { useState } from 'react';
import "./services.scss"
import { Accordion, AccordionTab } from 'primereact/accordion';

function ViewServices(props) {

    const [activeIndex1, setActiveIndex1] = useState(null);
    const [activeIndex2, setActiveIndex2] = useState(null);
    const [activeIndex3, setActiveIndex3] = useState(null);
    return (
        <main>
            <div className="container-fluid services_commitment2 services_page-banner-wrap">
                <div className="row">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="services_commitment">
                                <h2 data-aos="fade-in">
                                    Our Services
                                </h2>
                                <div className="col-md-12">
                                    <div className="row d-flex">
                                        <div className="services_page-banner">
                                            Leading the Charge in Blockchain Integration for Business Transformation.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="services_divider services_divider-mb">
                <img src="images/2px.png" />
            </div>
            <div className="container text-white services_container-mt">
                <div className="text-center services_service-intro" data-aos="fade-in">
                    <p>
                        In the age of digitization, businesses are seeking more robust, transparent, and secure solutions to meet their ever-evolving needs. At Anryton, we comprehend the intricate nuances of diverse industries, guiding them through the transformative journey toward blockchain integration.Our range of comprehensive services is meticulously designed to usher in a new era of digital transformation that stands on the bedrock of decentralized technology.
                    </p>
                </div>
                <div className="row d-flex align-items-center services_feats-row">
                    <div className="col-md-6 services_main-title" data-aos="fade-right">
                        <h2>
                            Custom<br /> Implementation
                        </h2>
                        <p>
                            In the vast realm of blockchain technology, one-size-fits-all is not a strategy we believe in. Recognizing the distinct challenges faced by different sectors, Anryton crafts bespoke blockchain solutions.
                        </p>
                    </div>
                    <div className="col-md-6 services_service-img" data-aos="fade-left">
                        <img src="/images/service1.jpg" alt="" />
                    </div>
                    <div className="services_col-equal">
                        <div className="col services_feats" data-aos="fade-down">
                            <span>
                                <img src="/images/industry.svg" alt="" />
                            </span>
                            <span>
                                <p>
                                    Industry-Centric Design
                                </p>
                                <div>
                                    Ensuring that every blockchain implementation aligns impeccably with the sector-specific demands.
                                </div>
                            </span>
                        </div>
                        <div className="col services_feats" data-aos="fade-up">
                            <span>
                                <img src="/images/strategic.svg" alt="" />
                            </span>
                            <span>
                                <p>
                                    Strategic Integration
                                </p>
                                <div>
                                    Adopting a systematic approach to integrate decentralized systems, reinforcing efficiency, and enhancing security.
                                </div>
                            </span>
                        </div>
                    </div>
                </div>

                <div className="row d-flex align-items-center services_feats-row">
                    <div className="col-md-6 services_service-img" data-aos="fade-right">
                        <img src="/images/service2.jpg" alt="" />
                    </div>
                    <div className="col-md-6 services_main-title" data-aos="fade-left">
                        <h2>
                            Continuous<br /> Training & Support
                        </h2>
                        <p>
                            Blockchain is not just about technology, but about fostering a new way of thinking and operating.
                        </p>
                    </div>
                    <div className="services_col-equal">
                        <div className="col services_feats" data-aos="fade-right">
                            <span>
                                <img src="/images/end-to-end.svg" alt="" />
                            </span>
                            <span>
                                <p>
                                    End-to-End Training
                                </p>
                                <div>
                                    From the basics of decentralized systems to advanced operations, our training modules cover it all.
                                </div>
                            </span>
                        </div>
                        <div className="col services_feats" data-aos="fade-up">
                            <span>
                                <img src="/images/support1.svg" alt="" />
                            </span>
                            <span>
                                <p>
                                    24/7 Support
                                </p>
                                <div>
                                    With our round-the-clock support, we stand by you, ensuring every query and challenge is addressed with utmost urgency and precision.
                                </div>
                            </span>
                        </div>
                        <div className="col services_feats" data-aos="fade-left">
                            <span>
                                <img src="/images/empowering.svg" alt="" />
                            </span>
                            <span>
                                <p>
                                    Post-Implementation Mastery:
                                </p>
                                <div>
                                    Empowering businesses to extract the utmost value from their solutions, long after the initial integration.
                                </div>
                            </span>
                        </div>
                    </div>
                </div>

                <div className="row d-flex align-items-center services_feats-row">
                    <div className="col-md-6 services_main-title" data-aos="fade-right">
                        <h2>
                            Integration<br /> & Migration Assistance
                        </h2>
                        <p>
                            The leap from centralized to decentralized systems, while revolutionary, can be daunting. We make it seamless.
                        </p>
                    </div>
                    <div className="col-md-6 services_service-img" data-aos="fade-left">
                        <img src="/images/service3.jpg" alt="" />
                    </div>
                    <div className="services_col-equal">
                        <div className="col services_feats" data-aos="fade-right">
                            <span>
                                <img src="/images/data-integrity.svg" alt="" />
                            </span>
                            <span>
                                <p>
                                    Preserving Data Integrity
                                </p>
                                <div>
                                    During the migration, data remains uncompromised, retaining its authenticity and consistency.
                                </div>
                            </span>
                        </div>
                        <div className="col services_feats" data-aos="fade-up">
                            <span>
                                <img src="/images/migrate.svg" alt="" />
                            </span>
                            <span>
                                <p>
                                    Smooth Transition
                                </p>
                                <div>
                                    With our experts at the helm, experience a migration devoid of hitches, ensuring business continuity and minimizing operational disruptions.
                                </div>
                            </span>
                        </div>
                        <div className="col services_feats" data-aos="fade-left">
                            <span>
                                <img src="/images/transition.svg" alt="" />
                            </span>
                            <span>
                                <p>
                                    Integration Excellence
                                </p>
                                <div>
                                    Beyond migration, we ensure that every facet of the new decentralized system integrates flawlessly with your existing processes, making the adoption journey truly transformative.
                                </div>
                            </span>
                        </div>
                    </div>
                </div>

                <div className="row d-flex align-items-center services_feats-row">
                    <div className="col-md-6 services_service-img" data-aos="fade-right">
                        <img src="/images/service4.jpg" alt="" />
                    </div>
                    <div className="col-md-6 services_main-title" data-aos="fade-left">
                        <h2>
                            Why<br /> Blockchain
                        </h2>
                        <p>
                            Embracing blockchain through Anryton has allowed numerous enterprises to scale drastically.
                        </p>
                    </div>
                    <div className="services_col-equal">
                        <div className="col services_feats" data-aos="fade-right">
                            <span>
                                <img src="/images/trend.svg" alt="" />
                            </span>
                            <span>
                                Enterprises witnessed 70% reduction in operational inefficiencies.
                            </span>
                        </div>
                        <div className="col services_feats" data-aos="fade-down">
                            <span>
                                <img src="/images/data-integrity.svg" alt="" />
                            </span>
                            <span>
                                Enhanced data security measures, leading to a 50% decrease in data breach incidents.
                            </span>
                        </div>
                        <div className="col services_feats" data-aos="fade-left">
                            <span>
                                <img src="/images/money.svg" alt="" />
                            </span>
                            <span>
                                Achieved 80% faster transaction processes compared to traditional systems.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="mb-4 services_faq" data-aos="fade">
                    <h1>
                        FAQs
                    </h1>
                    <h3 className="services_faq-title-desc" data-aos="fade">
                        The leap from centralized to decentralized systems, while revolutionary, can be daunting. We make it seamless.
                    </h3>
                </div>
                <div className="services_faq" data-aos="fade">
                    <Accordion activeIndex={activeIndex1} onTabChange={(e) => setActiveIndex1(e.index)}>
                        <AccordionTab header="What makes Anryton's blockchain integration services different from other providers?">
                            <p className="m-0">
                                At Anryton, we prioritize bespoke solutions. Recognizing that every industry has its unique set of challenges, our services are tailor-made to align with specific sector needs. Additionally, our commitment to continuous training and round-the-clock support ensures that businesses don't just adopt blockchain technology but master it, extracting maximum value in the long run.
                            </p>
                        </AccordionTab>
                    </Accordion>
                    <Accordion activeIndex={activeIndex2} onTabChange={(e) => setActiveIndex2(e.index)}>
                        <AccordionTab header="How does Anryton ensure a smooth transition during the integration and migration process?">
                            <p className="m-0">
                                Migration to a decentralized system, while transformative, can be complex. Our seasoned professionals adopt a meticulous approach, ensuring data integrity, system continuity, and minimizing disruptions. From preserving data authenticity to ensuring every aspect of the new decentralized system integrates flawlessly with existing processes, we've got it all covered. Our aim is to make the transition as smooth and hassle-free as possible.
                            </p>
                        </AccordionTab>
                    </Accordion>
                    <Accordion activeIndex={activeIndex3} onTabChange={(e) => setActiveIndex3(e.index)}>
                        <AccordionTab header="Will our team receive training on how to use the new blockchain-based system post-integration?">
                            <p className="m-0">
                                Absolutely! Anryton believes that transitioning to a decentralized model is a continuous journey. Our team of experts offers end-to-end training modules, from basics to advanced operations, ensuring your team is well-equipped to leverage the full potential of the integrated solutions. Plus, our 24/7 support ensures that any post-integration queries or challenges are addressed promptly.
                            </p>
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>
        </main>
    )
}

export default ViewServices