import React, { useEffect } from 'react';
import Slider from 'react-slick';

import "./about.scss"
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


function ViewAbout(props) {


    const slickSlider = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,

        responsive: [
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    className: "regular",
                    centerMode: false,
                    mobileFirst: true,
                    // dots: true,

                },
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    className: "regular",
                    centerMode: false,
                    mobileFirst: true,
                    // dots: true,
                }
            }
        ]
    };


    return (
        <main>
            <div className="container-fluid about_pagebanner-img about_page-banner-about">
                <div className="row">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="about_commitment">
                                <h2 data-aos="fade-in">
                                    About Us
                                </h2>
                                <div className="col-md-12">
                                    <div className="row d-flex">
                                        <div className="about_page-banner">
                                            Anryton is a leader in data security and data flow solutions, laying strong roots for the new digital world. Our deep-rooted expertise in Blockchain technology underpins a new age where data isn't just a commodity; it's the lifeblood of global innovation.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about_divider about_divider-mb">
                <img src="images/2px.png" />
            </div>

            <section>
                <div className="container py-5">
                    <div className='mb-4'>
                        <h1 className="about_our-edge-heading">
                            Our Vision
                        </h1>
                    </div>
                    <div className="about_main-timeline">
                        <div className="about_timeline about_left" data-aos="fade-left">
                            <div className="col about_equal-col">
                                <div className="about_vision-card">
                                    <span>
                                        <img src="images/secures.svg" />
                                    </span>
                                    <p>
                                        At Anryton, we envision a future where data moves seamlessly and securely across the globe, fueling icol-lg-6 col-md-12nnovation and empowering enterprises. As a trailblazer in data security and data flow solutions, we're more than just a cloud platform and a blockchain company. We're the architects of a secure digital realm where data isn’t just protected; it’s optimized to flow with unmatched efficiency.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="about_timeline about_right" data-aos="fade-left">
                            <div className="col about_equal-col">
                                <div className="about_vision-card">
                                    <span>
                                        <img src="images/quality.svg" />
                                    </span>
                                    <p>
                                        Our cutting-edge compression technique stands as a testament to our unyielding quest for excellence. It’s not merely about securing data, but revolutionizing how it is stored, accessed, and transferred. Our robust R&D is the powerhouse behind our disruptive technologies, continually propelling us ahead in the ever-evolving digital landscape.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="about_timeline about_left" data-aos="fade-right">
                            <div className="col about_equal-col">
                                <div className="about_vision-card">
                                    <span>
                                        <img src="images/gear.svg" />
                                    </span>
                                    <p>
                                        Every stride we take is geared towards crafting a resilient digital ecosystem where businesses not only survive but thrive amidst the data deluge. The journey of data from creation to consumption has never been more secure, swift, and seamless.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="about_timeline about_right" data-aos="fade-left">
                            <div className="col about_equal-col">
                                <div className="about_vision-card">
                                    <span>
                                        <img src="images/digital-frontier.svg" />
                                    </span>
                                    <p>
                                        With Anryton, you’re not just embracing superior data security and flow solutions; you’re aligning with a vision that’s set on redefining the digital frontier.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className='container' >
                <div className="about_vision-blocks">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="about_vision-card-join" data-aos="fade-in">
                                <p>
                                    Join us in carving a future where data is the linchpin of progress, securely nestled in the robust architecture of Anryton's innovation.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container about_mission-wrap'>
                <div className='row'>
                    <div className='col-lg-3 col-md-12 about_mission-statement' data-aos="fade-in">
                        <h2>
                            Our<br /> Mission
                        </h2>
                    </div>
                    <div className='col-lg-9 col-md-12 about_mission-desc' data-aos="fade-left">
                        At Anryton, we aim to revolutionize industries with robust tech solutions, combatting data challenges like manipulation, security, and ownership.

                        We're committed to laying secure foundations in our digital age, making Blockchain benefits accessible worldwide. Our mission is simplifying integration for industries, offering developer SDKs and peer-to-peer storage. Anryton ensures industries lead the fourth industrial revolution.
                    </div>
                </div>
            </div>

            <div className='container about_edge-mb-100'>
                <div className='mb-4' data-aos="fade-in">
                    <h1 className='about_our-edge-heading'>
                        Our Edge
                    </h1>
                </div>
                <div className="anry_anry-feats">
                    <div className='row anry_feats-row'>
                        <div className='col-lg-3 col-md-6 anry_card-margin' data-aos="fade-right">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/digital-narrative.svg" />
                                </div>
                                <h2>
                                    Anryton Unveiled
                                </h2>
                                <p>
                                    Understanding what truly sets Anryton apart in the vast digital landscape requires a deep dive into the nuances of our approach and capabilities.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 anry_card-margin' data-aos="fade-down">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/custom-settings.svg" />
                                </div>
                                <h2>
                                    Pioneering Technology
                                </h2>
                                <p>
                                    Harnessing the most avant-garde technologies to ensure a competitive advantage in data management and security.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 anry_card-margin' data-aos="fade-up">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/personalised.svg" />
                                </div>
                                <h2>
                                    Unmatched Expertise
                                </h2>
                                <p>
                                    A reservoir of experts with profound knowledge in both blockchain development and its practical applications across various industries.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 anry_card-margin' data-aos="fade-left">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/secure.svg" />
                                </div>
                                <h2>
                                    Adaptive Solutions
                                </h2>
                                <p>
                                    A suite of products and services that are scalable, ensuring that as your business grows, our solutions evolve in tandem.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='row anry_feats-row'>
                        <div className='col-lg-3 col-md-6 anry_card-margin' data-aos="fade-right">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/decentralized.svg" />
                                </div>
                                <h2>
                                    Sustainable Innovation
                                </h2>
                                <p>
                                    A commitment to developing solutions that aren't just technologically advanced but also environmentally conscious.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 anry_card-margin' data-aos="fade-up">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/community-driven.svg" />
                                </div>
                                <h2>
                                    Client-Centric Approach
                                </h2>
                                <p>
                                    A philosophy centered around understanding and addressing the unique needs of each client, ensuring tailor-made solutions.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 anry_card-margin' data-aos="fade-left">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/universal.svg" />
                                </div>
                                <h2>
                                    Global Outreach
                                </h2>
                                <p>
                                    With a presence in multiple countries, we understand the nuances of different markets, making our solutions universally adaptable.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container about_join-anryton-container text-center'>
                <div className='about_join-anryton-heading'>
                    <h2 data-aos="fade-right">
                        Join the Anryton Odyssey
                    </h2>
                    <p data-aos="fade-left">
                        As we charter unknown territories in the digital realm, we invite you to be a part of this transformative journey. Let's forge a future where data is not just a byte but the very essence of global evolution, all anchored by the unwavering strength of Anryton.
                    </p>
                </div>
            </div>
        </main>
    )
}

export default ViewAbout