import "./nft.scss";

function ViewQday(){
    return(
        <>           
            <div className="container-fluid nft_commitment2 nft_page-banner-wrap">
                <div className="row">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="nft_commitment">
                                <h2 data-aos="fade-in">
                              
                                </h2>
                                <div className="col-md-12">
                                    <div className="row d-flex">
                                        <div className="nft_page-banner">
                                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="qday_divider qday_divider-mb">
                <img src="images/2px.png" />
            </div>
            
            <div className='col-md-9 mx-auto text-center text-white mb-5'>
                <h1 className="b-4 fw-bolder" data-aos="fade-right mb-4">
                    Every Gene Has A Story to Tell
                </h1>
                <p className="fs-4 col-md-7 text-center mx-auto mt-3 mb-4"> The first platform dedicated to celebrating your DNA’s uniqueness
 through Non-Fungible Tokens, telling the story of your Health,
 Heritage, and Hope.</p>
            </div>
            <div className='col-md-9 mx-auto qday_join-anryton-container text-center'>
                <div className='qday_join-anryton-heading'>
                    <h2 data-aos="fade-right">
                    How to create your DNA NFT
                    </h2><br />
                    <p className="fs-3 mb-2 mt2"> Embarking on your DNA NFT journey is a seamless process that unfolds in three key steps:</p><br />
                    <ul className="lh-lg text-start" data-aos="fade-left">
                        <li className="fs-4 text-white"><strong>DNASequencing</strong><br /> Our authorised laboratory will extract your DNA and sequence it. Sequencing involves
                        decoding the unique genetic information contained in your DNA.</li>
                        <li className="fs-4 text-white"><strong> Digital Conversion</strong><br /> The sequenced data is then converted into a digital format that can serve as the basis
                        for creating your NFT. This is your genome file.</li>
                        <li className="fs-4 text-white"><strong>Choose Your Art Style</strong><br />  Platforms typically offer a range of artistic styles or themes to represent your DNA data
                        visually. Choose one that resonates with you or represents your personality.</li>
                        <li className="fs-4 text-white"><strong>Mint Your DNA NFT</strong><br />The data of your DNA represented by the artwork is minted as a Non-Fungible Token on
                        the blockchain, ensuring its uniqueness and ownership are securely recorded.</li>
                        <li className="fs-4 text-white"><strong> Receive Your DNA NFT</strong><br />Once minted, the DNA NFT is transferred to your digital Blockchain wallet, granting you
                        full ownership.</li>
                        <li className="fs-4 text-white"><strong> Certificate of Authenticity: </strong> You'll also receive a digital certificate of authenticity,
                        confirming the uniqueness and ownership of your DNA NFT.</li>
                    </ul>
                </div>
            </div>

            
            <div className="col-md-9 mx-auto text-white text-center qday_q-day-section">
                <h2 className="fw-bolder fs-1" data-aos="fade"> Coming next- Use cases
                </h2>
                <p className="lh-lg fs-5" data-aos="fade">Preciions healthcare- Experience personalized healthcare for longevity</p>
                <p className="lh-lg fs-5" data-aos="fade"> Identity- Use your DNA NFT as an unrivaled identity verifier
                </p>
                <p className="lh-lg fs-5" data-aos="fade"> Research- Sell your DNA data to medical institutes for research in medical advancement</p>
                <p className="lh-lg fs-5" data-aos="fade">Inheritence- Setup a robust mechanism and pass on your belonging to the rightful heirs</p>
            </div>
           

            <div className='col-md-9 mx-auto text-white nft_promise-quantam'>
                <div className="nft_anry-about-wrap" data-aos="fade">
                    <div className="col nft_about-bg-transparent" >
                       <h3 className="fw-bolder">Benefits of DNA NFT
                       </h3>
                       <p className="fs-5"><strong>Uniqueness:</strong> Each DNA NFT is a one-of-a-kind digital asset that represents the unique genetic
 makeup of an individual, making it an unparalleled form of personal expression in the digital
 age.</p>

                       <p className="fs-5"><strong> Privacy and Security: </strong> We prioritize your privacy and the security of your genetic information.
 Through advanced encryption and blockchain's inherent security features, your DNA data and
 digital artwork are protected against unauthorized access</p>


                       <p className="fs-5"><strong> Legacy and Preservation:</strong> Your DNA NFT is more than just art; it's a digital legacy that can be
 preserved and passed down through generations, offering a new way to connect with your
 ancestry and heritage.</p>

                       <p className="fs-5"><strong> Investment Potential:</strong> As with any NFT, DNA NFTs possess investment potential, with the
 added value of being deeply personal and unique. As the NFT market evolves, the value of your
 DNANFTcould appreciate over time</p>
                    </div>
                    <div className="col nft_about-bg">                        
                        <h3 className="lh-lg fw-bolder">About Us</h3>

                        <p className="fs-5">At AnryGen, genetics meets the digital frontier of Blockchain. We celebrate the uniqueness of
 your DNA by immortalizing it as a Non-Fungible Token (NFT). Our platform offers a
 revolutionary way to preserve and honor your genetic identity, showcasing it as a piece of art
 that is as unique as you are.</p>

                        <p className="fs-5">Through the seamless integration of cutting-edge DNA sequencing technologies and
 Blockchain's unparalleled security, we create a personalized digital legacy that transcends
 traditional boundaries. Join us on a journey to explore the essence of your being and claim a
 piece of immortality in the digital realm.</p>

 <p className="fs-5">CTA- Get Immortalized on the Blockchain</p>
 
                    </div>
                </div>
            </div>
        </>
    )
}
export default ViewQday;