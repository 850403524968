import React, { useEffect, useState } from 'react';
import "./nft-marketplace.scss";
import { Accordion, AccordionTab } from 'primereact/accordion';


function ViewNftMarketplace(props) {


    const [activeIndex1, setActiveIndex1] = useState(null);
    const [activeIndex2, setActiveIndex2] = useState(null);
    const [activeIndex3, setActiveIndex3] = useState(null);

    return (
        <div>
            <div className="container-fluid nft_commitment2 nft_page-banner-wrap">
                <div className="row">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="nft_commitment">
                                <h2 data-aos="fade-in">
                                    NFT Marketplace
                                </h2>
                                <div className="col-md-12">
                                    <div className="row d-flex">
                                        <div className="nft_page-banner">
                                            <b>True Uniqueness For Every Asset</b>
                                            Understand how digital assets can transform your business with Anryton's pioneering NFT (Non-Fungible Token) platform.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="nft_divider nft_divider-mb">
                <img src="images/2px.png" />
            </div>
            <div className="container text-white services_container-mt">
                <div className="text-center services_service-intro" data-aos="fade-in">
                    <p>
                        Anryton is poised to elevate the NFT ecosystem by embedding blockchain's power into every unique digital token. With us, your digital assets are supercharged with unparalleled security, transparency, and global access.

                        The Anryton NFT platform leverages its own Blockchain platform which ensures true decentralization and secured data management.
                    </p>
                </div>
            </div>

            <div className='container mb-0 anry_slider-container-pd'>
                <div className='mb-4'>
                    <h1 className='about_our-edge-heading' data-aos="fade-in">
                        Get Started Today
                    </h1>
                </div>
                <div className="anry_anry-feats">
                    <div className='row anry_feats-row'>
                        <div className=' col-md-6 anry_card-margin' data-aos="fade-right">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/universal.svg" />
                                </div>
                                <h2>
                                    Choose Anryton
                                </h2>
                                <p>
                                    Discover the Anryton difference. From high-level security to user-friendly interfaces, align your NFT aspirations with our platform’s robust capabilities.
                                </p>
                            </div>
                        </div>
                        <div className=' col-md-6 anry_card-margin' data-aos="fade-left">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/minimal.svg" />
                                </div>
                                <h2>
                                    Mint with Assurance
                                </h2>
                                <p>
                                    Confidently mint your unique NFTs. Rely on our platform's superior encryption and authentication protocols to ensure every digital token's integrity.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6 anry_card-margin' data-aos="fade-right">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/light.svg" />
                                </div>
                                <h2>
                                    Engage & Flourish
                                </h2>
                                <p>
                                    Delve into a global community of artists, creators, and collectors. Trade, exhibit, and collaborate, making the most of the opportunities Anryton's NFT platform offers.
                                </p>
                            </div>
                        </div>
                        <div className=' col-md-6 anry_card-margin' data-aos="fade-left">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/unwavering.svg" />
                                </div>
                                <h2>
                                    Expand Beyond NFTs
                                </h2>
                                <p>
                                    Don’t just stop at creating NFTs. Explore Anryton’s expansive ecosystem—Peer-to-Peer storage, Blockchain, and ANRY token—to witness how synergy amplifies value.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div className="container">
                <div className="mb-4 services_faq">
                    <h1 data-aos="fade">
                        FAQs
                    </h1>
                    <h3 className="services_faq-title-desc" data-aos="fade">
                        The leap from centralized to decentralized systems, while revolutionary, can be daunting. We make it seamless.
                    </h3>
                </div>
                <div className="services_faq" data-aos="fade">
                    <Accordion activeIndex={activeIndex1} onTabChange={(e) => setActiveIndex1(e.index)}>
                        <AccordionTab header="What makes Anryton's Peer-to-Peer storage different from traditional storage solutions? ">
                            <p className="m-0">
                                Anryton's P2P storage deploys a decentralized approach, distributing data across various nodes instead of centralizing it in one location. Anryton enhances data security by reducing single points of failure and also ensures constant availability and accessibility. Furthermore, with advanced encryption and the power of blockchain, our storage solution offers unparalleled benefits compared to traditional storage systems such as low costs, 24*7 availability, and low latency.
                            </p>
                        </AccordionTab>
                    </Accordion>
                    <Accordion activeIndex={activeIndex2} onTabChange={(e) => setActiveIndex2(e.index)}>
                        <AccordionTab header="How does data encryption work in Anryton's Peer-to-Peer storage system?">
                            <p className="m-0">
                                Before data is stored, it undergoes a robust encryption process through our proprietary algorithm, which acts as a digital safeguard. This encrypted data is then fragmented into smaller shards using our unique compression technique. These shards are distributed across multiple nodes in the network, ensuring both security and redundancy. Think of it as providing a secret code to each piece of your data, ensuring utmost confidentiality and protection.
                            </p>
                        </AccordionTab>
                    </Accordion>
                    <Accordion activeIndex={activeIndex3} onTabChange={(e) => setActiveIndex3(e.index)}>
                        <AccordionTab header="How can I get involved with Peer-to-Peer storage?">
                            <p className="m-0">
                                Engaging with the Anryton community is a fantastic way to stay updated, share insights, and learn best practices related to decentralized storage. You can join our forums, participate in webinars, and attend our regular community events. Together, we aim to create an environment where innovation thrives, and everyone contributes to the future of data storage.
                            </p>
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}

export default ViewNftMarketplace