import "./q-day.scss";

function ViewQday(){
    return(
        <>           
            <div className="container-fluid qday_commitment2 qday_page-banner-wrap">
                <div className="row">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="qday_commitment">
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="qday_divider qday_divider-mb">
                <img src="images/2px.png" />
            </div>
            
            <div className='col-md-9 mx-auto qday_join-anryton-container text-center'>
                <div className='qday_join-anryton-heading'>
                    <h2 data-aos="fade-right">
                    Quantum Computing, Q-Day, and the Need for Blockchain Solutions like Anryton
                    </h2>
                    <p data-aos="fade-left" className="lh-lg">
                    As the digital world continues to expand, so do the risks associated with safeguarding sensitive data. Among the most significant emerging threats is the rise of quantum computing, a technological leap that holds the power to outperform traditional computers in ways that could break conventional encryption methods. The day when this becomes a reality is often referred to as Q-Day—a looming milestone when quantum computers will be capable of rendering today's encryption systems obsolete.
                    </p>
                </div>
            </div>

            <div className="col-md-9 mx-auto text-white text-center qday_q-day-section">
                <h2 className="fw-bolder fs-1" data-aos="fade">What is Q-Day?</h2>
                <p className="lh-lg fs-5" data-aos="fade">Q-Day is the anticipated point in the future when quantum computers will have the computational power to break modern encryption algorithms, such as RSA and ECC (Elliptic Curve Cryptography). These algorithms form the basis of much of the world’s data security, including online banking, healthcare, financial transactions, and government communications. Once quantum computers reach a certain threshold of power, they will be able to break the mathematical problems that underpin these encryption systems, leaving businesses vulnerable to cyberattacks, data breaches, and unauthorized access.
                For businesses, this poses an urgent question: How can they future-proof their data against quantum threats?</p>
            </div>

            <div className='col-md-9 mx-auto text-white qday_promise-quantam'>
                <div className="qday_anry-about-wrap" data-aos="fade">
                    <div className="col anry_about-bg-transparent" >
                       <h3 className="fw-bolder">The Promise of Blockchain in the Quantum Era
                       </h3>
                       <p className="fs-5">One of the most promising solutions lies in blockchain technology, which offers decentralized, secure methods of storing and transferring data. Unlike traditional systems that store data in a centralized server, blockchain distributes data across multiple nodes, creating an immutable ledger that is almost impossible to hack or alter. This inherent security makes blockchain an ideal foundation for protecting data in a post-Q-Day world.</p>
                    </div>
                    <div className="col qday_about-bg">                        
                        <h4 className="lh-lg">However, even blockchain will need to adapt to the quantum era. The encryption used to secure blockchain data could be vulnerable to quantum attacks in the future. This is where Quantum Ready blockchain solutions, like those offered by Anryton, come into play.</h4>
                    </div>
                </div>
            </div>

            <div data-aos="fade" className='col-md-9 mx-auto text-white specificall qday_specifically-section'>
                <h4 className="fs-2 lh-xl fw-bolder">Anryton’s Layer One network is specifically designed to withstand the future threats posed by quantum computing. Here’s how Anryton is preparing businesses for Q-Day:</h4>

                <ol className="fs-6 mt-4">
                    <li className="fs-5 lh-base mb-3">Quantum-Resistant Encryption: Traditional encryption methods that secure blockchain networks could be broken by quantum computers. Anryton's solutions utilize quantum-resistant cryptography designed to withstand attacks from quantum computers. This ensures that data remains secure even as quantum computing evolves.</li>

                    <li className="fs-5 lh-base mb-3">Decentralized Data Storage: Anryton’s blockchain architecture provides decentralized data storage, distributing information across a global network of nodes. This decentralized approach reduces the risk of data being compromised in one central location, ensuring both security and transparency in data handling.</li>

                    <li className="fs-5 lh-base mb-3">Future-Proofing Data: As businesses prepare for the inevitability of Q-Day, adopting quantum-resistant solutions is key to staying ahead of emerging threats. Anryton’s Layer One network is built to scale with technological advancements, ensuring that data is protected not just against current threats but against future quantum-enabled attacks.</li>

                    <li className="fs-5 lh-base mb-3">Blockchain Immutability and Transparency: Blockchain’s inherent immutability—its ability to keep data tamper-proof—complements quantum-resistant encryption, ensuring a secure, transparent environment for storing and transferring sensitive information. This is critical for industries like finance, healthcare, and government, where data integrity is paramount.</li>
                </ol>
            </div>
            
            <div className='col-md-9 mx-auto text-white qday_buisness-section'>
                <div className="qday_anry-about-wrap" data-aos="fade">
                    <div className="col anry_about-bg-transparent" >
                       <h3 className="fw-bolder">Why Businesses Should Prepare for Q-Day Now?
                       </h3>
                       <p className="fs-5">The threat of Q-Day might still be years away, but the impact it will have on data security is immense. As quantum computing capabilities accelerate, businesses that don’t prepare for this technological shift could find themselves vulnerable to data breaches that expose customer information, intellectual property, and critical business assets. Investing in Quantum Ready blockchain solutions today is the most effective way to ensure long-term data security.</p>
                    </div>
                    <div className="col qday_about-bg">     
                        <h3 className="mb-4 fw-bolder">By leveraging the capabilities of Anryton’s Layer One network, businesses can:
                        </h3>                   
                        <ul className="fs-5 lh-base">
                            <li className="mb-2">Safeguard sensitive data with quantum-resistant encryption.</li>
                            <li className="mb-2">Benefit from a decentralized, secure storage system that minimizes the risk of breaches.</li>
                            <li className="mb-2">Maintain transparency and control over their data, ensuring compliance with global data protection regulations.</li>
                            <li className="mb-2">Future-proof their digital assets against both current and emerging threats.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ViewQday;