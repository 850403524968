import React, { useState } from 'react';
import './disclaimer.scss';
import { Link } from 'react-router-dom';

const DisclaimerDialog = ({ onAcceptAll, onRejectAll }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(true);

    const handleClose = () => {
        setIsDialogOpen(false);
    };

    if (!isDialogOpen) return null;

    return (
        <div className="disclaimer_disclaimer-dialog">
            <h3 className="fs-4 disclaimer_disclaimer-heading">Disclaimer</h3>
            

            <p className='disclaimer_disclaimer-text fs-5'>This website and all information contained herein are provided solely for informational and operational purposes regarding our platform’s utility token. Our token is a utility token intended exclusively for use within our platform ecosystem. It does not represent equity, ownership, or any share in profits or revenue of the issuing company. <Link to="/disclaimer">read more...</Link></p>

    {/* Please note that our utility token is not an investment vehicle and is not designed or intended to offer any profit, return, or speculative gains to purchasers or holders. The token’s sole purpose is to enable access to certain features or services on our platform, and it is not registered as a security under any jurisdiction.<br />

    Purchasers and holders of the token are encouraged to use it solely as outlined in the platform's documentation, and should consult with financial, legal, or tax advisors for specific guidance. Our platform does not make any guarantees regarding the token’s value, future utility, or legal classification outside the intended use as specified.<br />

    By engaging with our platform and/or purchasing the token, users acknowledge and agree to the terms outlined here and assume any risks associated with the utility token usage.</p> */}
            <div className="disclaimer_disclaimer-buttons">
                <button className="disclaimer_accept-button mx-2" onClick={() => { onAcceptAll(); handleClose(); }}>
                    Accept All
                </button>
                {/* <button className="disclaimer_reject-button" onClick={() => { onRejectAll(); handleClose(); }}>
                    Reject All
                </button> */}
            </div>
        </div>
    );
}

export default DisclaimerDialog;
