import { Link } from "react-router-dom"

const ViewPages = (props) => {
    
    return (
        <div>
            <Link to="/contact">Contact</Link>
            <h1>Demo</h1>
        </div>
    )
}

export default ViewPages