import React, { useEffect, useState } from 'react';
import "./p2p.scss";
import { Accordion, AccordionTab } from 'primereact/accordion';

function Viewp2p(props) {


    const [activeIndex1, setActiveIndex1] = useState(null);
    const [activeIndex2, setActiveIndex2] = useState(null);
    const [activeIndex3, setActiveIndex3] = useState(null);

    return (
        <div>
            <div className="container-fluid p2p_commitment2 p2p_page-banner-wrap">
                <div className="row">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="p2p_commitment p2p_p2p-wrap-wide" data-aos="fade-in">
                                <h2>
                                    Peer-to-Peer Storage
                                </h2>
                                <div className="col-md-12">
                                    <div className="row d-flex">
                                        <div className="p2p_page-banner">
                                            Your Data, Your Rules
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p2p_divider p2p_divider-mb">
                <img src="images/2px.png" />
            </div>

            <div className='container'>
                <div className="p2p_vision-blocks">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="p2p_p2p-card-intro">
                                <p className="p2p_main-intro" data-aos="fade-right">
                                    Explore Decentralized Storage with Peer-to-Peer Technology
                                </p>
                                <div>
                                    <div className="row p2p_next-gen" data-aos="fade-left">
                                        <div className="col-md-7 p2p_p2p-para">
                                            Navigate the next generation of data storage with Anryton’s P2P network—decentralized, secure, and resilient by design.

                                            By shattering the traditional norms of centralized data centers, we are reshaping how data is stored, accessed, and protected. With Anryton, your data isn’t just secured—it's decentralized, fragmented, and made resilient.
                                        </div>
                                        <div className='col-md-5 p2p_navigate'>
                                            <img src="images/navigate.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container p2p_slick-slider-container mb-0'>
                <div className='mb-4'>
                    <h1 className='p2p_our-edge-heading' data-aos="fade-in">
                        How Anryton’s P2P System Redefines Data Storage
                    </h1>
                </div>
                <div className="anry_anry-feats">
                    <div className='row anry_feats-row'>
                        <div className=' col-md-6 anry_card-margin' data-aos="fade-right">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/universal.svg" />
                                </div>
                                <h2>
                                    Distributed Data Resilience
                                </h2>
                                <p>
                                    Rather than placing your trust in a single data center, Anryton’s P2P storage scatters your data across numerous nodes in the network. This dispersion eliminates the typical vulnerabilities associated with centralized systems, drastically reducing single points of failure, and ensures your data is always available and accessible.
                                </p>
                            </div>
                        </div>
                        <div className=' col-md-6 anry_card-margin' data-aos="fade-left">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/minimal.svg" />
                                </div>
                                <h2>
                                    Superior Encryption & Transmission
                                </h2>
                                <p>
                                    When it comes to data transmission, Anryton doesn’t compromise. As your data begins its journey for processing, it's enveloped in a cocoon of top-tier encryption. This protective layer ensures your data remains impervious to breaches, safeguarding sensitive information from unauthorized access.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6 anry_card-margin' data-aos="fade-right">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/light.svg" />
                                </div>
                                <h2>
                                    Data Fragmentation & Security
                                </h2>
                                <p>
                                    Anryton employs cutting-edge algorithms to break data into smaller chunks. Coupled with advanced cryptographic measures, each fragment is securely stored across various nodes, shielding your data from both systemic failures and malicious threats.
                                </p>
                            </div>
                        </div>
                        <div className=' col-md-6 anry_card-margin' data-aos="fade-left">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/unwavering.svg" />
                                </div>
                                <h2>
                                    Intelligent Data Compression
                                </h2>
                                <p>
                                    In the data collection phase, Anryton stands out with its distinctive compression technique. By condensing vast data sets, we facilitate quicker insights without ever compromising the integrity and quality of the data.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container '>
                <div className='mb-4'>
                    <h1 className='p2p_our-edge-heading' data-aos="fade-in">
                        How to get started
                    </h1>
                </div>
                <div className="anry_anry-feats">
                    <div className='row anry_feats-row'>
                        <div className='col-md-6 col-lg-3 anry_card-margin' data-aos="fade-right">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/universal.svg" />
                                </div>
                                <h2>
                                    Dive into Decentralization
                                </h2>
                                <p>
                                    Get acquainted with decentralized storage and explore its myriad benefits. It's about taking control back of your data and feeling secure.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-3 anry_card-margin' data-aos="fade-up">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/minimal.svg" />
                                </div>
                                <h2>
                                    Shard, Secure, and Spread
                                </h2>
                                <p>
                                    Segment your data into manageable shards, distributing them across a vast network, all while leveraging our cutting-edge encryption and compression methodologies.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-3 anry_card-margin' data-aos="fade-down">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/light.svg" />
                                </div>
                                <h2>
                                    Discover Your Ideal Platform
                                </h2>
                                <p>
                                    Survey the landscape of Anryton. Align its capabilities with your unique requirements, seamlessly merging blockchain prowess with state-of-the-art data storage.
                                </p>
                            </div>
                        </div>
                        <div className=' col-md-6 col-lg-3 anry_card-margin' data-aos="fade-left">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/unwavering.svg" />
                                </div>
                                <h2>
                                    Reward and Harmonize
                                </h2>
                                <p>
                                    Create a rewarding environment for nodes participating in the network maintaining harmony in a distributed environment.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-3 anry_card-margin' data-aos="fade-right">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/universal.svg" />
                                </div>
                                <h2>
                                    Fortify with Encryption
                                </h2>
                                <p>
                                    We fortify your data at the first step of Blockchain implementation. Encrypt it, ensuring unmatched privacy and a fortress-like defense. Think of it as a personalized cipher for your digital assets
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-3 anry_card-margin' data-aos="fade-up">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/minimal.svg" />
                                </div>
                                <h2>
                                    Engage Through dApps
                                </h2>
                                <p>
                                    Harness the power of dApps. Access your data in real-time, regardless of geographical factors and experience unparalleled accessibility.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-3 anry_card-margin' data-aos="fade-down">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/light.svg" />
                                </div>
                                <h2>
                                    Uphold Network Integrity
                                </h2>
                                <p>
                                    Immerse yourself in a system that celebrates decentralizization at its core. Adhere to the governing rules and protocols of the network and enjoy true democratization.
                                </p>
                            </div>
                        </div>
                        <div className=' col-md-6 col-lg-3 anry_card-margin' data-aos="fade-left">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/unwavering.svg" />
                                </div>
                                <h2>
                                    Monitor and Manage
                                </h2>
                                <p>
                                    Anryton provides constant access to network performance and data integrity with transparent data management, championing a transparent and accountable data management approach.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-3 anry_card-margin' data-aos="fade-right">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/universal.svg" />
                                </div>
                                <h2>
                                    Thrive in Community
                                </h2>
                                <p>
                                    Join hands with like-minded pioneers. Engage, share, and grow with a community that's passionate about decentralized storage.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-3 anry_card-margin' data-aos="fade-left">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/minimal.svg" />
                                </div>
                                <h2>
                                    Go Beyond Storage
                                </h2>
                                <p>
                                    Leverage our digital ecosystem and explore other applications such as Blockchain, ANRY token, and the NFT platform to uncover the true potential of your endeavours.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="mb-4 services_faq">
                    <h1 data-aos="fade">
                        FAQs
                    </h1>
                    <h3 className="services_faq-title-desc" data-aos="fade">
                        The leap from centralized to decentralized systems, while revolutionary, can be daunting. We make it seamless.
                    </h3>
                </div>
                <div className="services_faq" data-aos="fade">
                    <Accordion activeIndex={activeIndex1} onTabChange={(e) => setActiveIndex1(e.index)}>
                        <AccordionTab header="What makes Anryton's Peer-to-Peer storage different from traditional storage solutions? ">
                            <p className="m-0">
                                Anryton's P2P storage deploys a decentralized approach, distributing data across various nodes instead of centralizing it in one location. Anryton enhances data security by reducing single points of failure and also ensures constant availability and accessibility. Furthermore, with advanced encryption and the power of blockchain, our storage solution offers unparalleled benefits compared to traditional storage systems such as low costs, 24*7 availability, and low latency.
                            </p>
                        </AccordionTab>
                    </Accordion>
                    <Accordion activeIndex={activeIndex2} onTabChange={(e) => setActiveIndex2(e.index)}>
                        <AccordionTab header="How does data encryption work in Anryton's Peer-to-Peer storage system?">
                            <p className="m-0">
                                Before data is stored, it undergoes a robust encryption process through our proprietary algorithm, which acts as a digital safeguard. This encrypted data is then fragmented into smaller shards using our unique compression technique. These shards are distributed across multiple nodes in the network, ensuring both security and redundancy. Think of it as providing a secret code to each piece of your data, ensuring utmost confidentiality and protection.
                            </p>
                        </AccordionTab>
                    </Accordion>
                    <Accordion activeIndex={activeIndex3} onTabChange={(e) => setActiveIndex3(e.index)}>
                        <AccordionTab header="How can I get involved with Peer-to-Peer storage?">
                            <p className="m-0">
                                Engaging with the Anryton community is a fantastic way to stay updated, share insights, and learn best practices related to decentralized storage. You can join our forums, participate in webinars, and attend our regular community events. Together, we aim to create an environment where innovation thrives, and everyone contributes to the future of data storage.
                            </p>
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>
        </div>
    )
}

export default Viewp2p