import React, { useEffect, useState } from 'react';
import "./anry-token.scss";
import { Accordion, AccordionTab } from 'primereact/accordion';
import Slider from 'react-slick';

import { Chart } from 'primereact/chart';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


function ViewAnryToken(props) {

    const [activeIndex1, setActiveIndex1] = useState(null);
    const [activeIndex2, setActiveIndex2] = useState(null);
    const [activeIndex3, setActiveIndex3] = useState(null);

    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});

    const slickSlider = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        dots: true,

        responsive: [
            {
                breakpoint: 1399,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    className: "center",
                    centerMode: true,
                    mobileFirst: true

                },
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    className: "center",
                    centerMode: true,
                    mobileFirst: true
                }
            }
        ]
    };

    useEffect(() => {
        const data = {
            labels: ['Angel Sale', 'Private Sale', 'Public Sale', 'Team', 'Reserves', 'Storage mining allocation', 'Grants Program', 'Marketing', 'Advisors', 'Liquidity/ Exchange listing', 'Staking'],
            datasets: [
                {
                    data: [3, 6, 6, 10, 25, 10, 20, 10, 3, 5, 2],
                    backgroundColor: ['#3C91E6', '#9FD356', '#FA824C', '#58FCEC', '#6D213C', '#FFC15E', '#FF570A', '#D5FFF3', '#6E75A8', '#DEEFB7', '#8BBEB2'],
                    hoverBackgroundColor: ['#3C91E6', '#9FD356', '#FA824C', '#58FCEC', '#6D213C', '#FFC15E', '#FF570A', '#D5FFF3', '#6E75A8', '#DEEFB7', '#8BBEB2'],
                }
            ]
        };
        const options = {
            cutout: '60%',
            plugins: {
                legend: false 
            },
        };

        setChartData(data);
        setChartOptions(options);
    }, []);


    return (
        <div>
            <div className="container-fluid anry_commitment2 anry_page-banner-wrap">
                <div className="row">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="anry_commitment">
                                <h2 data-aos="fade-in">
                                    ANRY Token
                                </h2>
                                <div className="col-md-12">
                                    <div className="row d-flex">
                                        <div className="anry_page-banner">
                                            Enabling Secure, Swift, and Seamless Digital Transactions
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="anry_divider anry_divider-mb">
                <img src="images/2px.png" />
            </div>
            <div className='container text-white'>
                <div className="anry_anry-about-wrap" data-aos="fade">
                    <div className="col anry_about-bg-transparent" >
                        ANRY token is your passport to the Anryton world where each transaction is more than an exchange—it's an entry to innovation. The ANRY token streamlines your digital journey, marrying top-notch security with unparalleled efficiency. It’s designed for the globe-trotter in the digital universe—quick, cost-effective, and universally adaptable.
                    </div>
                    <div className="col anry_about-bg" >
                        But ANRY is more than a digital currency; it’s the glue that connects the community. It empowers developers and delights users, weaving a network of progress. Here, collaboration fuels the platform's evolution, with each member playing a critical role in a thriving digital ecosystem.
                    </div>
                </div>
            </div>

            <div className='container about_slick-slider-container mb-0 anry_slider-container-pd'>
                <div className='mb-4' data-aos="fade-in">
                    <h1 className='about_our-edge-heading'>
                        Key Features
                    </h1>
                </div>
                <div className="anry_anry-feats">
                    <div className='row anry_feats-row'>
                        <div className='col-lg-3 col-md-6 anry_card-margin' data-aos="fade-right">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/universal.svg" />
                                </div>
                                <h2>
                                    Universal Access
                                </h2>
                                <p>
                                    A token that dissolves borders, offering global reach for effortless transactions
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 anry_card-margin' data-aos="fade-up">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/minimal.svg" />
                                </div>
                                <h2>
                                    Minimal Transaction Costs
                                </h2>
                                <p>
                                    A streamlined fee structure that economizes your transactions.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 anry_card-margin' data-aos="fade-down">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/light.svg" />
                                </div>
                                <h2>
                                    Lightning-Fast Transfers
                                </h2>
                                <p>
                                    Experience transactions that are facilitated in the blink of an eye, globally.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 anry_card-margin' data-aos="fade-left">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/unwavering.svg" />
                                </div>
                                <h2>
                                    Unwavering Security
                                </h2>
                                <p>
                                    A token underpinned by robust blockchain security for peace of mind.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='row anry_feats-row'>
                        <div className='col-lg-3 col-md-6 anry_card-margin' data-aos="fade-right">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/currency.svg" />
                                </div>
                                <h2>
                                    Stable Currency Value
                                </h2>
                                <p>
                                    Carefully engineered tokenomics for consistent value stability.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 anry_card-margin' data-aos="fade-up">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/ecosystem.svg" />
                                </div>
                                <h2>
                                    Holistic Ecosystem Integration
                                </h2>
                                <p>
                                    Versatile utility across a broad spectrum of services.
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-6 anry_card-margin' data-aos="fade-left">
                            <div className="anry_anry-feats-title">
                                <div>
                                    <img src="images/digital-frontier.svg" />
                                </div>
                                <h2>
                                    Decentralized Storage System
                                </h2>
                                <p>
                                    Dive into reliable decentralized storage with ANRY at your command.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container about_slick-slider-container anry_slider-container-pt'>
                <div className='mb-4 anry_slider-heading' data-aos="fade-in">
                    <div className="about_divider about_divider-mb">
                        <img src="images/2px.png" />
                    </div>
                    <p>
                        Those contributing storage space to the Anryton blockchain are rewarded with ANRY tokens, promoting a community-driven growth. Additionally, as the primary currency, ANRY manages transaction fees within the ecosystem, ensuring smooth and efficient operations across services.
                    </p>
                    <div className="about_divider about_divider-mb">
                        <img src="images/2px.png" />
                    </div>
                    <h2>Key Benefits of Adopting ANRY
                    </h2>
                </div>
                <div className=' about_slider-right-cards'>
                    <Slider {...slickSlider}>
                        <div className="about_slide-wrap about_silder-hover">
                            <span className='anry_slide-img'>
                                <img src="images/digital-narrative.svg" />
                            </span>
                            <h4>
                                Sustainability
                            </h4>
                            <p>Eco-conscious at its core, Anry reduces the carbon footprint of digital finance.
                            </p>
                        </div>
                        <div className="about_slide-wrap about_silder-hover">
                            <span className='anry_slide-img'>
                                <img src="images/custom-settings.svg" />
                            </span>
                            <h4>
                                Community-Driven
                            </h4>
                            <p>
                                It's a collaborative movement, rewarding active community engagement and shared growth.
                            </p>
                        </div>
                        <div className="about_slide-wrap about_silder-hover">
                            <span className='anry_slide-img'>
                                <img src="images/personalised.svg" />
                            </span>
                            <h4>
                                Scalability
                            </h4>
                            <p>
                                Anry is built to scale, handling soaring transaction volumes with zero lag.
                            </p>
                        </div>
                        <div className="about_slide-wrap about_silder-hover">
                            <span className='anry_slide-img'>
                                <img src="images/secure.svg" />
                            </span>
                            <h4>
                                Interoperability
                            </h4>
                            <p>
                                Seamlessly exchange with a multitude of currencies, connecting diverse blockchain ecosystems.
                            </p>
                        </div>
                        <div className="about_slide-wrap about_silder-hover">
                            <span className='anry_slide-img'>
                                <img src="images/decentralized.svg" />
                            </span>
                            <h4>
                                Transparency
                            </h4>
                            <p>
                                With Anry, every transaction is traceable and accountable, cast in digital stone.
                            </p>
                        </div>
                        <div className="about_slide-wrap about_silder-hover">
                            <span className='anry_slide-img'>
                                <img src="images/background.svg" />
                            </span>
                            <h4>
                                Future-Ready
                            </h4>
                            <p>
                                Stay ahead with Anry, constantly evolving to lead the crypto frontier.
                            </p>
                        </div>
                    </Slider>
                </div>
            </div>
            <div className='container anry_tokenomics-wrapper'>
                <div className="anry_tokenomics-title">
                    <h1>
                        Tokenomics
                    </h1>
                </div>
                <div className='row anry_tokenomics-flex'>
                        <div className='col-md-6'>
                            <div className="flex justify-content-center anry_chart-wrap">
                                <div className='anry_chart-signia'>
                                    <img src="images/tokenomics-stats.png" />
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6'>
                        <div className="anry_tokenomics-wrap">
                            <div className="anry_total-tokens">
                                <span className="anry_tokens-title">
                                    ANRY<br /> Distribution
                                </span>
                                <span className="anry_token-count">
                                    <b>
                                        Total Token Supply
                                    </b>
                                    <p>
                                        400 million tokens
                                    </p>
                                </span>
                            </div>
                            <div className="anry_tokenomics-data">
                                <div className='anry_tokenomics-division'>
                                    <div className="col anry_tokenomics-card anry_color-angel-sale">
                                        <span className="anry_tokenomics-card-title">
                                            Angel Sale
                                        </span>
                                        <span className="anry_tokenomics-card-value">
                                            <i>3%</i>
                                        </span>
                                    </div>
                                    <div className="col anry_tokenomics-card anry_color-private-sale">
                                        <span className="anry_tokenomics-card-title">
                                            Private Sale
                                        </span>
                                        <span className="anry_tokenomics-card-value">
                                            <i>6%</i>
                                        </span>
                                    </div>
                                    <div className=" col anry_tokenomics-card anry_color-public-sale">
                                        <span className="anry_tokenomics-card-title">
                                            Public Sale
                                        </span>
                                        <span className="anry_tokenomics-card-value">
                                            <i>6%</i>
                                        </span>
                                    </div>
                                </div>
                                
                                <div className='anry_tokenomics-division'>
                                    <div className="col anry_tokenomics-card anry_color-team">
                                        <span className="anry_tokenomics-card-title">
                                            Team
                                        </span>
                                        <span className="anry_tokenomics-card-value">
                                            <i>10%</i>
                                        </span>
                                    </div>

                                    <div className="col anry_tokenomics-card anry_color-reserves">
                                        <span className="anry_tokenomics-card-title">
                                            Reserves
                                        </span>
                                        <span className="anry_tokenomics-card-value">
                                            <i>25%</i>
                                        </span>
                                    </div>

                                    <div className="col anry_tokenomics-card anry_color-staking">
                                        <span className="anry_tokenomics-card-title">
                                            Staking
                                        </span>
                                        <span className="anry_tokenomics-card-value">
                                            <i>2%</i>
                                        </span>
                                    </div>
                                </div>

                                <div className='anry_tokenomics-division'>
                                    <div className="col anry_tokenomics-card anry_color-grants">
                                        <span className="anry_tokenomics-card-title">
                                            Grants program
                                        </span>
                                        <span className="anry_tokenomics-card-value">
                                            <i>20%</i>
                                        </span>
                                    </div>

                                    <div className="col anry_tokenomics-card anry_color-marketing">
                                        <span className="anry_tokenomics-card-title">
                                            Marketing
                                        </span>
                                        <span className="anry_tokenomics-card-value">
                                            <i>10%</i>
                                        </span>
                                    </div>

                                    <div className="col anry_tokenomics-card anry_color-advisors">
                                        <span className="anry_tokenomics-card-title">
                                            Advisors
                                        </span>
                                        <span className="anry_tokenomics-card-value">
                                            <i>3%</i>
                                        </span>
                                    </div>
                                </div>

                                <div className='anry_tokenomics-division'>
                                    <div className="col anry_tokenomics-card anry_color-liquidity">
                                        <span className="anry_tokenomics-card-title">
                                            Liquidity/ Exchange listing:
                                        </span>
                                        <span className="anry_tokenomics-card-value">
                                            <i>5%</i>
                                        </span>
                                    </div>

                                    <div className="col anry_tokenomics-card anry_color-storage">
                                        <span className="anry_tokenomics-card-title">
                                            Storage mining allocation
                                        </span>
                                        <span className="anry_tokenomics-card-value">
                                            <i>10%</i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="mb-4 services_faq" data-aos="fade">
                    <h1>
                        FAQs
                    </h1>
                    <h3 className="services_faq-title-desc" data-aos="fade">
                        The leap from centralized to decentralized systems, while revolutionary, can be daunting. We make it seamless.
                    </h3>
                </div>
                <div className="services_faq" data-aos="fade">
                    <Accordion activeIndex={activeIndex1} onTabChange={(e) => setActiveIndex1(e.index)}>
                        <AccordionTab header="How does the ANRY token ensure currency stability? ">
                            <p className="m-0">
                                ANRY is meticulously designed to maintain a steady value, providing reliability for all types of transactions. By integrating algorithms that respond to market dynamics and utilizing a reserve mechanism, we ensure that ANRY remains stable, making it a dependable choice for users, traders, and investors alike.
                            </p>
                        </AccordionTab>
                    </Accordion>
                    <Accordion activeIndex={activeIndex2} onTabChange={(e) => setActiveIndex2(e.index)}>
                        <AccordionTab header="Can I use the ANRY token outside the Anryton platform?">
                            <p className="m-0">
                                Absolutely! ANRY is crafted for universal access. This means you can use it beyond the confines of the Anryton ecosystem. Whether it's for online services, offline transactions, or even as a medium of exchange with other platforms that accept it, ANRY breaks geographical barriers, allowing you to harness its benefits anytime, anywhere.
                            </p>
                        </AccordionTab>
                    </Accordion>
                    <Accordion activeIndex={activeIndex3} onTabChange={(e) => setActiveIndex3(e.index)}>
                        <AccordionTab header="What benefits or advantages do I receive when purchasing ANRY tokens during the private sale?">
                            <p className="m-0">
                                Participating in the ANRY token private sale offers several distinct advantages. Firstly, private sale participants typically receive tokens at a significantly reduced price compared to their projected public sale value, providing an early-bird advantage. Additionally, private sale contributors often benefit from exclusive bonuses and preferential terms, reflecting our appreciation for their early support and belief in the Anryton vision. However, it's essential to conduct thorough research and understand the terms and potential risks before participating in any token sale. Always consult with financial professionals to make informed decisions.
                            </p>
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>

            <div className='container about_join-anryton-container text-center mt-5'>
                <div className='about_join-anryton-heading'>
                    <h2 data-aos="fade-right">
                        Let’s Work Together
                    </h2>
                    <p data-aos="fade-left">
                        Whether you’re a business looking for a reliable transaction medium or an individual aiming for efficient digital interactions, ANRY is the answer. It encapsulates Anryton’s vision of a secure, swift, and seamless digital world. Embrace Anry, and take a leap into the future of digital transactions.
                    </p>
                </div>
                <div>
                    <div className='anry_explore-btn my-4' data-aos="fade-left">
                        <button>Explore our unique Academy</button>
                    </div>
                    <div className='about_join-anryton-heading' data-aos="fade-right">
                        <p>
                            When you invest in Anry, you’re not just holding a digital currency; you're becoming part of a vibrant community that's shaping the future of secure data flows.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewAnryToken