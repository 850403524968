import "./faq.scss";

function ViewQday(){
    return(
        <>           
            <div className="container-fluid faq_commitment2 faq_page-banner-wrap">
                <div className="row">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="faq_commitment">
                                <h2 data-aos="fade-in">
                                
                                </h2>
                                <div className="col-md-12">
                                    <div className="row d-flex">
                                        <div className="faq_page-banner">
                                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="qday_divider qday_divider-mb">
                <img src="images/2px.png" />
            </div>
            
            <div className="row d-flex align-items-center faq_feats-row">
                <div className="col-md-9 mx-auto">
                    <div className="row">
                        <h1 className="text-center text-white mb-5">How to Connect Your Anryton Blockchain to MetaMask:<br /> A Step-by-Step Guide for Non-Technical Users</h1>
                        <div className="col-md-7 d-flex flex-column align-items-center justify-content-center faq_main-title" data-aos="fade-right">                    
                            <p className="fs-3 text-white">
                            MetaMask is a popular wallet used for managing Ethereum-based tokens and connecting to
        decentralized applications. Since Anryton is EVM-compatible, you can connect your Anryton
        blockchain network to MetaMask by following these simple steps
                            </p>
                            
                            <p className="fs-5 text-white">
                                <strong>Step 1: Install MetaMask</strong>
                                If you haven’t already installed MetaMask, you can download it as an extension for your browser
                                (Chrome, Firefox, or Brave).
                            </p>

                            <ul className="text-white">
                                <li className="fs-5 lh-lg">Go to [MetaMask website] (<a className="text-white" href="https://metamask.io" target="_blank">https://metamask.io</a>) and click on the "Download" button</li>
                                <li className="fs-5 lh-lg"> Follow the instructions to install the extension.</li>                                
                            </ul>
                        </div>

                        <div className="col-md-5 faq_service-img text-center" data-aos="fade-left">
                            <img src="/images/step_1.jpg" width="550" alt="" className="rounded-3" />
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row d-flex align-items-center faq_feats-step-second">
                <div className="col-md-9 mx-auto">
                    <div className="row">
                        <div className="col-md-7 faq_service-img text-center" data-aos="fade-left">
                            <img src="/images/step_2.jpg" width="550" alt="" className="rounded-3" />
                        </div>

                        <div className="col-md-5 d-flex flex-column align-items-center justify-content-center faq_main-title" data-aos="fade-right">                    
                            <h2 className="text-white lh-lg">Set up your MetaMask wallet by creating a password and securing your recovery phrase. This
                            phrase is crucial, so keep it safe.</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row d-flex align-items-center faq_feats-row faq_step-third">
                <div className="col-md-9 mx-auto">
                    <div className="row">
                        <div className="col-md-7 d-flex flex-column align-items-center justify-content-center faq_main-title" data-aos="fade-right">                    
                            <h2 className="text-white lh-lg">Step 2: Open MetaMask</h2>
                            <p className="text-white lh-lg fs-4">Once MetaMask is installed, open it by clicking on the MetaMask icon in your browser's toolbar.
                            You'll be able to see your wallet and account settings.</p>
                        </div>

                        <div className="col-md-5 faq_service-img text-center" data-aos="fade-left">
                            <img src="/images/step_3.jpg" width="400" alt="" className="rounded-3" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row d-flex align-items-center faq_feats-row faq_step-third">
                <div className="col-md-9 mx-auto">
                    <div className="row">
                        <div className="col-md-5 faq_service-img text-center" data-aos="fade-left">
                            <img src="/images/step_4.jpg" width="400" alt="" className="rounded-3" />
                        </div>

                        <div className="col-md-7 d-flex flex-column justify-content-center faq_main-title" data-aos="fade-right">                    
                            <h2 className="text-white lh-lg">Step 3: Add a Custom Network</h2>
                            <p className="text-white lh-lg fs-4">By default, MetaMask is set to the Ethereum main network, but you can easily connect it to
                            Anryton by adding it as a custom network. Follow these steps:</p>

                            <ul className="text-white">
                                <li className="text-white fs-4">Click on your account icon in the top-right corner of MetaMask.</li>
                                <li className="text-white fs-4">From the dropdown, select "Settings".</li>
                                <li className="text-white fs-4"> In the settings menu, select "Networks".</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row d-flex align-items-center faq_feats-row faq_step-third">
                <div className="col-md-9 mx-auto">
                    <div className="row">
                        <div className="col-md-7 d-flex flex-column justify-content-center faq_main-title" data-aos="fade-right">                    
                            <h1 className="text-white lh-lg"> Now, click on the "Add Network" button</h1>
                            
                        </div>

                        <div className="col-md-5 faq_service-img text-center" data-aos="fade-left">
                            <img src="/images/step_5.jpg" width="400" alt="" className="rounded-3" />
                        </div>
                    </div>
                </div>
            </div>


            <div className="row d-flex align-items-center faq_feats-row faq_step-third">
                <div className="col-md-9 mx-auto">
                    <div className="row">
                        <div className="col-md-6 faq_service-img text-center" data-aos="fade-left">
                            <img src="/images/step_6.jpg" width="400" alt="" className="rounded-3" />
                        </div>

                        <div className="col-md-6 d-flex text-center flex-column justify-content-center faq_main-title" data-aos="fade-right">                    
                            <h1 className="text-white lh-lg">You will see this window
                            </h1>                            
                        </div>
                    </div>
                </div>
            </div>

            <div className="row d-flex align-items-center faq_feats-row faq_step-third">
                <div className="col-md-9 mx-auto">
                    <div className="row">
                    <div className="col-md-6 d-flex flex-column justify-content-center faq_main-title" data-aos="fade-right">                    
                            <h2 className="text-white lh-lg">Step 4: Enter Anryton Network Details
                            </h2>
                            <p className="text-white lh-lg fs-4">To connect MetaMask to Anryton, you will need to fill in the following fields with the provided
                            information:</p>

                            <ul className="text-white lh-lg">
                                <li className="text-white fs-4">Network Name: `Anryton Mainnet`</li>
                                <li className="text-white fs-4"> New RPCURL: <a className="text-white" href="https://jsrpc.anryton.com" target="_blank">`https://jsrpc.anryton.com`</a></li>
                                <li className="text-white fs-4"> Chain ID: `130`</li>
                                <li className="text-white fs-4"> Currency Symbol: `MOL`</li>
                                <li className="text-white fs-4"> Block Explorer URL: <a className="text-white" href="https://evm.anryton.com" target="_blank">`https://evm.anryton.com`</a>
                                </li>
                            </ul>
                        </div>

                        <div className="col-md-6 faq_service-img text-center" data-aos="fade-left">
                            <img src="/images/step_7.jpg" width="600" alt="" className="rounded-3" />
                        </div>

                        
                    </div>
                </div>
            </div>

            <div className="row d-flex align-items-center faq_feats-row faq_step-third">
                <div className="col-md-9 mx-auto">
                    <div className="row">                        

                        <div className="col-md-12 d-flex flex-column justify-content-center faq_main-title" data-aos="fade-right">                    
                            <h2 className="text-white lh-lg">Step 5: Network Connection Confirmation
                            </h2>
                            <p className="text-white lh-lg fs-4">After saving, MetaMask will automatically switch to the Anryton Mainnet. You’ll now be
 connected to Anryton, and you should be able to see the MOL token as the currency within
 MetaMask.</p>

 <h2 className="text-white lh-lg">Step 6: View Transactions and Explore the Blockchain
                            </h2>
                            <p className="text-white lh-lg fs-4">You can monitor your transactions and activity on Anryton using the following resources:</p>

                            <ul className="lh-lg">
                                <li className="fs-5 text-white">Anryton Explorer: Visit <a className="text-white" href="https://evm.anryton.com" target="_blank">[https://evm.anryton.com]</a> <a className="text-white" target="_blank" href="https://evm.anryton.com">(https://evm.anryton.com)</a> to track
 transactions and explore blocks on the Anryton blockchain.
</li>
                                <li className="fs-5 text-white">CosmosExplorer: Visit <a href="https://explorer.anryton.com" className="text-white" target="_blank">[https://explorer.anryton.com]</a>  
                                <a className="text-white" href="https://explorer.anryton.com" target="_blank">(https://explorer.anryton.com)</a>
                                for Cosmos-based insights into Anryton.</li>    
                            </ul>    
          

                            <h2 className="text-white mt-5">Conclusion</h2>
                            <h4 className="text-white lh-lg">By following these simple steps, you’ve successfully connected your MetaMask wallet to the
 Anryton Mainnet. Now you can manage your MOL tokens and explore the Anryton blockchain
 with ease.</h4>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ViewQday;