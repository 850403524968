import React, { useState } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import "./cases.scss"



function ViewCases(props) {
    const [visible, setVisible] = useState(false);
    const [healthCare, setHealthCare] = useState(false);
    const [supplyChain, setSupplyChain] = useState(false);
    const [finance, setFinance] = useState(false);
    const [agriculture, setAgriculture] = useState(false);
    const [identity, setIdentity] = useState(false);
    const [governance, setGovernance] = useState(false);
    const [insurance, setInsurance] = useState(false);
    const [ecommerce, setEcommerce] = useState(false);
    const [content, setContent] = useState(false);
    const [education, setEducation] = useState(false);
    const [legal, setLegal] = useState(false);

    return (
        <main>
            <div className="container-fluid cases_commitment2 cases_page-banner-wrap">
                <div className="row">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="cases_commitment">
                                <h2 data-aos="fade-in">
                                    Are You Blockchain Ready?
                                </h2>
                                <div className="col-md-12">
                                    <div className="row d-flex">
                                        <div className="cases_page-banner">
                                            Explore Anryton's comprehensive solutions, tailored to meet the unique challenges of each industry. Embrace digital transformation with the inclusion of Blockchain with Anryton.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cases_divider cases_divider-mb">
                <img src="images/2px.png" />
            </div>
            <div className="cases_case-sidebar">
                <Sidebar className='sidebar-bg' visible={visible} position="right" onHide={() => setVisible(false)}>
                    <h2>Aviation</h2>
                    <p>
                        Leveraging Anryton's Blockchain, airlines can issue tickets as NFTs, ensuring uniqueness, authenticity, and easy transferability. Combined with the ANRY token, airlines can introduce a new age of loyalty points, with impeccable traceability. The peer-to-peer storage, along with compression and encryption techniques, ensures passenger data protection and efficient handling of vast amounts of flight-related information.
                    </p>
                    <p>
                        In the skies or on the ground, with Anryton's transformative solutions, the aviation industry is set for a seamless and more secure future.
                    </p>
                </Sidebar>
            </div>
            <div className="cases_case-sidebar">
                <Sidebar className='sidebar-bg' visible={healthCare} position="right" onHide={() => setHealthCare(false)}>
                    <h2>Healthcare</h2>
                    <p>
                        Anryton's peer-to-peer storage system, combined with its encryption technique, ensures that patient records are stored securely, maintaining privacy and integrity. Furthermore, using NFTs, patient histories can be tokenized to ensure authenticity and prevent tampering. Billing and payment processes become seamless and transparent using the ANRY token within the ecosystem. For research areas like Genomics, Blockchain integration becomes a necessity to ensure authenticity and security.
                    </p>
                    <p>
                        Empowering healthcare with Anryton ensures not only efficient patient care but also fortifies trust through unmatched data integrity.
                    </p>
                </Sidebar>
            </div>
            <div className="cases_case-sidebar">
                <Sidebar className='sidebar-bg' visible={supplyChain} position="right" onHide={() => setSupplyChain(false)}>
                    <h2>Supply Chain </h2>
                    <p>
                        Anryton's Blockchain provides a transparent ledger for recording every product movement across the supply chain. Combined with NFTs, each product can have a unique digital identity, ensuring authenticity. The ANRY token can be utilized for instant, low-cost transactions throughout the chain, while the compression technique ensures efficient storage of vast logistical data. The complete supply chain digital ecosystem created with Anryton’s decentralization principles becomes a trustable, efficient, and unbiased environment.
                    </p>
                    <p>
                        From producer to consumer, Anryton ensures every step is transparent, trustworthy, and traceable, reshaping the backbone of global commerce.
                    </p>
                </Sidebar>
            </div>
            <div className="cases_case-sidebar">
                <Sidebar className='sidebar-bg' visible={finance} position="right" onHide={() => setFinance(false)}>
                    <h2>Finance</h2>
                    <p>
                        The challenges in present-day banking and lending need urgent attention. By leveraging Anryton's blockchain and ANRY token, financial institutions can provide decentralized banking solutions, ensuring quicker transactions, lower fees, and higher security. Loans and credits can be managed using smart contracts, ensuring automated compliance and reduced operational costs. Furthermore, Anryton Blockchain can streamline many intricate processes such as KYC, which costs billions of dollars in terms of execution, verification, maintenance, and reverification.
                    </p>
                    <p>
                        Financial systems evolve with Anryton, where transactions are swift, and transparent, and the future of decentralized finance takes shape.
                    </p>
                </Sidebar>
            </div>
            <div className="cases_case-sidebar">
                <Sidebar className='sidebar-bg' visible={agriculture} position="right" onHide={() => setAgriculture(false)}>
                    <h2>Agriculture</h2>
                    <p>
                        Using the Anryton Blockchain, every step, from seeding to the final produce reaching the consumers, can be traced, ensuring organic compliance or even general quality check. Farmers can use ANRY tokens for their payments which assists in instant settlement, a dream for farmers today. The peer-to-peer storage allows for efficient data handling of agricultural patterns, soil health, and more, creating a record which can not be altered and gives true value to the farmers as well as the consumers.
                    </p>
                    <p>
                        Anryton cultivates innovation, ensuring that from seed to shelf, every grain and green is traceable, authentic, and sustainable.
                    </p>
                </Sidebar>
            </div>
            <div className="cases_case-sidebar">
                <Sidebar className='sidebar-bg' visible={identity} position="right" onHide={() => setIdentity(false)}>
                    <h2>Identity</h2>
                    <p>
                        As identities become digital, effective handling becomes paramount. With Anryton's encryption and blockchain, digital identities can be created and verified, providing a secure and immutable record of a person's identity. NFTs can uniquely represent each individual, making identity theft almost impossible. One single platform can be used for verifying identity in multiple places, without revealing true data.
                    </p>
                    <p>
                        Crafting a digital identity with Anryton means stepping into a world where verification is instant and identity theft is a relic of the past.
                    </p>
                </Sidebar>
            </div>
            <div className="cases_case-sidebar">
                <Sidebar className='sidebar-bg' visible={governance} position="right" onHide={() => setGovernance(false)}>
                    <h2>Governance</h2>
                    <p>
                        Leveraging the Anryton blockchain, governments can implement transparent voting systems where each vote is recorded permanently on the distributed ledger. Furthermore, the vote is uniquely associated with the caster through decentralized identity management and is secured from outside eyes, keeping votes private. Public records, stored using the peer-to-peer system, ensure that citizens have access to official documents in a secure, tamper-proof environment.
                    </p>
                    <p>
                        With Anryton, governance transcends traditional boundaries, ushering in an era of transparent decision-making and empowered citizenry.
                    </p>
                </Sidebar>
            </div>
            <div className="cases_case-sidebar">
                <Sidebar className='sidebar-bg' visible={insurance} position="right" onHide={() => setInsurance(false)}>
                    <h2>Insurance</h2>
                    <p>
                        Instant claims and settlement are no longer a distant dream. Anryton’s blockchain can automate claim verifications using smart contracts, ensuring quick settlements. Insurance policies can be tokenized using NFTs, ensuring unique identification and easy transferability. All records are securely stored using the platform's peer-to-peer storage, ensuring data integrity which further ensures that neither the company nor the user has any power to change the agreed-upon terms without mutual consent.
                    </p>
                    <p>
                        Anryton transforms insurance, ensuring claims are not just processed but are transparent, prompt, and built on trust.
                    </p>
                </Sidebar>
            </div>
            <div className="cases_case-sidebar">
                <Sidebar className='sidebar-bg' visible={ecommerce} position="right" onHide={() => setEcommerce(false)}>
                    <h2>Ecommerce</h2>
                    <p>
                        Ecommerce platforms utilize Anryton’s NFT platform to guarantee product authenticity, preventing counterfeits. This has allowed not only individuals but big brands to leverage the power of NFTs and get a competitive edge in the market. ANRY tokens offer a seamless and cost-effective transaction method, while the peer-to-peer storage ensures customer data protection and efficient handling of vast product databases.
                    </p>
                    <p>
                        Shop with confidence and operate with efficiency; Anryton brings authenticity and seamless transactions to the forefront of ecommerce.
                    </p>
                </Sidebar>
            </div>
            <div className="cases_case-sidebar">
                <Sidebar className='sidebar-bg' visible={content} position="right" onHide={() => setContent(false)}>
                    <h2>Content and Media</h2>
                    <p>
                        Content monetization and anti-piracy become easy with Anryton. Artists and creators can tokenize their content using the Anryton NFT platform, ensuring they get fairly compensated for every use. The ANRY token facilitates quick payments enforced by smart contract conditions that execute automatically based on defined rules. The encryption technique ensures content protection against unauthorized access and piracy while the peer-to-peer storage makes sure that the content is always available, everywhere.
                    </p>
                    <p>
                        Anryton's solutions amplify the voice of creators, ensuring content remains protected, valued, and ever-resonant in the digital age.
                    </p>
                </Sidebar>
            </div>
            <div className="cases_case-sidebar">
                <Sidebar className='sidebar-bg' visible={education} position="right" onHide={() => setEducation(false)}>
                    <h2>Education</h2>
                    <p>
                        Educational institutions can issue certificates as NFTs, ensuring their authenticity and preventing forgery. This helps the industry and several organizations to easily establish a channel for verification of the documents, a major challenge that Anryton solves. The peer-to-peer storage system, combined with Anryton's compression technique, provides students with swift access to vast learning materials while ensuring data security and low latency.
                    </p>
                    <p>
                        Educating the future with Anryton means every achievement is recognized, every resource is accessible, and learning knows no bounds.
                    </p>
                </Sidebar>
            </div>
            <div className="cases_case-sidebar">
                <Sidebar className='sidebar-bg' visible={legal} position="right" onHide={() => setLegal(false)}>
                    <h2>Legal</h2>
                    <p>
                        Legal documents can be stored using Anryton’s secure peer-to-peer storage, ensuring they're immutable and tamper-proof. Smart contracts, facilitated by Anryton's Blockchain, can automate and execute contractual agreements when conditions are met. NFTs can be utilized for unique legal documents, ensuring authenticity and a robust proof of origin that can be established with ease.
                    </p>
                    <p>
                        Reinventing the legal landscape, Anryton brings transparency and automation, ensuring justice is both swift and immutable.
                    </p>
                </Sidebar>
            </div>
            <div className="container mt-lg-4 mt-5 mb-5">
                <div className="row cases_cases-card-container">
                    <div className="col-md-6 col-lg-4 mb-4 cases_case-card-wrap" data-aos="fade-right">
                        <a href="javascript:void(0)" className="cases_case-card" onClick={() => setVisible(true)}>
                            <div className="col-md-12 card h-100 p-4 cases_cases-card">
                                <div className="cases_card-top-img">
                                    <img src="images/aviation-case.svg" />
                                </div>
                                <div className="cases_card-body">
                                    <h2>
                                        <span>
                                            Aviation
                                        </span>
                                        <span>
                                            <img src="images/expand.svg" />
                                        </span>
                                    </h2>
                                    <p>
                                        Anryton's Blockchain enables airlines to issue NFT tickets, enhance loyalty programs, and ensures secure passenger data management.
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-4 cases_case-card-wrap" data-aos="fade-up">
                        <a href="javascript:void(0)" className="cases_case-card" onClick={() => setHealthCare(true)}>
                            <div className="col-md-12 card h-100 p-4 cases_cases-card">
                                <div className="cases_card-top-img">
                                    <img src="images/healthcare-case.svg" />
                                </div>
                                <div className="cases_card-body" >
                                    <h2>
                                        <span>
                                            Healthcare
                                        </span>
                                        <span>
                                            <img src="images/expand.svg" />
                                        </span>
                                    </h2>
                                    <p>
                                        Anryton secures patient records with peer-to-peer storage and encryption. NFTs authenticate patient histories, while ANRY tokenizes billing for transparency.
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-4 cases_case-card-wrap" data-aos="fade-left">
                        <a href="javascript:void(0)" className="cases_case-card" onClick={() => setSupplyChain(true)}>
                            <div className="col-md-12 card h-100 p-4 cases_cases-card">
                                <div className="cases_card-top-img">
                                    <img src="images/supply-chain-case.svg" />
                                </div>
                                <div className="cases_card-body">
                                    <h2>
                                        <span>
                                            Supply Chain
                                        </span>
                                        <span>
                                            <img src="images/expand.svg" />
                                        </span>
                                    </h2>
                                    <p>
                                        Anryton's Blockchain ensures transparent product tracking with NFTs, ANRY token transactions, and efficient data storage, fostering trust in global commerce.
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-4 cases_case-card-wrap" data-aos="fade-right">
                        <a href="javascript:void(0)" className="cases_case-card" onClick={() => setFinance(true)}>
                            <div className="col-md-12 card h-100 p-4 cases_cases-card">
                                <div className="cases_card-top-img">
                                    <img src="images/finance-case.svg" />
                                </div>
                                <div className="cases_card-body">
                                    <h2>
                                        <span>
                                            Finance
                                        </span>
                                        <span>
                                            <img src="images/expand.svg" />
                                        </span>
                                    </h2>
                                    <p>
                                        Anryton addresses banking challenges with blockchain, ANRY token, smart contracts, KYC automation, ensuring swift, transparent, decentralized finance evolution.
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-4 cases_case-card-wrap" data-aos="fade-up">
                        <a href="javascript:void(0)" className="cases_case-card" onClick={() => setAgriculture(true)}>
                            <div className="col-md-12 card h-100 p-4 cases_cases-card">
                                <div className="cases_card-top-img">
                                    <img src="images/agri-case.svg" />
                                </div>
                                <div className="cases_card-body">
                                    <h2>
                                        <span>
                                            Agriculture
                                        </span>
                                        <span>
                                            <img src="images/expand.svg" />
                                        </span>
                                    </h2>
                                    <p>
                                        Anryton Blockchain ensures traceability from seeding to consumption, utilizing ANRY tokens for instant settlement and peer-to-peer storage for data integrity.
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-4 cases_case-card-wrap" data-aos="fade-left">
                        <a href="javascript:void(0)" className="cases_case-card" onClick={() => setIdentity(true)}>
                            <div className="col-md-12 card h-100 p-4 cases_cases-card">
                                <div className="cases_card-top-img">
                                    <img src="images/identity-case.svg" />
                                </div>
                                <div className="cases_card-body">
                                    <h2>
                                        <span>
                                            Identity
                                        </span>
                                        <span>
                                            <img src="images/expand.svg" />
                                        </span>
                                    </h2>
                                    <p>
                                        Anryton offers secure digital identity solutions with encryption, blockchain, and NFTs, ensuring instant verification and thwarting identity theft."
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-4 cases_case-card-wrap" data-aos="fade-right">
                        <a href="javascript:void(0)" className="cases_case-card" onClick={() => setGovernance(true)}>
                            <div className="col-md-12 card h-100 p-4 cases_cases-card">
                                <div className="cases_card-top-img">
                                    <img src="images/governance-case.svg" />
                                </div>
                                <div className="cases_card-body">
                                    <h2>
                                        <span>
                                            Governance
                                        </span>
                                        <span>
                                            <img src="images/expand.svg" />
                                        </span>
                                    </h2>
                                    <p>
                                        Anryton's blockchain enables transparent voting, ensuring secure, private votes and access to public records, transforming governance and empowering citizens.
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-4 cases_case-card-wrap" data-aos="fade-up">
                        <a href="javascript:void(0)" className="cases_case-card" onClick={() => setInsurance(true)}>
                            <div className="col-md-12 card h-100 p-4 cases_cases-card">
                                <div className="cases_card-top-img">
                                    <img src="images/insurance-case.svg" />
                                </div>
                                <div className="cases_card-body">
                                    <h2>
                                        <span>
                                            Insurance
                                        </span>
                                        <span>
                                            <img src="images/expand.svg" />
                                        </span>
                                    </h2>
                                    <p>
                                        Anryton's blockchain enables instant claims, smart contract automation, NFT tokenization for policies, secure peer-to-peer storage, and transparent insurance processes.
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-4 cases_case-card-wrap" data-aos="fade-left">
                        <a href="javascript:void(0)" className="cases_case-card" onClick={() => setEcommerce(true)}>
                            <div className="col-md-12 card h-100 p-4 cases_cases-card">
                                <div className="cases_card-top-img">
                                    <img src="images/e-commerce-case.svg" />
                                </div>
                                <div className="cases_card-body">
                                    <h2>
                                        <span>
                                            Ecommerce
                                        </span>
                                        <span>
                                            <img src="images/expand.svg" />
                                        </span>
                                    </h2>
                                    <p>
                                        Anryton's NFT platform ensures product authenticity, aids big brands, offers ANRY tokens for transactions, and prioritizes data protection.
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-4 cases_case-card-wrap" data-aos="fade-right">
                        <a href="javascript:void(0)" className="cases_case-card" onClick={() => setContent(true)}>
                            <div className="col-md-12 card h-100 p-4 cases_cases-card">
                                <div className="cases_card-top-img">
                                    <img src="images/digital-narrative.svg" />
                                </div>
                                <div className="cases_card-body">
                                    <h2>
                                        <span>
                                            Content and Media
                                        </span>
                                        <span>
                                            <img src="images/expand.svg" />
                                        </span>
                                    </h2>
                                    <p>
                                        Anryton simplifies content monetization and protection through NFTs, ANRY tokens, encryption, and peer-to-peer storage, empowering creators' voices.
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-4 cases_case-card-wrap" data-aos="fade-up">
                        <a href="javascript:void(0)" className="cases_case-card" onClick={() => setEducation(true)}>
                            <div className="col-md-12 card h-100 p-4 cases_cases-card">
                                <div className="cases_card-top-img">
                                    <img src="images/education-case.svg" />
                                </div>
                                <div className="cases_card-body">
                                    <h2>
                                        <span>
                                            Education
                                        </span>
                                        <span>
                                            <img src="images/expand.svg" />
                                        </span>
                                    </h2>
                                    <p>
                                        Anryton enables educational NFT certificates, ensuring authenticity, streamlining verification, and facilitating swift access to secure learning materials.
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-6 col-lg-4 mb-4 cases_case-card-wrap" data-aos="fade-left">
                        <a href="javascript:void(0)" className="cases_case-card" onClick={() => setLegal(true)}>
                            <div className="col-md-12 card h-100 p-4 cases_cases-card">
                                <div className="cases_card-top-img">
                                    <img src="images/legal-case.svg" />
                                </div>
                                <div className="cases_card-body">
                                    <h2>
                                        <span>
                                            Legal
                                        </span>
                                        <span>
                                            <img src="images/expand.svg" />
                                        </span>
                                    </h2>
                                    <p>
                                        Anryton offers secure storage for legal documents, facilitates smart contracts, utilizes NFTs, and ensures transparent, automated justice.
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>


        </main>
    )
}

export default ViewCases