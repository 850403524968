import './App.css';
import AppRoutes from './router';
import { PrimeReactProvider } from 'primereact/api';
import 'primeicons/primeicons.css';
// import CookieConsent from './components/layout/cookie-consent/cookieconsent';


function App() {
  return (
    <>
      <PrimeReactProvider>
        <AppRoutes />
      </PrimeReactProvider>
      {/* <CookieConsent /> */}
    </>
  );
}

export default App;
