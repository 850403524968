import React, { useState, useEffect, useRef } from 'react';
import { TabView, TabPanel } from 'primereact/tabview';
import { Button } from 'primereact/button';
import './whitepaper.scss';

function ViewWhitepaper(props) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [showSidebar, setShowSidebar] = useState(false);
    const [showButton, setShowButton] = useState(false);

    const sectionsRef = useRef([]);

    useEffect(() => {
        const handleResize = () => {
            setShowButton(window.innerWidth <= 1024);
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar);
    };

    const getTabClass = (index) => {
        return activeIndex === index ? 'whitepaper_tab-header active' : 'whitepaper_tab-header';
    };

    const handleTabClick = (index) => {
        setActiveIndex(index);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        if (window.innerWidth <= 1024) {
            setShowSidebar(false);
        }
    };

    const handleNext = () => {
        if (activeIndex < 40) {
            setActiveIndex((prevIndex) => prevIndex + 1);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    };

    const handlePrevious = () => {
        if (activeIndex > 0) {
            setActiveIndex((prevIndex) => prevIndex - 1);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    };

    return (
        <div className="container-fluid">
            <div>
                <div className="container services_container-mt pt-lg-0 pt-5">
                    {showButton && (
                        <div className='whitepaper_bi-list-icon whitepaper_toggler'>
                            <Button onClick={toggleSidebar}>
                                <span>
                                    <i className="bi bi-list"></i>
                                </span>
                                <span className='ms-3 whitepaper_toggle-text'>
                                    Whitepaper Menu
                                </span>
                            </Button>
                        </div>
                    )}
                    <div className="whitepaper_whitepaper-container">

                        <div className={`whitepaper_tab-headers ${showSidebar ? 'show-sidebar' : ''}`}>
                        
                            <div className="whitepaper_tab-title">Revolutionizing Secure, Scalable, and Quantum-Resistant Blockchain</div>
                            <div className={getTabClass(1)} onClick={() => handleTabClick(1)}>Abstract</div>
                            {/* <div className={getTabClass(2)} onClick={() => handleTabClick(2)}>The Origin of Bioaro</div> */}
                            <div className={getTabClass(2)} onClick={() => handleTabClick(2)}>Identifying Core Challenges</div>
                            {/* <div className="whitepaper_tab-title">Unserstanding Anryton</div> */}
                            <div className={getTabClass(3)} onClick={() => handleTabClick(3)}>Anryton: The Solution</div>
                            <div className={getTabClass(4)} onClick={() => handleTabClick(4)}>Addressing Security and Privacy Threats</div>
                            <div className={getTabClass(5)} onClick={() => handleTabClick(5)}>Anryton’s Vision</div>
                            <div className={getTabClass(6)} onClick={() => handleTabClick(6)}>Introduction to Anryton</div>
                            <div className={getTabClass(7)} onClick={() => handleTabClick(7)}>Key Features of Anryton</div>
                            
                            <div className={getTabClass(8)} onClick={() => handleTabClick(8)}>The Rise of Quantum Computing</div>
                            <div className={getTabClass(9)} onClick={() => handleTabClick(9)}>Global Payments and Accessibility</div>
                            <div className="whitepaper_tab-title">Research Behind Anryton</div>
                            <div 
                            className={getTabClass(10)} onClick={() => handleTabClick(10)}> Initial Exploration and Challenges 
                            </div>
                           
                            <div className={getTabClass(11)} onClick={() => handleTabClick(11)}>Decision to Build Anryton</div>

                             <div className="whitepaper_tab-title">Technical Details</div>
                            <div className={getTabClass(12)} onClick={() => handleTabClick(12)}>Architecture Overview
                            </div>
                            <div className={getTabClass(13)} onClick={() => handleTabClick(13)}> Advanced Governance Model

                            </div>
                            <div className={getTabClass(14)} onClick={() => handleTabClick(14)}>Validator Requirements</div>
                            <div className={getTabClass(15)} onClick={() => handleTabClick(15)}>RPC and Development Tools</div>
                            
                            <div className={getTabClass(16)} onClick={() => handleTabClick(16)}>Consensus Mechanism: Tendermint
                            </div>
                            <div className={getTabClass(17)} onClick={() => handleTabClick(17)}> Quantum-Proof Security

                            </div>

                            




                         <div className="whitepaper_tab-title">Anryton’s Roadmap</div>
                            <div className={getTabClass(18)} onClick={() => handleTabClick(18)}>Immediate Goals
                            </div>
                            <div className={getTabClass(19)} onClick={() => handleTabClick(19)}> Expanding the Ecosystem
                            </div>
                            <div className={getTabClass(20)} onClick={() => handleTabClick(20)}>Quantum-Resistance Integration Timeline</div>
                            <div className={getTabClass(21)} onClick={() => handleTabClick(21)}> Long-term Industry Adoption
                            </div>
                            {/* <div className={getTabClass(23)} onClick={() => handleTabClick(23)}> Strategic Vision
                            </div>
                            <div className="whitepaper_tab-title">Technical details </div>
                            <div className={getTabClass(24)} onClick={() => handleTabClick(24)}> Signature Algorithm
                            </div>
                            <div className={getTabClass(25)} onClick={() => handleTabClick(25)}> Machine Hardware Requirements
                            </div>
                            <div className={getTabClass(26)} onClick={() => handleTabClick(26)}> Hard Disk Type and Space
                            </div>
                            <div className={getTabClass(27)} onClick={() => handleTabClick(27)}> Operating Systment & Installation
                            </div>
                            <div className={getTabClass(28)} onClick={() => handleTabClick(28)}> How to use ?
                            </div>

                            <div className={getTabClass(29)} onClick={() => handleTabClick(29)}> Comparison to Popular Cryptocurrencies
                            </div>
                            <div className={getTabClass(30)} onClick={() => handleTabClick(30)}> RPC Client Support 
                            </div>
                            <div className={getTabClass(31)} onClick={() => handleTabClick(31)}> Blockchain Consensus Mechanism
                            </div>

                            <div className="whitepaper_tab-title">Quantum Proofing  </div>
                            <div className={getTabClass(32)} onClick={() => handleTabClick(32)}> Post-Quantum Cryptography
                            </div>
                            <div className={getTabClass(33)} onClick={() => handleTabClick(33)}> Zero Knowledge Proofs (ZKPs)
                            </div>
                            <div className={getTabClass(34)} onClick={() => handleTabClick(34)}> Anryton's Plan
                            </div>
                            <div className="whitepaper_tab-title">Blockchain Forks and Impact on Security  </div>
                            <div className={getTabClass(35)} onClick={() => handleTabClick(35)}> The Forking Process: Technical Overview
                            </div>
                         

                            <div className={getTabClass(36)} onClick={() => handleTabClick(36)}>Successful Blockchain Forks and Their Impact on Security
                            </div>
                            <div className={getTabClass(37)} onClick={() => handleTabClick(37)}> Impact on Security
                            </div>

                            <div className="whitepaper_tab-title">Roadmap for Future Development  </div>
                            <div className={getTabClass(38)} onClick={() => handleTabClick(38)}>Post-Mainnet Milestones
                            </div>
                            <div className={getTabClass(39)} onClick={() => handleTabClick(39)}> Healthcare Integration Roadmap
                            </div>
                            <div  className={getTabClass(40)} onClick={() => handleTabClick(40)}>Future-Proofing with Quantum Resistance
                            </div> */}
                           

                            
                        </div>
                        <div className='_mobile-container'>
                            <div className="whitepaper_navigation-buttons">
                                {/* Add buttons for next and previous */}
                                <Button label="Previous" className="p-button-secondary me-2 whitepaper_white-outline" onClick={handlePrevious} disabled={activeIndex === 0} />
                                <Button label="Next" className="p-button-primary whitepaper_white-outline" onClick={handleNext} disabled={activeIndex === 21} />
                            </div>
                            <div className="tab-content">
                                <TabView activeIndex={activeIndex} onTabChange={(e) => handleTabClick(e.index)}>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                        <h2>Revolutionizing Secure, Scalable, and Quantum-Resistant Blockchain for Global Data Solutions</h2>
                                        
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                    <h2>Anryton Overview</h2>

<p>Anryton, a Layer 1 blockchain developed by Bioaro, represents a decentralized ecosystem. Anryton has been designed to address the critical challenges in secure, scalable, and privacy-focused data management across industries.</p>

<p>Being a pioneer in genomics and personalized medicine, Bioaro recognized that conventional data storage systems could no longer fulfill the requirement for handling sensitive healthcare data. Fragmentation in data storage, increasing cyber threats, limited data ownership, and complex regulatory compliance, particularly for healthcare institutions, necessitated an innovative approach to data handling.</p>

<p>Anryton was designed as a blockchain-based ecosystem leveraging decentralized architecture and advanced cryptography to tackle such issues not just for healthcare but all industries. Utilizing the Cosmos SDK and Tendermint consensus protocol, Anryton offers EVM compatibility, enabling seamless migration for Ethereum-based applications. Its infrastructure is optimized for scalability and built to address the anticipated risks of quantum computing through the integration of quantum-resistant cryptographic protocols, making it future-proof against emerging security threats.</p>

<p>Anryton’s key focus areas are privacy-by-design, decentralized data ownership, and MOL—its native utility coin—facilitating seamless interaction within its ecosystem. MOL enables efficient, low-cost data storage and cross-border data-sharing capabilities, critical for industries such as healthcare, finance, and supply chain management. Anryton’s decentralized structure, supported by a secure validator network, mitigates the risks of data breaches and unauthorized access, empowering users with data sovereignty and secure sharing options.</p>

<p>Anryton’s post-quantum readiness and commitment to interoperability are the cornerstone of its innovative approach toward a future-safe solution. Leveraging the Cosmos Inter-Blockchain Communication (IBC) protocol, Anryton can interact with other blockchain networks, enabling cross-chain data exchanges and expanding its reach across various industries. Its integration with healthcare platforms like BioSport and BioEMR supports the secure management of real-time patient data and medical records while maintaining strict privacy controls. Quantum-proofing of Anryton’s ecosystem is a result of its lattice-based cryptography and Zero-Knowledge Proofs (ZKPs) integration plan.</p>

<p>Anryton’s infrastructure supports secure data management for multiple sectors, offering scalable solutions, high decentralization, and low transaction costs. Therefore, Anryton is a preferred solution for real-time applications, enterprise solutions, and decentralized finance (DeFi) ecosystems, where data privacy and security are essential. The long-term roadmap for Anryton includes a transition to a fully quantum-resistant protocol, ensuring that its Layer 1 blockchain remains secure against quantum threats while fostering global adoption of decentralized data solutions.</p>

<p>Anryton aims to redefine the outdated data management by creating a secure, cost-effective, and scalable platform. It stands as a foundational technology for secure data handling, ensuring that industries can leverage digital transformation without compromising on data integrity and privacy.</p>

                                    </TabPanel>
                                    {/* <TabPanel className='whitepaper_tabbed-panel-outline'>
                                    <h2>Bioaro Overview</h2>

<p>Bioaro, the company behind Anryton, is a cutting-edge technology company at the forefront of healthcare innovation in genomics, personalized medicine, and data-driven healthcare solutions. A critical aspect of Bioaro’s research and development initiative lies with how massive amount of data is stored, managed, and shared in the healthcare sector.</p>

<p>From advanced analytics, AI, and genomics to Blockchain and Quantum computing, Bioaro constantly explores the most efficient way to revolutionize healthcare. By working closely with researchers, healthcare providers, and patients, it has developed platforms capable of handling large datasets, including genomic sequences and patient records, critical for personalized medicine.</p>

<p>However, as these systems grew more complex, Bioaro began to encounter significant challenges with existing data management solutions. In particular, the privacy, security, and ownership of the sensitive healthcare data being processed is often questioned in existing systems.</p>

                                        
                                    </TabPanel> */}
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                    <h2>Identifying the Core Challenges in the Modern Digital World</h2>

<h3>Data Storage, Security, Ownership, and Privacy</h3>

<p>While handling huge data sets during its work in healthcare technology, Bioaro uncovered several critical issues surrounding data management that demanded immediate attention:</p>

<h4>Data Storage and Fragmentation</h4>
<p>Healthcare data grows by the second. Bioaro identified that traditional centralized systems struggled to handle such a scale, leading to fragmented data storage across various platforms. This fragmentation created inefficiencies and increased the risk of data breaches, as healthcare records became vulnerable when dispersed across multiple systems.</p>

<h4>Security Vulnerabilities</h4>
<p>There is a constantly increasing number of cyberattacks targeting healthcare institutions worldwide. Therefore, the inadequacy of conventional data security protocols is obvious. It underscores the risks associated with unauthorized access, data breaches, and hacking incidents.</p>

<h4>Data Ownership and Control</h4>
<p>Data ownership often feels like a luxury to patients along with global access to health records. Bioaro saw a critical need to empower patients by ensuring they could decide who had access to their medical records, how the data was used, and where it was stored.</p>

<h4>Privacy Concerns</h4>
<p>As patient data became more digitized, Bioaro recognized the heightened risk of privacy breaches. Sensitive information, including medical histories and genomic data, is being accessed, shared, or even sold without explicit patient consent. Ensuring the privacy of such data became paramount in protecting patient rights and maintaining trust in healthcare systems.</p>

                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                    <h2>Anryton: The Answer to Modern-day Data Challenges</h2>

<p>The challenges mentioned above became a prominent reason for Bioaro to explore advanced technological solutions. Through extensive research and development, Blockchain technology emerged as the ideal answer. With its decentralized architecture, transparent record-keeping, and cryptographic security, Blockchain is the perfect one-stop solution to modern-day data management issues.</p>

<p>This realization marked the advent of Anryton, Bioaro’s blockchain initiative. Anryton was developed as a Layer 1 blockchain, leveraging the inherent properties of Blockchain. These properties enable security, privacy, and data ownership, in any environment where data storage and retrieval are in question. Therefore, Anryton offered healthcare institutions, researchers, and patients a secure and scalable platform for managing healthcare data in a trustless environment.</p>

<p>However, Anryton soon transformed from a healthcare-specific solution to addressing the fundamental issues of data storage, security, ownership, and privacy for several industries.</p>

<p>This blockchain initiative represents the future of secure data management, offering a robust solution that addresses the challenges identified through Bioaro’s years of technological innovation.</p>

                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                    <h2>Addressing Data Security, Privacy, and Future Threats</h2>

<p>As data becomes more interconnected through cloud platforms, IoT devices, and integrated systems, ensuring secure data storage and communication is a critical challenge that needs to be addressed.</p>

<h3>Key challenges in traditional data management:</h3>

<h4>Data Breaches</h4>
<p>The sensitive nature of personal data makes it a prime target for cyberattacks. If breached, the personal data often leads to financial, legal, and reputational consequences. The fragmented nature of information across various platforms is one of the main reasons behind the risk of unauthorized access.</p>

<h4>Lack of Interoperability</h4>
<p>Current information systems operate in silos, preventing secure and seamless sharing of data across different institutions or geographical regions. This lack of interoperability can lead to inefficiencies and potential exposure of sensitive data.</p>

<h4>Regulatory Compliance</h4>
<p>Global regulations such as HIPAA, GDPR, and other local data protection laws place immense pressure on information providers and organizations. These regulations demand the secure handling of data, which increases the complexity of data management systems.</p>

<h4>Quantum Computing Threats</h4>
<p>With quantum computing poised to disrupt traditional encryption methods, data storage and sharing is particularly vulnerable. The cryptographic algorithms that currently secure sensitive data are at risk of being easily broken by quantum computers, making the need for quantum-resistant solutions urgent.</p>

<p>Addressing these challenges requires a new paradigm in data storage and security, one that incorporates advanced cryptographic techniques, decentralized data management, and future-proof solutions like quantum-safe encryption.</p>

                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                    <h2>Anryton’s Vision</h2>

<h3>The Need for a Secure, Scalable, and Cost-Effective Solution</h3>
<p>Bioaro envisions a future where healthcare data flows seamlessly across borders, institutions, and systems, maintaining the highest levels of security and privacy.</p>

<p>Anryton is an extension of that vision where all kinds of data can be stored and shared securely across all industries such as supply chain, education, finance, and more.</p>

<h3>Core Pillars</h3>
<p>It stands on four core pillars:</p>
<ul>
    <li><strong>Privacy by Design</strong></li>
    <li><strong>Scalability for Global needs</strong></li>
    <li><strong>Cost-Effective Cross-border Payments</strong></li>
    <li><strong>Quantum-proof</strong></li>
</ul>

<p>Ultimately, the vision is to create a future-ready digital environment equipped to handle the technological advancements of today and regulatory changes of tomorrow.</p>

                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                    <h2>Introduction to Anryton: A privacy-focused Layer 1 Blockchain for secure data storage</h2>

<p>Anryton is a purpose-built Layer 1 blockchain designed to address the unique challenges of modern-day data-driven society. Developed using the Cosmos SDK and backed by Tendermint’s consensus protocol, Anryton offers a robust and scalable infrastructure that prioritizes data privacy, security, and interoperability. Such an architecture allows Anryton to redefine how industries like aviation manage, store, and share critical data.</p>

                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                    <h2>Key Features of Anryton</h2>

<h3>EVMOS Compatibility</h3>
<p>Anryton is fully compatible with the Ethereum Virtual Machine (EVM). This further allows data applications, decentralized finance (DeFi) platforms, and other dApps built on Ethereum to seamlessly migrate or integrate with the Anryton ecosystem. It also gives Anryton access to Ethereum's extensive developer community while benefiting from the enhanced scalability features of Cosmos.</p>

<h3>Future-Ready with Quantum Cryptography</h3>
<p>One of Anryton’s core innovations is its research in quantum-resistant cryptographic protocols. As the threat of quantum computing looms over traditional encryption methods, Anryton is prepared to secure sensitive data information against future attacks. This ensures that the blockchain remains secure even in the post-quantum era, protecting the long-term integrity of data.</p>

<h3>Decentralized and Scalable Infrastructure</h3>
<p>Built on a decentralized network of validators, Anryton ensures that data is not stored in a single, vulnerable location. This decentralization allows the network to scale effortlessly without compromising security. Furthermore, Tendermint’s Byzantine Fault Tolerance (BFT) consensus protocol leads to fast and secure transactions, making it an ideal platform for handling payments, agreements, and real-time data sharing.</p>

<h3>Real-life Integration</h3>
<p>Anryton’s layer 1 has been integrated with healthcare platforms such as BioSport and BioEMR to ensure that patient data and medical records are managed with the highest level of security. These platforms enable healthcare providers to access and update patient records in real-time while maintaining strict privacy controls enforced by the blockchain.</p>

<h3>MOL Coin for Ecosystem Transactions</h3>
<p>Anryton’s native coin, MOL, powers the ecosystem, enabling secure and seamless transactions within the healthcare and associated sectors. MOL facilitates global payments at low cost and near-instant finality. MOL acts solely as a utility coin that facilitates data storage on Anryton’s Blockchain and empowers Anryton-based applications.</p>

                                    </TabPanel>


                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                    <h2>The Rise of Quantum Computing</h2>

<p>The era of quantum computing brings unprecedented computational power that is capable of solving complex problems.</p>

<p>However, this capability of quantum computers acts as a double-edged sword. Because of its computational abilities, current encryption protocols are vulnerable to quantum attacks, including RSA and elliptic curve cryptography (ECC) which are mostly used in the current systems. These protocols secure digital communications, banking systems, and data storage.</p>

<p>A quantum computer, leveraging algorithms like Shor’s algorithm, can break these encryption methods in a fraction of the time it would take a classical computer. Therefore, the encryption of today becomes obsolete under the threat of Q-day. From financial transactions to government secrets, entire systems would be at risk if quantum computers are able to break encryption models widely used across the globe.</p>

<p>The successful experiences in the recent past has made this threat more realistic, leading to Anryton’s Quantum-proofing plans. Some of the updates in the quantum world are:</p>

<h3>Google’s Quantum Supremacy Experiment</h3>
<p>In 2019, Google announced the achievement of “quantum supremacy” by solving a problem that would take the most advanced classical supercomputers thousands of years to compute. While the problem itself was specific and not immediately applicable to encryption, the experiment underscored the disruptive potential of quantum computing.</p>

<h3>IBM’s Quantum Progress</h3>
<p>IBM has been a leader in Blockchain and quantum research is no different. IBM is already offering cloud-based quantum computing platforms for commercial use. These advancements have shown real-world applications of quantum technology in industries like chemistry and pharmaceuticals.</p>

<p>Anryton, as part of its core mission, is addressing these near-future threats by incorporating quantum-resistant cryptographic algorithms. This forward-thinking approach positions Anryton as a blockchain ecosystem built to withstand the next generation of computing advancements, safeguarding sensitive data in sectors such as healthcare, finance, and beyond.</p>


 
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>

                                    <h2>The Necessity for Seamless Global Payment Systems within Decentralized Frameworks</h2>

<p>Secure and efficient cross-border transactions have become a necessity for both individuals and businesses. Traditional financial systems commonly face high transaction fees, slow processing times, and complex regulatory requirements, particularly when dealing with cross-border payments. These challenges hinder financial inclusion and make it difficult for people and businesses in developing economies to participate fully in the global economy.</p>

<p>Blockchain technology, particularly decentralized networks, offers a solution to these inefficiencies by enabling transparent, fast, and low-cost transactions across borders. Decentralized payment systems remove intermediaries, allowing for peer-to-peer transactions.</p>

<p>However, for these systems to truly replace or complement traditional financial systems, there is a need for a regulated currency backed by proven technology. Anryton’s layer 1 can be used to build stablecoins backed by or pegged to a country’s currency. Such stablecoins can be used to facilitate any global payment requirements. A universally recognized digital currency, like Anryton’s MOL coin, is being used as a utility coin within a decentralized framework, providing the following benefits:</p>

<h3>Cost-Effective Transactions</h3>
<p>By eliminating the need for intermediaries (such as banks or payment processors), blockchain-based payments significantly reduce transaction costs required for accessing decentralized storage.</p>

<h3>Fast, Secure Settlements</h3>
<p>In traditional financial systems, international payments can take several days to clear due to complex regulatory processes. Blockchain allows for near-instant settlements, regardless of geographic location. It further uses smart contracts to ensure that data storage payments are processed in a trustless environment.</p>

<h3>Financial Inclusion</h3>
<p>Through blockchain, anyone with an internet connection can participate in the Anryton ecosystem, eliminating the barriers posed by traditional infrastructures. Without the need for any special setup, users can enjoy unmatched privacy and security with Anryton.</p>

<p>Anryton’s MOL coin, integrated with its decentralized blockchain infrastructure, is designed to facilitate seamless global payments and access to a world-leading storage system. MOL ensures that transactions are secure, transparent, and cost-effective, supporting not only healthcare data needs but also global commerce. By backing this utility coin with a proven blockchain framework, Anryton addresses the critical need for a reliable payment solution that can operate at a global scale while maintaining compliance with local and international regulations.</p>

<p>Since Anryton is built specifically to address data security and storage concerns, it offers various advantages over other Blockchain layer 1 platforms.</p>

<p>The table below shows a simple comparison between Anryton, Bitcoin, and Ethereum’s layer 1 solutions.</p>

                                    <div className="table-container">
      <table className="whitepaper_table-theme">
        <thead>
          <tr>
            <th>Feature</th>
            <th>Bitcoin</th>
            <th>Ethereum</th>
            <th>Anryton</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Quantum-proof</td>
            <td>No (Vulnerable to quantum attacks)</td>
            <td>No (Vulnerable to quantum attacks)</td>
            <td className="highlight">Yes, with lattice-based cryptography</td>
          </tr>
          <tr>
            <td>Transaction Speed</td>
            <td>5 to 10 TPS</td>
            <td>10-20 TPS</td>
            <td className="highlight">Scalable up to 1000 TPS</td>
          </tr>
          <tr>
            <td>Finality</td>
            <td>Approx 60 mins</td>
            <td>Approx 5 mins</td>
            <td className="highlight">Near instant</td>
          </tr>
          <tr>
            <td>Transaction Cost</td>
            <td>High</td>
            <td>Medium to high (depending on network congestion)</td>
            <td className="highlight">Negligible</td>
          </tr>
          <tr>
            <td>Degree of Decentralization</td>
            <td>High</td>
            <td>High</td>
            <td className="highlight">High (10+ validator nodes)</td>
          </tr>
          <tr>
            <td>Security</td>
            <td>Strong (PoW with large hash power)</td>
            <td>Strong (PoS/PoW hybrid security)</td>
            <td className="highlight">Robust (ZKP + quantum-proof cryptography)</td>
          </tr>
        </tbody>
      </table>
    </div>


                                    </TabPanel>

                                    <TabPanel className='whitepaper_tabbed-panel-outline'>

                                    <h2>The Research Behind Anryton</h2>

<h3>Initial Exploration</h3>
<h4>2+ Years of Research into Decentralized Solutions</h4>
<p>In the early stages of Bioaro’s exploration into blockchain technology, the focus was clear—find a solution that could address the complex needs of the healthcare industry, especially with data management. Like many forward-thinking organizations, Bioaro recognized the transformative potential of blockchain to revolutionize data security and privacy. With healthcare data becoming more digitized and patient information being stored across various platforms, Bioaro initiated a deep dive into blockchain technologies, believing that decentralized systems held the key to ensuring the integrity and privacy of such sensitive information.</p>

<p>Over the next two years, Bioaro’s research team examined and experimented with several existing blockchain solutions, including decentralized storage platforms, decentralized applications (DApps), Layer 2 solutions, sharding techniques, and sidechains. These technologies offered promising features and theoretical advantages for handling large datasets as explained below.</p>

<h4>Decentralized Storage</h4>
<p>Initially, Bioaro explored decentralized storage platforms such as IPFS and Filecoin, which promised to eliminate the risks of centralized data silos by spreading data across a distributed network. These solutions were attractive for storing vast amounts of medical and genomic data, as they theoretically provided greater security and redundancy.</p>

<h4>DApps and Layer 2 Solutions</h4>
<p>Bioaro also explored building decentralized applications (DApps) on established blockchains such as Ethereum, and investigated Layer 2 scaling solutions like rollups to reduce transaction fees and improve network throughput. These methods aimed to enhance the performance of existing blockchains by offloading transactions from the main chain, which was appealing for applications that required real-time access to patient data or genomic analysis.</p>

<h4>Sharding and Sidechains</h4>
<p>To further address scalability challenges, Bioaro’s research also led to the exploration of sharding, where a blockchain is split into smaller pieces (shards) to process transactions in parallel, and sidechains, which allowed independent blockchains to run alongside the main chain for specific use cases. Both solutions held theoretical promise for improving performance and reducing congestion.</p>

<h3>Challenges Faced</h3>
<h4>Scalability, High Transaction Costs, and Securing Decentralized Storage</h4>
<p>Despite these promising technologies, Bioaro encountered significant challenges during the implementation phase, many of which exposed the shortcomings of existing blockchain solutions in meeting the unique demands of healthcare and other data-intensive industries.</p>

<h4>Scalability Issues</h4>
<p>While sharding and Layer 2 solutions helped to some extent, they were often not robust enough to handle the sheer volume of healthcare data being generated in real time. For example, genomic datasets alone contain billions of data points, making it extremely difficult for existing blockchains to process this data efficiently. The need for low-latency, high-throughput systems became apparent.</p>

<h4>High Transaction Costs</h4>
<p>Ethereum’s popularity and widespread use presented a fundamental challenge: high gas fees or unpredictable fees. For decentralized applications, especially those involving frequent interactions with the blockchain, the cost of executing transactions quickly became prohibitive. Healthcare organizations and institutions, that operate on tight budgets, could not justify the overhead costs of using such platforms when cheaper alternatives existed, even though those alternatives lacked the security blockchain offered.</p>

<h4>Securing Decentralized Storage</h4>
<p>Decentralized storage platforms such as IPFS faced challenges related to data availability and permanence. While data could be stored in a decentralized manner, ensuring that the data was always accessible and verifiable when needed was not guaranteed. Furthermore, the lack of a native incentive mechanism to ensure long-term storage reliability made decentralized storage platforms a less-than-ideal solution.</p>

<p>The friction caused by these challenges ultimately pushed Bioaro to rethink its approach.</p>


                                        
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                    <h2>The Decision to Build Anryton</h2>

<h3>A Purpose-Built Layer 1 Blockchain Solution</h3>
<p>Faced with the growing realization that existing technologies could not meet their needs, Bioaro made the pivotal decision to build Anryton, its own Layer 1 blockchain solution. The motivation behind this move was not to reinvent the wheel but to develop a blockchain that could address the specific challenges they had uncovered through their years of research and experimentation. The decision was clear: Create a solution that is secure, scalable, cost-effective, and future-proof.</p>

<h3>Why EVMOS?</h3>
<p>Bioaro chose EVMOS (Ethereum Virtual Machine Optimized) as the foundation for Anryton’s architecture because it combines the best of both worlds: compatibility with the Ethereum ecosystem and its vast developer base, while also integrating the high-performance, consensus-driven environment of the Cosmos SDK and Tendermint. This combination allowed Bioaro to build a blockchain that supports decentralized applications and smart contracts while offering much-needed improvements in scalability, performance, and interoperability.</p>

<h3>Solving Scalability and Transaction Costs</h3>
<p>By using Cosmos’ Tendermint consensus mechanism, Anryton delivers higher throughput and faster transaction finality. The move to a dedicated Layer 1 blockchain also allowed Bioaro to control and optimize transaction costs, making Anryton more cost-effective for large-scale use cases like healthcare data management. This shift ensured that the prohibitive gas fees of Ethereum would no longer be a barrier to implementing decentralized applications in healthcare.</p>

<h3>Decentralized, Secure Storage</h3>
<p>Anryton was also designed to tackle the challenges of decentralized storage by integrating quantum-resistant cryptography and incentivizing long-term storage mechanisms. This ensures that sensitive data stored on Anryton is secure from external threats and reliably available when needed. The system incorporates a built-in incentive structure for validators and storage nodes, guaranteeing the permanent availability and accessibility of critical healthcare data.</p>

<p>The decision to build Anryton was the result of over two years of research, experimentation, and hard-earned lessons from working with existing blockchain technologies. While decentralized storage, Layer 2 solutions, and sidechains showed potential, they fell short of Bioaro’s high standards for security, scalability, and cost-efficiency—particularly in the sensitive, data-heavy world of healthcare.</p>

<p>With Anryton, Bioaro lays the foundation for a future where secure, decentralized data management is the norm across all industries. By combining the best aspects of EVM compatibility, quantum resistance, and decentralized storage, Anryton represents the culmination of Bioaro’s journey to build a truly innovative and industry-specific blockchain solution.</p>

<h2>Evaluation of Different Blockchain Platforms</h2>
<p>The team behind Anryton conducted an extensive evaluation of various existing blockchain platforms to identify the one that best suited the needs of their data-intensive and security-focused applications. The primary criteria were scalability, transaction efficiency, interoperability, and future-proofing, particularly in light of emerging technologies like quantum computing. The evaluation considered several major blockchain platforms, including Solana, Algorand, Polkadot, Cardano, and others, each with its own strengths and limitations.</p>

<h3>Solana: High-Speed Transactions but Centralization Concerns</h3>
<p>Solana emerged as an early contender due to its high throughput, capable of processing thousands of transactions per second (TPS). Solana's Proof of History (PoH) consensus mechanism allows for rapid transaction processing by ordering events before they are confirmed by the network.</p>

<p><strong>However, Solana had the following concerns at the time:</strong></p>
<ul>
    <li><strong>Centralization Risk:</strong> The reliance on a small number of validators raised concerns about the platform’s degree of decentralization. In highly regulated industries like healthcare, centralization is a significant risk, as it can lead to potential single points of failure and reduced security.</li>
    <li><strong>Validator Requirements:</strong> Solana's validators require high hardware specifications, making it costly to operate nodes, which limits participation and decentralization, an important factor for a decentralized ecosystem that relies on widespread validation.</li>
</ul>

<h3>Algorand: A Focus on Efficiency but Limited Ecosystem</h3>
<p>Algorand, known for its Pure Proof of Stake (PPoS) consensus mechanism, offers a more efficient and environmentally friendly alternative. Algorand’s consensus ensures quick finality and low transaction costs, addressing some of the scalability issues seen in earlier blockchains.</p>

<p><strong>However, the Bioaro team found some limitations:</strong></p>
<ul>
    <li><strong>Smaller Ecosystem:</strong> While Algorand is highly efficient, its ecosystem is still relatively small compared to more established blockchains like Ethereum and Cosmos. For Bioaro, access to a vibrant developer and dApp ecosystem was critical to fostering innovation in healthcare data management.</li>
    <li><strong>Interoperability:</strong> Algorand’s interoperability solutions were still in development at the time of evaluation, limiting its ability to integrate with other blockchain systems.</li>
</ul>

<h3>Polkadot: Interoperability Pioneer but Scalability Concerns</h3>
<p>Polkadot was another strong contender, especially given its focus on interoperability and cross-chain communication through its Relay Chain and Parachains model. Polkadot enables different blockchains to interoperate, allowing data and assets to be transferred across otherwise siloed ecosystems.</p>

<p><strong>However, challenges arose:</strong></p>
<ul>
    <li><strong>Limited Parachain Slots:</strong> While Polkadot’s parachains enable scalability, there are a limited number of parachain slots available, making it difficult for every project to secure a spot.</li>
    <li><strong>Governance Complexity:</strong> Polkadot’s governance model is highly complex, which could present challenges in quickly implementing changes or adapting to evolving requirements, especially in a fast-paced industry like healthcare.</li>
</ul>

<h3>Cardano: Secure and Scalable but Slow to Develop</h3>
<p>Cardano’s Ouroboros Proof of Stake (PoS) protocol was highly appealing from a security and sustainability perspective. Cardano’s research-driven approach ensured that the platform was built on solid cryptographic principles, which aligned with Bioaro’s focus on security and privacy.</p>

<p><strong>However, there were some drawbacks:</strong></p>
<ul>
    <li><strong>Slow Development:</strong> Despite its strong academic foundations, Cardano’s development and release cycles were relatively slow, meaning that some features (such as smart contracts and dApp capabilities) were delayed compared to other platforms.</li>
    <li><strong>Ecosystem Maturity:</strong> Like Algorand, Cardano’s ecosystem, while growing, was still relatively small compared to Ethereum’s or Cosmos’, limiting access to a broader set of tools, developers, and partnerships.</li>
</ul>

<h3>Conclusion: The Choice of EVMOS</h3>
<p>After a thorough evaluation of the various platforms and their consensus mechanisms, Bioaro concluded that EVMOS (Ethereum Virtual Machine Optimized for Cosmos) was the ideal solution. EVMOS combined the best features of Ethereum’s widespread ecosystem and development tools with the scalability and modularity of the Cosmos blockchain architecture. This combination offered the flexibility, security, and scalability that Bioaro needed to build Anryton: an ideal Layer 1 blockchain.</p>

<h3>EVMOS Benefits: The Perfect Blend for Anryton’s Vision</h3>

<h4>Ethereum Compatibility</h4>
<p><strong>Access to Ethereum's Vast Ecosystem and Tools</strong></p>
<p>One of the primary advantages of EVMOS is its full compatibility with Ethereum, the largest and most widely used blockchain ecosystem in the world. Ethereum is home to the vast majority of decentralized applications (dApps), smart contracts, and decentralized finance (DeFi) platforms, making it an attractive option for developers and businesses alike.</p>

<p>By leveraging EVMOS, Anryton gains access to:</p>
<ul>
    <li><strong>Ethereum's Developer Community:</strong> Ethereum has thousands of active developers and a rich ecosystem of tools and libraries, such as Truffle, Hardhat, and MetaMask, that simplify the process of building and deploying decentralized applications. This gives Anryton an immediate advantage by allowing developers to migrate existing Ethereum applications or create new ones on a more scalable blockchain.</li>
    <li><strong>ERC-20 and ERC-721 Standards:</strong> EVMOS allows Anryton to natively support Ethereum's token standards, such as ERC-20 (for fungible tokens) and ERC-721 (for non-fungible tokens), which are essential for building a robust healthcare ecosystem where patient data, medical assets, and genomic sequences can be tokenized and securely transferred.</li>
    <li><strong>Interoperability with Ethereum DeFi:</strong> As of 2024, Ethereum hosts nearly $100 billion in total value locked (TVL) across DeFi protocols. By being EVM-compatible, Anryton can seamlessly interact with these DeFi systems, offering healthcare institutions access to decentralized finance solutions for funding, payments, and data monetization.</li>
</ul>

<h4>Cosmos Integration</h4>
<p><strong>Benefits of the Cosmos Ecosystem’s Scalability and Modularity</strong></p>
<p>EVMOS is built on Cosmos SDK, which provides an additional layer of scalability and modularity. Cosmos, known as the "Internet of Blockchains," allows different blockchains to interconnect through its Inter-Blockchain Communication (IBC) protocol. This is critical for industries that require seamless communication between different data systems and blockchains.</p>

<p>Key benefits of Cosmos integration include:</p>
<ul>
    <li><strong>Scalability:</strong> Cosmos' Tendermint consensus engine offers fast finality and high throughput, processing transactions at speeds of up to 10,000 TPS. This is crucial for applications that handle large datasets, such as genomic sequencing or real-time patient monitoring systems.</li>
    <li><strong>Modularity:</strong> Cosmos’ modular architecture allows Anryton to customize its blockchain stack to meet the specific needs of businesses. For example, Anryton can incorporate specialized governance models or storage solutions without sacrificing performance or security.</li>
    <li><strong>Interoperability with Other Blockchains:</strong> Through Cosmos’ IBC, Anryton can connect and share data with other blockchains in the Cosmos ecosystem, including those tailored for DeFi, supply chain management, and identity verification. This ensures that data on Anryton is accessible to a broader ecosystem while maintaining strict security controls.</li>
</ul>

<h4>Post-Quantum Cryptography Flexibility: Future-Proofing Security</h4>
<p>As quantum computing advances, it threatens to render traditional cryptographic algorithms, such as RSA and ECC, obsolete. Recognizing this looming challenge, Anryton chose EVMOS because of its flexibility in integrating post-quantum cryptographic algorithms, ensuring that the platform remains secure in the face of future technological disruptions.</p>

<ul>
    <li><strong>Quantum-Resistant Algorithms:</strong> Anryton is designed to incorporate quantum-resistant cryptography, such as lattice-based, hash-based, and multivariate quadratic algorithms. These algorithms have been identified as viable solutions for protecting data from quantum attacks, and ensuring long-term security for healthcare data, genomic information, and other sensitive assets stored on Anryton.</li>
    <li><strong>Modular Upgrades:</strong> The modular nature of Cosmos SDK allows Anryton to integrate these advanced cryptographic protocols seamlessly as they are developed, without disrupting the existing network. This adaptability ensures that Anryton can evolve as quantum computing technology matures.</li>
    <li><strong>Post-Quantum Readiness:</strong> By building on EVMOS, Anryton is not only prepared to face current security threats but is also equipped to meet the demands of a post-quantum world, safeguarding data for decades to come.</li>
</ul>

<p>After thoroughly evaluating various blockchain platforms, Bioaro’s choice of EVMOS for Anryton was driven by its unique combination of Ethereum compatibility, Cosmos-based scalability, and future-proof security.</p>

<p>EVMOS enables Anryton to access the vast Ethereum ecosystem, leverage Cosmos’ modular and scalable architecture, and remain secure against future quantum threats.</p>

                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                    <h2>Anryton Layer 1 Blockchain Architecture</h2>
    <p>The Anryton Layer 1 Blockchain is built on a highly configurable architecture, designed for enhanced security, decentralization, and network efficiency. Leveraging the Cosmos SDK and Tendermint consensus algorithm, Anryton incorporates a unique set of configurations optimized for its privacy-centric applications and decentralized ecosystem. Here’s a detailed look at the architectural parameters:</p>

    <h3>Network Configuration: Chain ID, Currency, and Validators</h3>
    
    <p><strong>Chain ID:</strong> <code>anry_130</code></p>
   <p> Chain ID serves as the unique identifier for the Anryton network, necessary for connecting to wallets, Web3 middleware, and decentralized applications (dApps). The chain ID allows for quick identification and helps prevent cross-chain interference with other blockchain networks.</p>

    <p><strong>Currency Symbol:</strong> <code>MOL</code></p>
    <p>
    The native currency of the Anryton network is MOL, which serves as the medium of exchange for all transactions. MOL is a utility token essential for staking, gas fees, and various governance mechanisms. The word “MOL” represents “precious” in other languages like Punjabi and Hindi.</p>

    <p><strong>Max Validators:</strong> <code>100</code></p>
    <p>
    Anryton supports up to 100 validators, ensuring decentralization, speed, and security by limiting the number of participants involved in consensus processes. The validators are responsible for block creation and validation, and their limited number ensures a balance between decentralization and network performance.</p>

    <h3>Staking and Validator Incentives</h3>
    
    <p><strong>Unbonding Time:</strong> <code>1814400s (21 days)</code></p>
    <p>
    Validators have a 21-day unbonding period to ensure network stability and prevent sudden drops in participation.</p>

    <p><strong>Base Proposer Reward:</strong> <code>0.01</code> | <strong>Bonus Proposer Reward:</strong> <code>0.04</code></p>
    <p>
    Validators receive both a base proposer reward and a bonus reward for successfully proposing a block. These incentives, totaling 0.05, encourage validator participation and secure the network’s operations.</p>

    <h3>Historical and Bond Denomination</h3>
    
    <p><strong>Historical Entries:</strong> <code>10000</code></p>
    <p>
    Anryton supports up to 10,000 historical entries for each validator, ensuring that comprehensive records of past performance are maintained for audit, analysis, and network efficiency monitoring.</p>

    <p><strong>Bond Denom:</strong> <code>mol</code></p>
    <p>
    mol is also the designated bond denomination for staking activities. Validators and delegators use mol to participate in staking, further reinforcing its integral role in Anryton’s ecosystem.</p>

                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                    <h2>Advanced Governance Configuration</h2>
    <p>Anryton’s advanced governance configurations foster decentralized decision-making by ensuring that all stakeholders have a say in the network’s future through voting mechanisms:</p>

    <p><strong>Max Deposit Period:</strong> <code>172800s (48 hours)</code></p>
    <p>
    The maximum deposit period is set to 48 hours, allowing participants a two-day window to deposit tokens for governance proposals.</p>

    <p><strong>Voting Period:</strong> <code>432000s (5 days)</code></p>
    <p>
    The voting period is set to 5 days, during which network participants can cast their votes on governance proposals.</p>

    <p><strong>Quorum:</strong> <code>0.334</code> | <strong>Threshold:</strong> <code>0.5</code> | <strong>Veto Threshold:</strong> <code>0.334</code></p>
    <p>
    For any governance proposal to pass, at least 33.4% of the voting power must participate (Quorum), and 50% of those voting must support the proposal (Threshold). A veto threshold of 33.4% prevents any single minority from blocking the decision-making process.</p>


    
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                   
                                    <h2>Validator Security and Penalties</h2>
    <p>Anryton has strict measures in place to ensure validators operate securely and consistently, minimizing risks of network disruptions:</p>

    <p><strong>Signed Blocks Window:</strong> <code>35000</code> | <strong>Min Signed Per Window:</strong> <code>0.5</code></p>
    <p>
    Validators must sign at least 50% of the blocks within a window of 35,000 blocks, ensuring that they actively participate in block validation.</p>

    <p><strong>Downtime Jail Duration:</strong> <code>7200s (2 hours)</code></p>
    <p>
    Validators that miss block validations are subject to penalties, including being jailed for a 2-hour duration during which they are restricted from participating in network activities.</p>

    <p><strong>Slash Fraction Doublesign:</strong> <code>0.02</code> | <strong>Slash Fraction Downtime:</strong> <code>0.0001</code></p>
    <p>
    If a validator is found double-signing blocks, they are penalized with a 2% slash of their staked tokens. Validators that experience significant downtime are penalized with a minimal slash of 0.01%, encouraging continuous uptime and proper validator behavior.</p>

    <p>Anryton's architecture ensures a robust, secure, and decentralized blockchain network. By maintaining a well-balanced governance model and incentivizing validators appropriately, it aligns with its vision of privacy, security, and decentralized innovation.</p>
    <h2>EVM Compatibility and Developer Ecosystem</h2>

<p>Anryton's architecture is built on the Evmos framework, making it fully EVM-compatible. This allows developers to easily deploy Ethereum-based applications on Anryton with minimal changes. Key benefits of this EVM compatibility include:</p>

<h3>Metamask Integration</h3>
<p>Since Anryton is compatible with EVM, it can be added to Metamask, allowing users to interact with Anryton dApps directly through a familiar interface.</p>

<h3>Smart Contract Development</h3>
<p>Developers can continue to write smart contracts using Solidity, the widely adopted language for smart contract development on Ethereum. This minimizes the learning curve for developers who are already familiar with Ethereum's development environment.</p>

<h2>Consensus Mechanism: Tendermint</h2>

<p>Anryton utilizes Tendermint as its consensus algorithm, a proven and highly secure protocol widely used across the Cosmos ecosystem. Key advantages of Tendermint include:</p>

<h3>Security</h3>
<p>Tendermint is recognized as one of the most secure consensus algorithms in the blockchain space, ensuring safety and liveness even under adversarial conditions.</p>

<h3>Open-Source</h3>
<p>As an open-source algorithm, Tendermint enables transparency, community-driven innovation, and regular security audits to maintain its robustness.</p>

<h3>Performance</h3>
<p>Tendermint provides fast finality, making Anryton capable of handling high-throughput applications and enterprise solutions without compromising on security.</p>

<h2>Token Supply and Economics</h2>

<p><strong>100 Million MOL:</strong> Reserved for network operations, validator rewards, and staking. This supply ensures that the Anryton network can run efficiently for several decades, even under extreme conditions. In enterprise use cases, the network could potentially sustain itself for up to a century.</p>

<h3>mol vs MOL</h3>
<p>The smallest unit of MOL is mol, similar to how ETH is broken down into WEI. 1 MOL equals 1,000,000,000,000,000,000 mol, enabling precision in transaction handling and staking.</p>

<h2>Validator Staking Requirements</h2>

<p>Validators are integral to maintaining the security and consensus of the Anryton network. The following staking structure applies to validators:</p>

<p><strong>40 MOL</strong> must be staked by each validator to join the network, incentivizing honest behavior and securing their role in block production.</p>

<p>This staking model not only incentivizes network participation but also ensures that only committed and reliable validators are involved in the network’s consensus process.</p>

<h2>Manual Network Addition in Metamask</h2>

<p>To interact with the Anryton network using Metamask, users need to manually add the network by entering key details such as the Chain ID and RPC URL, which are laid out in the network's official documentation. This allows users to seamlessly connect to Anryton's ecosystem through Metamask and use MOL for transactions, staking, and governance.</p>

<h2>Strategic Vision</h2>

<p>Anryton’s long-term vision is to create a blockchain network that combines the best of both worlds— Ethereum’s EVM ecosystem and Cosmos’s scalability and security. This hybrid model ensures that developers can seamlessly transition from Ethereum to Anryton while leveraging the scalability and performance benefits provided by Cosmos.</p>

<p>The 400 million MOL supply ensures the longevity and sustainability of the network, with strategic reserves in place to fuel ecosystem growth, future partnerships, and technological advancements.</p>

<p>Anryton's combination of advanced architecture, developer-friendly environment, and robust security makes it well-positioned to lead in decentralized innovation across both B2B and decentralized finance (DeFi) sectors.</p>

                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                    <h2>Technical Details of Anryton’s Layer 1 Blockchain</h2>

<h3>Signature Algorithm</h3>
<p>Anryton supports multiple signature algorithms to ensure secure and reliable transactions on its network. These include:</p>
<ul>
    <li><strong>ECDSA:</strong> Elliptic Curve Digital Signature Algorithm, widely used in blockchain networks for secure transaction verification.</li>
    <li><strong>ED25519:</strong> A modern public-key signature system that offers enhanced security and speed.</li>
</ul>
<p>Anryton also adheres to the EIP-712 standard, which defines a structured and secure way to sign off-chain data, adding a layer of protection for interactions with dApps and other external tools.</p>

<h3>Public RPC Node URL</h3>
<p>For developers and users looking to interact with the Anryton blockchain, a public RPC node is available at:</p>
<p><strong>Public RPC URL:</strong> <a href="http://jsrpc.anryton.com">jsrpc.anryton.com</a></p>
<p>This node provides a gateway for connecting applications, wallets, and other services to the Anryton blockchain in a secure and scalable manner.</p>

<h3>Machine Hardware Requirements</h3>
<p>To set up and run an Anryton node, the following hardware specifications are recommended:</p>
<ul>
    <li><strong>CPU:</strong> At least 4 physical CPU cores.</li>
    <li><strong>Storage:</strong> A minimum of 500GB of NVME SSD storage for fast data access and optimal performance.</li>
    <li><strong>Memory:</strong> A minimum of 32GB of RAM to ensure smooth operation and handling of large datasets.</li>
</ul>
<p><em>Note:</em> For nodes requiring more computational power, such as validators or high-traffic dApps, additional CPU and memory resources may be necessary.</p>

<h3>Hard Disk Type and Space</h3>
<p>Anryton requires at least 500GB of NVME SSD storage, which ensures fast data processing and retrieval. The use of NVME SSD over traditional hard drives significantly improves the node's performance, especially under heavy load conditions.</p>

<h3>Wallet/Node Installation</h3>
<p>Anryton supports both Ubuntu 16.04 and Ubuntu 18.04 for node or wallet installation. Detailed installation guides for setting up a node on these platforms are available in Anryton's official documentation, guiding users through the steps for proper configuration, syncing, and maintaining a node on the network.</p>

<h3>Exchange Integration and RPC Methods</h3>
<p>Anryton supports a variety of JSON-RPC methods that facilitate smooth interaction with its blockchain, particularly for Ethereum-compatible applications. The following key namespaces are supported:</p>

<h4>1. Ethereum Namespace (eth):</h4>
<ul>
    <li><code>eth_getBalance</code>: Returns the balance of a specified account.</li>
    <li><code>eth_getStorageAt</code>: Fetches storage data from a specific location at a given block.</li>
    <li><code>eth_getTransactionCount</code>: Retrieves the total number of transactions sent from a given address.</li>
    <li><code>eth_getBlockTransactionCountByNumber</code>: Returns the number of transactions in a block by its number.</li>
    <li><code>eth_getBlockTransactionCountByHash</code>: Returns the number of transactions in a block by its hash.</li>
    <li><code>eth_getCode</code>: Retrieves the code stored at a specified address.</li>
    <li><code>eth_sign</code>: Signs a message with a private key.</li>
    <li><code>eth_sendTransaction</code>: Sends a transaction to the network for processing.</li>
    <li><code>eth_sendRawTransaction</code>: Sends a pre-signed raw transaction to the network.</li>
    <li><code>eth_call</code>: Executes a smart contract method without creating an actual transaction.</li>
    <li><code>eth_estimateGas</code>: Estimates the gas required to execute a transaction.</li>
</ul>

<h4>2. Debug Namespace (debug):</h4>
<p>Provides several non-standard RPC methods to help developers debug and inspect the blockchain's current state.</p>

<h4>3. Transaction Pool Namespace (txpool):</h4>
<ul>
    <li><code>txpool_content</code>: Shows the detailed content of the transaction pool.</li>
    <li><code>txpool_inspect</code>: Provides insights into the current state of the transaction pool.</li>
    <li><code>txpool_status</code>: Displays the status of the transaction pool.</li>
</ul>

<p>Anryton supports JSON-RPC over HTTP and WebSocket protocols, providing flexible integration options for developers.</p>

<h3>Example cURL Commands</h3>
<p>To interact with the Anryton blockchain via RPC, you can use the following cURL command as an example to fetch the balance of a specified address:</p>
<p><code>curl -X POST --data '&#123;"jsonrpc":"2.0","method":"eth_getBalance","params":["0xYourAddress", "latest"],"id":1&#125;' -H "Content-Type: application/json" http://localhost:8545</code></p>
<p>This command can be easily adapted for other RPC methods based on the parameters required for your use case.</p>

<h3>How to Generate an Address/Mnemonic for Deposits</h3>
<p>Generating a wallet address or mnemonic for users to deposit funds is a crucial part of interacting with the Anryton blockchain. Follow these steps to generate or retrieve Ethereum-compatible addresses using JSON-RPC:</p>

<p><strong>Set Up Your Environment:</strong> Ensure that you have access to an Anryton node with JSON-RPC enabled. You can either:</p>
<ul>
    <li>Use the public RPC endpoint at <a href="http://jsrpc.anryton.com">jsrpc.anryton.com</a>.</li>
    <li>Run your own Anryton node to enable complete control over your environment.</li>
</ul>

<h4>Generate or Retrieve an Address Using the <code>eth_accounts</code> Method</h4>
<p>You can execute the following cURL command to call the <code>eth_accounts</code> method and retrieve an address:</p>
<p><code>curl -X POST --data '&#123;"jsonrpc":"2.0","method":"eth_accounts","params":[],"id":1&#125;' -H "Content-Type: application/json" http://localhost:8545</code></p>
<p>This command will return a list of wallet addresses controlled by the client that you can use for deposits.</p>

<h3>How to Retrieve Block Information</h3>
<p>Anryton provides a set of JSON-RPC methods for retrieving key block information. Example command:</p>
<p><code>curl -X POST --data '&#123;"jsonrpc":"2.0","method":"eth_getBlockByNumber","params":["0xBlockNumber", true],"id":1&#125;' -H "Content-Type: application/json" http://localhost:8545</code></p>

<h3>Iterating Through Transactions by Transaction IDs</h3>
<p>Use the <code>eth_getTransactionByHash</code> method to fetch transaction details:</p>
<p><code>curl -X POST --data '&#123;"jsonrpc":"2.0","method":"eth_getTransactionByHash","params":["0xYourTransactionHash"],"id":1&#125;' -H "Content-Type: application/json" http://localhost:8545</code></p>

<h3>Comparison to Popular Cryptocurrencies</h3>
<p>Anryton is a highly scalable, EVM-compatible Layer 1 blockchain built on the Cosmos SDK and utilizing the Tendermint Core consensus algorithm.</p>
<h2>RPC Client Support in Java</h2>
    <p>There is no dedicated Java RPC client for Anryton, but Java developers can interact with the Anryton blockchain using libraries that support Ethereum JSON-RPC APIs. Since Anryton is EVM-compatible, any Java library that can connect to Ethereum's JSON-RPC interface (such as Web3j) can be used to perform transactions, query account balances, and interact with smart contracts on Anryton.</p>

    <h2>Transaction Types: Transfer, Swap, Mint, and Batch Send</h2>
    <p>Anryton supports multiple transaction types, leveraging the Cosmos and Ethereum ecosystems. Below is an overview of these transaction types:</p>

    <h3>Cosmos Transactions</h3>
    <p>Cosmos-based transactions in Anryton consist of multiple messages (<code>sdk.Msg</code>) that trigger state changes within Cosmos modules. Key features include:</p>

    <ul>
        <li><strong>Multiple Messages:</strong> Cosmos transactions can bundle multiple messages into a single transaction, unlike Ethereum transactions.</li>
    </ul>
    <p><strong>Components:</strong></p>
    <ul>
        <li><strong>Msgs:</strong> An array of actions to be executed.</li>
        <li><strong>GasLimit:</strong> The maximum amount of gas the user is willing to pay for executing the transaction.</li>
        <li><strong>FeeAmount:</strong> The fee amount for the transaction.</li>
    </ul>

    <h3>Ethereum Transactions</h3>
    <p>Ethereum-compatible transactions in Anryton follow the structure of typical Ethereum transactions, with each transaction encapsulating a single message. Key components include:</p>

    <ul>
        <li><strong>Recipient:</strong> The address receiving the tokens.</li>
        <li><strong>Signature:</strong> The signature of the sender to validate the transaction.</li>
        <li><strong>Nonce:</strong> A counter for the number of transactions sent from the sender’s account.</li>
        <li><strong>Value:</strong> The amount of tokens to be transferred (in <code>wei</code>).</li>
        <li><strong>Data:</strong> Arbitrary data for smart contract interactions.</li>
        <li><strong>GasLimit:</strong> The maximum gas consumption.</li>
        <li><strong>maxPriorityFeePerGas</strong> and <strong>maxFeePerGas</strong>: Used for fee calculation, supporting EIP-1559 fee structures.</li>
    </ul>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                    <h2>Blockchain Consensus Mechanism</h2>
    <p>Anryton operates on a Proof of Stake (PoS) consensus mechanism. Validators in the Anryton network participate in block creation and verification based on the amount of staked MOL tokens. PoS allows for a more energy-efficient consensus process compared to Proof of Work (PoW) systems, as it does not require significant computational power for mining, while still maintaining high security and decentralization.</p>

                                      
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                    <h2>Post-Quantum Cryptography</h2>
    <p>Unlike traditional or classic computers, Quantum computers use qbits, which allows them to possess unimaginable computational power. By directly manipulating the elements, quantum computers and Quantum algorithms have the capability of breaking complex cryptographic algorithms in seconds. Traditional cryptographic methods, such as RSA and Elliptic Curve Cryptography (ECC) are especially important to note as they are the most commonly used algorithms, and most vulnerable to quantum attacks.</p>
    
    <p>Fortunately, even though Quantum computing is not practically possible at the moment, quantum-based cryptography is available to be implemented.</p>

    <p>One of the post-quantum techniques that has been validated as an effective protection against future quantum attacks is lattice-based cryptography, a type of cryptography built on hard mathematical problems related to lattice structures.</p>
    
    <p>Together with Zero Knowledge Proofs (ZKPs), lattice-based cryptography offers both security and privacy, making it a powerful tool for ensuring that sensitive data, particularly in sectors like healthcare, remains protected.</p>

    <h3>Anryton’s Roadmap on Incorporating Lattice-Based Cryptography</h3>
    <p>In anticipation of the challenges posed by quantum computing threats, Anryton has conceived a lattice-based cryptographic integration strategy into its blockchain protocol and not solely as an add-on. Through a fork, this integration will involve building a quantum secure version of the Anryton blockchain. By adopting lattice encryption and zero knowledge proofs, Anryton will guarantee security, privacy of the platform and its sustainability in the years to come.</p>

    <h3>The Approach for the Forking of Anryton for Lattice-Based Cryptography</h3>
    <p>The term forking commonly refers to a feature of adaptation in blockchain where either an upgrade or a modification is introduced to the blockchain protocol giving rise to a new network. This may lead to two distinct chains existing in the same space – one which runs the unchanged protocol while the other runs the modified.</p>
    
    <p>In the case of Anryton, the focus of the fork will be on implementing lattice-based cryptography within the system core for future quantum threats.</p>

    <p><strong>The fork will be implemented in the following way:</strong></p>
    <ul>
        <li>Anryton will initiate an open governance proposal, explaining the need for lattice-based cryptography and detailing the benefits it will bring in terms of post-quantum security.</li>
        <li>This will involve discussions with node operators, validators, and other key stakeholders to secure consensus before the fork.</li>
        <li>Once consensus is achieved, the implementation process will begin.</li>
        <li>The Anryton development team will create a branch of the existing Anryton blockchain that incorporates lattice-based cryptographic algorithms.</li>
        <li>This branch will undergo extensive testing to ensure compatibility with the existing ecosystem, dApps, and smart contracts.</li>
        <li>The quantum-resistant version of Anryton will be deployed on a testnet.</li>
        <li>After successful testing, the fork will be implemented on the Anryton mainnet.</li>
        <li>This fork will establish a new chain with quantum-resistant cryptography, while the original chain will continue running in parallel for a transitional period.</li>
        <li>Anryton will offer tools for migrating data and assets securely from the original chain to the forked quantum-resistant chain.</li>
        <li>The governance framework of the blockchain will be adjusted to incorporate any additional needs for quantum cryptography management, including decisions around key management and protocol updates.</li>
    </ul>

    <h2>Successful Blockchain Forks</h2>
    <p>As Blockchains are immutable, the only way to improve a Blockchain is to update the protocol rather than changing it from the core. Therefore, Blockchains go through a process of fork where a change is proposed among all validators of the Blockchain network. If the network approves, all new blocks are created using the latest changes. These changes can be anything from block size to consensus protocol.</p>
    
    <p>Over the years, many blockchains have gone through forks, resulting in enhancement of their existing capabilities. Below are some key examples of successful forks and their impacts on the respective protocols' security.</p>

    <h3>1. Bitcoin Cash (2017)</h3>
    <p>Bitcoin’s network was experiencing congestion, leading to slow transaction times and high fees. Some community members proposed increasing the block size to allow more transactions per block, while others opposed this.</p>
    
    <p>The Bitcoin Cash fork resulted in a new blockchain for the community members who wanted better throughput. This fork resulted in an increased block size of 8MB (later 32MB) to improve transaction throughput.</p>

    <p><strong>Impact on Security:</strong></p>
    <ul>
        <li>The larger block size allowed for faster transaction processing, reducing the likelihood of transaction delays.</li>
        <li>Bitcoin Cash maintained the core security mechanisms of Bitcoin, including its Proof of Work (PoW) consensus, making it equally secure against 51% attacks and other vulnerabilities that Bitcoin had already addressed.</li>
        <li>However, while Bitcoin Cash improved scalability, it did not introduce significant new cryptographic security features.</li>
    </ul>

    <h3>2. Ethereum Fork (2016)</h3>
    <p>One such controversy involved Ethereum and what’s become referred to as the DAO Fork – one of the most important and publicized forks ever in the history of blockchain.</p>
    
    <p>In light of the fact that there was a hack of the DAO funds and approximately 3 million Ethereum (ETH) were “stolen” in the process, the community came together and voted to hard fork the blockchain with the intention of returning the lost funds back to the initial holders.</p>

    <p>There were two forks that resulted from this hard fork:</p>
    <ul>
        <li>The DAO support protocol.</li>
        <li>The Ethereum Classic cryptocurrency.</li>
    </ul>

    <p>This decision meant that Ethereum had the ability to correct the flaws which were responsible for the leading loss of funds, and ushered in a new ethos towards the concept of smart contract development focused on security. Enhanced security and security auditing of the Ethereum was borne from this event.</p>

    <p>With the network’s ability to reverse the hack of the DAO, trust in the Ethereum network was regained, reinforcing Ethereum’s commitment to security and the community’s strength in maintaining the best interests of the network.</p>

    <p>The Shelley fork transformed Cardano into one of the most decentralized blockchains, enhancing both its security and operational integrity.</p>

                                    </TabPanel>

                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                    <h2>Anryton's Vision and Roadmap</h2>
    <p>Anryton's vision extends far beyond the successful launch of its mainnet. With a focus on secure data management, privacy, and post-quantum cryptography, Anryton has laid out a comprehensive roadmap to guide its growth and long-term sustainability. Below is a detailed overview of the key milestones in Anryton’s post-mainnet phase, its healthcare integration strategy, and its approach to future-proofing the blockchain with quantum-resistant technology.</p>
    <h2>Post-Mainnet Milestones: Key Developments and Ecosystem Expansion</h2>
    <p>Following the successful launch of the Anryton mainnet, the focus will shift towards expanding its ecosystem, refining core features, and onboarding critical partners. The post-mainnet milestones are centered around enhancing platform functionality, driving adoption, and expanding use cases across various industries.</p>

    <h3>Year 1: Immediate Post-Mainnet Focus</h3>
    <ul>
        <li><strong>Onboarding Validators:</strong> Establishing a decentralized network of validators will be a top priority. Validators will be incentivized to ensure network security and handle increasing transaction volumes as new healthcare and enterprise solutions are onboarded.</li>
        <li><strong>Cross-Chain Integrations:</strong> Anryton will actively pursue integrations with other blockchains, especially within the Cosmos ecosystem, using the Inter-Blockchain Communication (IBC) protocol. This will allow seamless data sharing and asset transfers between Anryton and other blockchains like Terra or Osmosis.</li>
        <li><strong>Global Storage Rollout:</strong> In partnership with major information providers, Anryton will work towards large-scale adoption across industries. The aim is to make Anryton the backbone of data infrastructure globally, facilitating secure data sharing across borders while adhering to local privacy laws.</li>
        <li><strong>Enterprise Adoption:</strong> Anryton will work with companies in finance, supply chain, and data management to integrate decentralized storage that leverages Anryton's privacy and security features. Use cases will include decentralized finance (DeFi) solutions and secure supply chain tracking.</li>
    </ul>
                                    </TabPanel>

                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                    <h3>Year 2-3: Expanding the Ecosystem</h3>
    <ul>
        <li><strong>Decentralized Application (dApp) Marketplace:</strong> Launch of a decentralized application marketplace to allow third-party developers to build on top of Anryton. This will accelerate innovation within the ecosystem and encourage the creation of healthcare-focused dApps, such as secure telemedicine platforms and decentralized health data exchanges.</li>
        <li><strong>Partnership with National Healthcare Systems:</strong> Anryton will aim to partner with government health organizations to introduce decentralized health data storage and secure medical record systems. This could include collaborations with healthcare ministries and public hospitals in both developed and developing countries.</li>
        <li><strong>NFTs for Medical Data and Genomics:</strong> Expanding the use of NFTs (Non-Fungible Tokens) to represent unique pieces of medical data, such as genomic sequences or personalized health records, enabling patients to own and control their own health data securely.</li>
    </ul>

    <h3>Year 4 and Beyond: Global Expansion</h3>
    <ul>
        <li><strong>Global Healthcare Adoption:</strong> Anryton will push for widespread adoption across healthcare institutions globally. This includes large-scale implementations with hospitals, research institutions, and insurance providers to ensure global accessibility and data portability.</li>
        <li><strong>Enterprise Solutions for Data Privacy:</strong> Anryton will also expand into industries that require high levels of data security, such as finance, legal, and intellectual property management. The goal is to position Anryton as the go-to platform for secure, decentralized data storage and privacy solutions in industries where data ownership and security are paramount.</li>
    </ul>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                    <h2>Healthcare Integration Roadmap: Scaling Anryton Within the Healthcare Industry</h2>
    <p>Anryton’s integration into the healthcare sector will be one of the primary focuses in its post-mainnet phase. The goal is to provide healthcare institutions with a secure and decentralized way to manage sensitive patient data, genomic information, and research results. The integration roadmap outlines how Anryton will scale within healthcare, starting with BioChain and moving toward industry-wide adoption.</p>

    <h3>Phase 1: Initial Healthcare Integrations (Year 1)</h3>
    <ul>
        <li><strong>BioChain Pilot Program:</strong> Anryton will begin with a pilot integration of BioChain, a decentralized data management platform designed to store, secure, and share genomic and health data. The pilot will involve a select group of hospitals, genomic research institutions, and patient advocates. BioChain will leverage Anryton's privacy-preserving features to ensure that patients control their data and consent to its use.</li>
        <li><strong>Data Privacy Audits:</strong> As part of the initial rollout, third-party data privacy audits will be conducted to ensure compliance with healthcare regulations such as HIPAA in the United States and GDPR in Europe. This will build trust and credibility with healthcare institutions and patients.</li>
    </ul>

    <h3>Phase 2: Scaling Healthcare Adoption (Year 2-3)</h3>
    <ul>
        <li><strong>Partnerships with Research Institutions:</strong> Anryton will expand its reach by partnering with medical research organizations and universities, providing a secure platform for storing and analyzing large-scale genomic data. This will enable secure collaboration between researchers while ensuring the privacy and ownership of sensitive patient data.</li>
        <li><strong>Decentralized Medical Record System:</strong> Anryton will introduce a decentralized electronic health record (EHR) system that allows patients and healthcare providers to store and access medical records securely. This system will enable healthcare institutions to share data in real-time while preserving patient privacy and complying with regulations.</li>
        <li><strong>Integration with BioSport and BioEMR:</strong> Expanding on the existing integrations with BioSport and BioEMR, Anryton will develop deeper integrations with healthcare platforms, allowing secure, real-time updates to patient health records, fitness data, and medical histories. This integration will be pivotal for creating a cohesive health data ecosystem.</li>
    </ul>

    <h3>Phase 3: Full Industry Integration (Year 4 and Beyond)</h3>
    <ul>
        <li><strong>Decentralized Clinical Trials:</strong> Anryton will introduce blockchain-based solutions for clinical trials, allowing for transparent and secure collection of patient data and trial results. This will streamline the process of regulatory approval and ensure the integrity of trial data.</li>
        <li><strong>Blockchain-Based Insurance Claims:</strong> By partnering with healthcare insurance providers, Anryton will enable decentralized and transparent insurance claims processes, reducing fraud and improving the speed and accuracy of payouts.</li>
    </ul>
                                    </TabPanel>
                                    <TabPanel className='whitepaper_tabbed-panel-outline'>
                                    <h2>Future-Proofing with Quantum Resistance: Implementation Timeline for Post-Quantum Cryptography</h2>
    <p>Given the potential for quantum computing to disrupt current cryptographic systems, Anryton has laid out a detailed roadmap for integrating post-quantum cryptography into its core architecture. The goal is to ensure long-term security, particularly for sensitive healthcare data that must remain protected for decades.</p>

    <h3>Year 1: Research and Feasibility Studies</h3>
    <ul>
        <li><strong>Quantum-Resistant Cryptography Research:</strong> In the first year following the mainnet launch, Anryton will complete its ongoing research into lattice-based cryptography, one of the most promising areas for post-quantum security. Collaborations with cryptographic experts and academic institutions will drive this phase.</li>
        <li><strong>Feasibility Studies:</strong> During this phase, Anryton will test the integration of post-quantum algorithms within its existing blockchain framework. The goal is to identify the optimal cryptographic schemes (such as Learning With Errors (LWE)) that provide the highest security without sacrificing performance.</li>
    </ul>

    <h3>Year 2: Testnet Implementation of Post-Quantum Cryptography</h3>
    <ul>
        <li><strong>Testnet Deployment:</strong> Anryton will introduce quantum-resistant cryptographic algorithms in a controlled environment on the testnet. This phase will focus on evaluating the performance, security, and scalability of the new cryptographic protocols.</li>
        <li><strong>Security Audits and Penetration Testing:</strong> Extensive security audits will be conducted by third-party experts to test the robustness of the post-quantum cryptography implementation. The goal is to identify any potential vulnerabilities before deploying the system to the mainnet.</li>
    </ul>

    <h3>Year 3: Mainnet Integration and Quantum-Resistant Fork</h3>
    <ul>
        <li><strong>Mainnet Fork for Quantum Resistance:</strong> Once post-quantum cryptographic algorithms are successfully tested, Anryton will initiate a hard fork to implement these quantum-resistant features on the mainnet. This will secure all future transactions and data against quantum attacks while allowing for a seamless transition for existing users.</li>
        <li><strong>Validator Upgrade:</strong> Validators and node operators will be required to upgrade their systems to support the new quantum-resistant protocols. This ensures the entire network benefits from the enhanced security without disruption.</li>
    </ul>

    <h3>Year 4 and Beyond: Continuous Upgrades and Monitoring</h3>
    <ul>
        <li><strong>Periodic Security Upgrades:</strong> Anryton will continue to monitor developments in quantum computing and cryptography. Regular protocol updates will be scheduled to ensure that the blockchain remains secure against any future advancements in quantum computing.</li>
        <li><strong>Quantum-Ready Partnerships:</strong> Anryton will also explore partnerships with industries and institutions working on quantum technologies, ensuring that the blockchain can adapt and remain secure as the quantum landscape evolves.</li>
    </ul>
                                    </TabPanel>
                                   



                                </TabView>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewWhitepaper;
